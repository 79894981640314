import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import * as EstimateList from './Saga/EstimateList';
// import * as Mail from '../Mail/Saga/Mail';
// import * as Responses from '../../Store/Responses';
import './Css/Grid.css';
import LoadingComponent from '../Layout/LoadingComponent';
import EstimateComponent from '../Estimates/Index';
import moment from 'moment';
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';





// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }




// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }



// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }


// import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,

// } from '@material-ui/pickers';







// import ReactDataGrid from "react-data-grid"
const ReactDataGrid = require('react-data-grid');



// import * as ReactDataGrid from "react-data-grid";
// import { Toolbar, Data } from "react-data-grid-addons";

// const MailLinkFormatter = (payload:any) => {

//     return <a role="button"  className="btn btn-primary" target="_blank" onClick={this.mailadd} >追加</a>;

// };
const DateFormatter = (payload) => {
  if (payload.value) {
    return moment(payload.value).format("YYYY/MM/DD");
  } else {
    return "";
  }
};

const DateTimeFormatter = (payload) => {
  if (payload.value) {
    return moment(payload.value).format("YYYY/MM/DD hh:mm:ss");
  } else {
    return "";
  }
};

const NameFormatter = (payload) => {
  return <div><div>{payload.row.tatemonoName}</div><div>{payload.row.shikuchoson}{payload.row.choiki}{payload.row.otherAddress}</div><div>{payload.row.shunkouYear}年{payload.row.shunkouMonth}月</div></div>
};




// const ChinRyouFormatter =(payload:any) => {
//     return (
//         <div>
//             <div>賃料 {payload.row.chinRyou.toLocaleString()}円</div>
//             <div>共益 {payload.row.kyouekiHi!=null?payload.row.kyouekiHi.toLocaleString():"-"}円</div>
//             <div>水道 {payload.row.suidouRyou}</div>
//             <div>車① {payload.row.pRyou}</div>
//         </div>
//     )
// };




const columns = [
  { key: 'contractionDate', name: '契約日', width: 90, resizable: true, formatter: DateFormatter },

  { key: 'buildingName', name: '物件名', width: 170, resizable: true },
  { key: 'roomName', name: '部屋名', width: 50, resizable: true },
  { key: 'name1', name: '名前', width: 80, resizable: true },
  { key: 'updatedAt', name: '更新日時', width: 90, resizable: true, formatter: DateTimeFormatter },
  { key: 'staffName', name: '担当', width: 50, resizable: true },

  { key: 'edit', name: '編集', width: 70, resizable: true },
  { key: 'addpdf', name: 'pdf添付', width: 60, resizable: true },
  { key: 'addmail', name: 'メール', width: 60, resizable: true },
  { key: 'dl', name: 'DL', width: 50, resizable: true },
  // { key: 'col8', name: '件数', resizable: true, formatter: DetailLinkFormatter }
];

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalestimates: false,
      rentEstimateId: 0,
      tatemonoId: "",
      roomId: "",
      change: true,
    }
    // this.onRowClick = this.onRowClick.bind(this);
    // this.mailadd = this.mailadd.bind(this);
    this.getCellActions = this.getCellActions.bind(this);
    this.toggleestimates = this.toggleestimates.bind(this);

    this.handleEmp = this.handleEmp.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleContractionDateChangeStart = this.handleContractionDateChangeStart.bind(this);
    this.handleContractionDateChangeEnd = this.handleContractionDateChangeEnd.bind(this);
    this.submit = this.submit.bind(this);

    // this.delete_auto_send = this.delete_auto_send.bind(this);




    var dt = new Date();
    // var day = dt.getDate();

    // //1ヶ月前
    //  var lastMonth = dt.setMonth(month -2);
    // var lastmonth = dt.getMonth()+1;//変数の場合は、+1
    // alert(dt)
    this.props.change_estimate_search({
      contractDateStart: dt,
      contractDateEnd: null,
      tatemonoName: null,
      name: null,
      responseOnly: true,
      rentResponseId: this.props.rentResponseId
    })


    this.props.get_estimate_list({
      contractDateStart: dt,
      contractDateEnd: null,
      tatemonoName: null,
      name: null,
      responseOnly: true,
      rentResponseId: this.props.rentResponseId
    })
  }
  componentWillMount() {


    // this.props.customerList()

  }
  getSelectedCell(event) {
    alert()
  }

  // onRowClick = (rowIdx:any, row:any) => { 

  //     if(row)
  //     this.props.toggle(row);




  // }

  EmptyRowsView = () => {
    const message = "検索結果がありません。";

    return (
      this.props.estimate_list_loading === true ? <LoadingComponent /> : <div style={{ textAlign: "center", padding: "100px" }} > <h3>{message}</h3></div>

    );
  };

  //   mailadd= (tatemonoId:any,roomId:any) => {

  //     this.props.add_mail_room({window:window,id:131,tatemonoId:tatemonoId,roomId:roomId})
  //     this.props.togglesearch();
  //   }
  toggleestimates = (id) => {
    this.setState({ rentEstimateId: id })
    this.setState(prevState => ({
      modalestimates: !prevState.modalestimates,

    }));

  }
  getCellActions(column, row) {
    // console.log(row.way)

    // const cellActions = {

    //   coming: comingActions
    // };
    console.log(row)

    if (column.key === "addmail") {

      return [{
        icon: <i className="fas fa-envelope float-left" style={{ fontSize: "20px", marginTop: "5px" }} />,

        callback: () => {
          if (window.editorInstance2) {
            this.props.insert_estimate_to_mail_text({ window: window, id: row.id })
          }
          
        }
      }
      ]

    } else if (column.key === "addpdf") {

      return [{
        icon: <i className="fas fa-file-pdf float-left" style={{ fontSize: "20px", marginTop: "5px" }} />,

        callback: () => {
          this.props.add_estimate_to_mail({ id: row.id, mailid: this.props.send_mail_details.id })

        }
      }
      ]
    } else if (column.key === "dl") {

      return [{
        icon: <i className="fas fa-file-download float-left" style={{ fontSize: "20px", marginTop: "5px" }} />,

        callback: () => {
          this.props.download_estimate_pdf(row)
        }
      }
      ]
    } else if (column.key === "edit") {

      return [{
        icon: <button type="button" className="btn btn-sm btn-success float-left" style={{ margin: "2px", width: "50px" }}>編集</button>,

        callback: () => {
          this.toggleestimates(row.id)
        }
      }
      ]
    } else {
      return "";
    }

  }



  //       delete_auto_send(){
  //       let insertdata = {
  //         rentResponseId: this.props.rentResponseId,
  //         tatemonoId: this.state.tatemonoId,
  //        status:4,

  //         roomId:this.state.roomId,

  //        };


  // // this.props.delete_auto_send(insertdata)
  // this.toggleautodelete()
  // }
  handleChange(event) {

    let estimate_search = this.props.estimate_search
    estimate_search[event.target.name] = event.target.value
    this.props.change_estimate_search(estimate_search);

    this.setState(prevState => ({
      change: !prevState.change,

    }));
  };


  handleContractionDateChangeStart = (date) => {

    let estimate_search = this.props.estimate_search

    estimate_search.contractDateStart = date;

    this.props.change_estimate_search(estimate_search)
    this.setState(prevState => ({
      change: !prevState.change,

    }));
  };

  handleContractionDateChangeEnd = (date) => {

    let estimate_search = this.props.estimate_search

    estimate_search.contractDateEnd = date;

    this.props.change_estimate_search(estimate_search)
    this.setState(prevState => ({
      change: !prevState.change,

    }));
  };
  handleEmp(event) {

    let estimate_search = this.props.estimate_search
    estimate_search.responseOnly = event.target.checked
    this.props.change_estimate_search(estimate_search);

    this.setState(prevState => ({
      change: !prevState.change,

    }));
  };
  submit() {
    this.props.get_estimate_list(this.props.estimate_search)
  }

  render() {
    // console.log(this.props.customerlists)
    // alert(this.props.customerlists+"sss")
    // const ss:any=[];
    return (
      <div >
        {/* {this.props.estimate_put_loading?<div style={{position:"absolute",background:"rgba(45,45,45, 0.5)",marginLeft:"-14px",marginTop:"-14px",width:"100%",height:"100%",zIndex:3000}}>
             <div style={{ marginTop: "20%" }}>
             <Loading/>
             </div>
             </div>:""} */}


        <Grid container spacing={3}>
          <Grid item xs={12}>


            <TextField
                  id="contractDateStart"
                  label="契約日"
                  type="date"
                  name="contractDateStart"
                  size="small"
                  onChange={this.handleChange}
                  value={moment(this.props.estimate_search.contractDateStart).format("YYYY-MM-DD")}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  variant="standard"
                  style={{marginRight:20}}
              />
～
            <TextField
                  id="contractDateEnd"
                  label="契約日"
                  type="date"
                  name="contractDateEnd"
                  size="small"
                  onChange={this.handleChange}
                  value={moment(this.props.estimate_search.contractDateEnd).format("YYYY-MM-DD")}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  variant="standard"
                  style={{marginLeft:20}}
              />
{/* 
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>


              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                margin="normal"
                id="date-picker-estimate2"
                label="契約日"
                //   showTodayButton={true}
                style={{ float: "left" }}
                // error={true}
                // helperText="契約日を選択してください"
                //   errorMessages={['契約日を選択してください']}
                name="contractDataStart"
                onChange={this.handleContractionDateChangeStart}
                value={this.props.estimate_search.contractDateStart}
                //    value={this.props.estimateData.contractDate}
                //              onChange={this.handleContractionDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                //   minDateMessage="契約日を選択してください"
                // minDate={new Date("2000-01-01")}
                invalidDateMessage={"日付の形式が違います"}
              />
           ～
           <KeyboardDatePicker

                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                margin="normal"
                id="date-picker-estimate2"
                label="契約日"
                style={{ marginLeft: "20px" }}
                // error={true}
                // helperText="申込日を選択してください"
                //   showTodayButton={true}
                //  name ="applicationDate"
                name="contractDataEnd"
                value={this.props.estimate_search.contractDateEnd}
                //  value={this.props.estimateData.applicationDate}
                onChange={this.handleContractionDateChangeEnd}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                invalidDateMessage={"日付の形式が違います"}
              />

            </MuiPickersUtilsProvider> */}
            <FormControlLabel
              style={{ marginLeft: "10px" }}
              control={
                <Checkbox
                  name="responseOnly"
                  // checked={this.props.estimate_search.responseOnly}
                  onChange={this.handleEmp}
                  checked={this.props.estimate_search.responseOnly}
                  // value={this.props.estimate_search.responseOnly}
                  color="primary"
                />
              }
              label="この反響"
            />

          </Grid>
          <Grid item xs={12}>

            <TextField
              id="outlined-basic"
              //   className={classes.textField}
              label="物件名"
              margin="normal"

              onChange={this.handleChange}
              name="tatemonoName"
              value={this.props.estimate_search.tatemonoName}

              style={{ float: "left", width: "200px", marginLeft: "10px", marginTop: 0 }}

            //   errorMessages={['部屋名を入力してください。']}
            />

            <TextField
              id="outlined-basic"
              //   className={classes.textField}
              label="契約者名"
              margin="normal"

              onChange={this.handleChange}
              name="name"
              value={this.props.estimate_search.name}
              style={{ float: "left", width: "200px", marginLeft: "10px", marginTop: 0 }}

            //   errorMessages={['部屋名を入力してください。']}
            />

            <Button type="button" className="float-right" variant="contained" color="secondary"
              onClick={() => this.toggleestimates(0)} style={{ margin: "2px" }}>見積作成</Button>
            <Button type="button" variant="contained" color="primary" onClick={this.submit} style={{ margin: "2px" }}>検索</Button>

          </Grid>
        </Grid>
        <ReactDataGrid
          // ref={node => this.grid = node}
          enableCellSelect={false}
          columns={columns}
          rowGetter={(i) => this.props.estimate_list[i]}
          // rowHeight={90}
          // headerRowHeight={35}
          rowsCount={this.props.estimate_list.length}
          getCellActions={this.getCellActions}
          // headerRenderer={true}
          dragable={true}
          // onColumnResize={(idx, width) =>
          //     console.log(`Column ${idx} has been resized to ${width}`)
          // }
          minHeight={500}
          emptyRowsView={this.EmptyRowsView}
        // onRowClick={this.props.selectsheet}
        //onCellSelected={this.getSelectedCell}
        />


        <Modal isOpen={this.state.modalestimates} zIndex={3000} size="xxl" fade={false}>

          <ModalHeader toggle={() => this.toggleestimates(-1)}>見積作成</ModalHeader>
          <ModalBody style={{ background: "#9fb6cc" }}>
            <EstimateComponent
              rentEstimateId={this.state.rentEstimateId}
              tatemonoId=""
              roomId=""
            />
          </ModalBody>
          <ModalFooter>
            {/* <button className="btn btn btn-danger" style={{margin:"5px"}} onClick={this.delete_auto_send}>除外する</button> */}
            <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={() => this.toggleestimates(-1)}>閉じる</button>
          </ModalFooter>
        </Modal>


        <Backdrop open={this.props.estimate_put_loading} style={{ zIndex: 7000 }} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }


}



const mapStateToProps = (state) => {
  return {
    estimate_list: state.estimateList.estimate_list,
    estimate_list_loading: state.estimateList.estimate_list_loading,
    send_mail_details: state.mailReducer.send_mail_details,
    estimate_put_loading: state.estimateList.estimate_put_loading,
    estimate_search: state.estimateList.estimate_search,
    users: state.masterReducer.users,

    // responseid: state.customersReducer.responseid,
    // responsedetail: state.customersReducer.responsedetail,
    // introductions_loading:state.responsesReducer.introductions_loading
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_estimate_list(state) {
      dispatch(EstimateList.get_estimate_list(state))
    },
    insert_estimate_to_mail_text(state) {
      dispatch(EstimateList.insert_estimate_to_mail_text(state))
    },
    download_estimate_pdf(state) {
      dispatch(EstimateList.download_estimate_pdf(state))
    },
    add_estimate_to_mail(state) {
      dispatch(EstimateList.add_estimate_to_mail(state))
    },
    change_estimate_search(state) {
      dispatch(EstimateList.change_estimate_search(state))
    },
    // delete_auto_send(state:any) {
    //     dispatch(EstimateList.delete_auto_send(state))
    // },
    // add_mail_room(state:any) {
    //     dispatch(Mail.add_mail_room(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);