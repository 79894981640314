const authentication = require('../../../react-azure-adb2c2').default;

export function update(values: any) {

    console.log(values)


    const token = authentication.getAccessToken();
    // const url = `http://localhost:5000/api/v1/rent/responses/update/` + values.id;
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/update/` + values.id;

    const url = process.env.REACT_APP_API_CRM_RESPONSES_URL + `/api/v1/rent/responses/update/` + values.id; //これ
    // alert(url)
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}

export function update_free(values: any) {

    console.log(values)

    const token = authentication.getAccessToken();
    // const url = `http://localhost:5000/api/v1/rent/responses/updatefree/` + values.id;
    const url = process.env.REACT_APP_API_CRM_RESPONSES_URL + `/api/v1/rent/responses/updatefree/` + values.id; //これ
    // alert(url)
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}


export function add(values: any) {

    console.log(values)


    const token = authentication.getAccessToken();
    // const url = `http://localhost:5000/api/v1/rent/responses/new/`;
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/new/`;
    const url = process.env.REACT_APP_API_CRM_RESPONSES_URL + `/api/v1/rent/responses/new/`; //これ
    // const url = process.env.REACT_APP_API_BASE_URL + `/api/RentResponses/` + values.id
    // const url = `https://localhost:44341/api/RentResponses/`+ values.id
    // alert(JSON.stringify(values))
    // alert(url)
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}






export function response_delete(values: any) {

    console.log(values)

    const token = authentication.getAccessToken();
    // const url = `http://localhost:5000/api/v1/rent/responses/delete/` + values;
    const url = process.env.REACT_APP_API_CRM_RESPONSES_URL + `/api/v1/rent/responses/delete/` + values;

    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'delete',
        // body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}


export function insert(values: any) {

    values.customerdetail.tel1 = values.customerdetail.tel1 ? values.customerdetail.tel1.toString() : ""
    values.customerdetail.tel1_remarks = values.customerdetail.tel1_remarks ? values.customerdetail.tel1_remarks.toString() : ""
    values.customerdetail.tel2 = values.customerdetail.tel2 ? values.customerdetail.tel2.toString() : ""
    values.customerdetail.tel2_remarks = values.customerdetail.tel2_remarks ? values.customerdetail.tel2_remarks.toString() : ""

    values.customerdetail.fax = values.customerdetail.fax ? values.customerdetail.fax.toString() : ""
    values.customerdetail.fax_remarks = values.customerdetail.fax_remarks ? values.customerdetail.fax_remarks.toString() : ""

    values.customerdetail.fax = values.customerdetail.fax ? values.customerdetail.fax.toString() : ""
    values.customerdetail.fax_remarks = values.customerdetail.fax_remarks.toString()
    values.customerdetail.memo = values.customerdetail.memo ? values.customerdetail.memo.toString() : ""

    values.customerdetail.mail1 = values.customerdetail.mail1 ? values.customerdetail.mail1.toString() : ""
    values.customerdetail.mail1_remarks = values.customerdetail.mail1_remarks ? values.customerdetail.mail1_remarks.toString() : ""
    values.customerdetail.mail2 = values.customerdetail.mail2 ? values.customerdetail.mail2.toString() : ""
    values.customerdetail.mail2_remarks = values.customerdetail.mail2_remarks ? values.customerdetail.mail2_remarks.toString() : ""
    values.customerdetail.office_name = values.customerdetail.office_name ? values.customerdetail.office_name.toString() : ""

    values.customerdetail.remarks = values.customerdetail.remarks ? values.customerdetail.remarks.toString() : ""
    values.customerdetail.executive = values.customerdetail.executive ? values.customerdetail.executive.toString() : ""
    values.customerdetail.agent_etc = values.customerdetail.agent_etc ? values.customerdetail.agent_etc.toString() : ""

    let contents = {
        rentCustomers: values.customerdetail,
        rentResponses: values.responsedetail,
        line_user_id: values.line_user_id,
        line_account_section_id: values.line_account_section_id,
        matchinglist: values.response_add_select_mail_id_list == null ? [] : values.response_add_select_mail_id_list
    }
    // alert(JSON.stringify(contents))
    const token = authentication.getAccessToken();
    // const url = `https://localhost:44341/api/RentResponses/`
    const url = process.env.REACT_APP_API_BASE_URL + `/api/RentResponses/`
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(contents),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}



export function getlist(values: any) {

    const token = authentication.getAccessToken();
    // const url = `https://localhost:44341/api/RentResponsesList/`
    const url = process.env.REACT_APP_API_BASE_URL + `/api/RentResponsesList/`
    const search = values ? values : {};
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(search),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        //.then(payload => ({ payload }))
        .catch(error => ({ error }));
}


export function responses_search_list(value: any) {

    const token = authentication.getAccessToken();
    // const url = `https://localhost:44341/response/api/MatchingList/`+value
    const url = process.env.REACT_APP_API_BASE_URL + `/response/api/RentResponsesSearch/` + value
    //  alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}


// export function matcing_select_list(value: any) {

//     const token = authentication.getAccessToken();
//     // const url = `https://localhost:44341/response/api/MatchingList/`+value
//     const url = process.env.REACT_APP_API_BASE_URL + `/response/api/MatchingSelectList/` + value

//     return fetch(url, {
//         method: 'get',
//         // body: JSON.stringify(search),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));
// }

export function get_by_customerid_list(values: number) {

    const token = authentication.getAccessToken();
    // const url = `https://localhost:44341/api/c/CustomerLogs/`+values;
    // const url = `https://localhost:44341/api/c/CustomerLogs/34`;

    const url = process.env.REACT_APP_API_BASE_URL + `/api/c/CustomerLogs/` + values;
    // const search = values ? values : {};
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}



export function getdetail(values: any) {

    // const url = `http://localhost:5000/api/v1/rent/responses/detail/` + values;
    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/detail/` + values;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}


export function merge_response_mails(values: any) {

    const token = authentication.getAccessToken();
    // const url = `https://localhost:44341/response/api/MatchingSelectList/`+values.responseId;

    // const url = process.env.REACT_APP_API_BASE_URL + `/response/api/MatchingSelectList/` + values.responseId;

    // const url = `http://localhost:5000/api/v1/rent/responses/matchingselectlist`;//これ
    const url = process.env.REACT_APP_API_CRM_RESPONSES_URL + `/api/v1/rent/responses/matchingselectlist`;//これ

    // alert(url)
    // const url = process.env.REACT_APP_API_BASE_URL+`/response/api/MatchingSelectList/`+values.responseId;
    // const search = values ? values : {};
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));



}

// export function merge_response_mails(values:any) {
// alert("fff")
//     const token = authentication.getAccessToken();
//     // const url = `https://localhost:44341/response/api/MatchingSelectList/`+values.responseId;





//     const url = process.env.REACT_APP_API_BASE_URL+`/response/api/MatchingSelectList/`+values.responseId;
//     // const search = values ? values : {};
//     return fetch(url, {
//         method: 'put',
//         body: JSON.stringify(values.response_matching_select_list),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
// .then(res => res.json())
// .then(payload => ({ payload }))
//         .catch(error => ({ error }));
// }

export function date_update(values: any) {

    console.log(values)


    const token = authentication.getAccessToken();
    // const url = `http://localhost:5000/api/v1/rent/responses/dateupdate/` + values.id;
    const url = process.env.REACT_APP_API_CRM_RESPONSES_URL + `/api/v1/rent/responses/dateupdate/` + values.id;
    // alert(url)
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}