
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { call, put, takeEvery, select } from 'redux-saga/effects';

import * as rent_customer_contacts_api from '../Api/rent_customer_contacts_api';
import * as rent_tasks_api from '../Api/rent_tasks_api';
import * as users_api from '../Api/users_api';
import * as tm_tenpo_api from '../Api/tm_tenpo_api';
import * as t_owner_room_api from '../Api/t_owner_room_api';
import * as rent_m_corporates_api from '../Api/rent_m_corporates_api';
import * as tm_owner_api from '../Api/tm_owner_api';
import * as t_eigyou_api from '../Api/t_eigyou_api';
import * as rent_corporate_contacts_api from '../Api/rent_corporate_contacts_api';
import * as tm_owner_plan_api from '../Api/tm_owner_plan_api';
import * as t_owner_plan_counts_api from '../Api/t_owner_plan_counts_api';
import * as tm_tatemono_api from '../Api/tm_tatemono_api';
import * as rent_m_todo_categories_api from '../Api/rent_m_todo_categories_api';

import * as Task from '../../Task/Saga/Task';


// import * as RentIntroductionsApi from '../Api/RentIntroductionsApi';
import { startSubmit, stopSubmit, reset, submit } from 'redux-form';
import { array } from 'prop-types';
import { PlaylistAddTwoTone } from '@mui/icons-material';
var moment = require('moment-timezone');
// import * as Customers from './Customers';

//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}


export interface ResponsesListState {
  calendar_list: any;
  calendar_list_disp: any;

  calendar_list_form: {
    start: string,
    end: string,
    section_id: any,
    user_id: number,
  },
  calendar_all_store_list_form: {
    start: string,
    end: string,
    section_id: any,
    user_id: number,
  },
  calendar_staff_list_form: {
    start: string,
    end: string,
    section_id: any,
    user_id: number,
    slot: number,
    start_total: string,
    end_total: string,
  },
  calendar_store_list_form: {
    start: string,
    end: string,
    section_id: any,
    user_id: number,
  },

  calender_loading: boolean,

  rent_task_list: any;
  rent_task_detail: any;


  calendar_search_store_list: any;
  calendar_user_details: any;



  calendar_type: number;
  calendar_backdrop: boolean;
  calendar_event_type: number;
  calendar_precalendar: any;

  calendar_free_dialog: boolean;
  calendar_free_detail: any;
  // change_modalMatching:any

  eigyou_rent_m_corporate_all_list: any;

  eigyou_owner_room: any;
  eigyou_detail: any;

  task_edit_modal: boolean,
  eigyo_edit_modal: boolean,

  users: any;
  tm_owners: any;

  calendar_toggle: any,

  calendar_viewType: any,

  calendar_list_edit: any,
  calendar_list_edit_pre: any,
  calendar_list_edit_dialog_disp: boolean,

  tm_owner_search_owner_name_list: any,
  tm_owner_search_building_list: any,
  tm_owner_search_form: any;

  tm_owner_outai_naiyou_list:any;

  tm_owner_plan_list: any;
  t_owner_plan_counts_list: any;
  tm_tatemono_list_master: any;
  rent_m_todo_category_list: any;
}

const initialState: ResponsesListState = {

  calendar_list: [],
  calendar_list_disp: [],


  calendar_list_form: {
    // start: moment().startOf('week').startOf('day').format('YYYY/MM/DD HH:mm:ss'),
    // end: moment().endOf('week').endOf('day').format('YYYY/MM/DD HH:mm:ss'),
    start: '',
    end: '',
    section_id: [],
    user_id: 0,
  },
  calendar_all_store_list_form: {
    start: moment().startOf('day').format('YYYY/MM/DD HH:mm:ss'),
    end: moment().endOf('day').format('YYYY/MM/DD HH:mm:ss'),
    section_id: [0],
    user_id: 0,
  },
  calendar_staff_list_form: {
    start: moment().startOf('day').format('YYYY/MM/DD HH:mm:ss'),
    end: moment().endOf('day').format('YYYY/MM/DD HH:mm:ss'),
    section_id: [],
    user_id: 0,
    slot: 0,//横　色付け用
    start_total: moment().startOf('month').format('YYYY/MM/DD HH:mm:ss'),//集計用
    end_total: moment().endOf('month').format('YYYY/MM/DD HH:mm:ss'),//集計用
  },
  calendar_store_list_form: {
    start: moment().startOf('day').format('YYYY/MM/DD HH:mm:ss'),
    end: moment().endOf('day').format('YYYY/MM/DD HH:mm:ss'),
    section_id: [],
    user_id: 0,
  },

  calender_loading: false,

  rent_task_list: [],
  rent_task_detail: { id: "", section_id: 0, user_id: 0, start_at: moment().format("YYYY-MM-DD"), end_at: moment().format("YYYY-MM-DD"), remarks: "", color: "#F9A7A7" },




  calendar_search_store_list: [],
  calendar_user_details: [],
  // all_store: [
  //   {id:17, label:""}
  //   ],
  calendar_type: 0,// 0:店舗別　1:全店舗 2:担当別
  calendar_backdrop: false,

  calendar_event_type: 0,
  calendar_precalendar: [],

  calendar_free_dialog: false,

  calendar_free_detail: {},

  eigyou_rent_m_corporate_all_list: [],
  
  eigyou_owner_room: [],
  eigyou_detail: {
    eigyou_no: "",
    yoyaku_date: "",
    start_at: "",
    end_at: "",
    no_count: "",
    owner_id: "",
    tantou_user_id: "",
    together_user_id:[],
    raiten_kbn_no: "",
    outai_naiyou_no: "",
    outai_naiyou_id: [],
    suggestions: "",
    outai_shubetsu_no: "",
    naiyou: "",
    komatte: "",
    promise: "",
    confirmers: "",
    responder: "",
  },
  task_edit_modal: false,
  eigyo_edit_modal: false,

  users: [],
  tm_owners: [],

  // calendar_toggle: ["coming", "key", "explan", "contract"],
  calendar_toggle: ["coming"],
  // calendar_toggle: ["coming"],
  calendar_viewType: "week",

  calendar_list_edit: [],
  calendar_list_edit_pre: [],
  calendar_list_edit_dialog_disp: false,

  tm_owner_search_owner_name_list: [],
  tm_owner_search_building_list: [],
  tm_owner_search_form: {owner_id: "", owner_name: "",  building_id: "", building_name: "", },

  tm_owner_outai_naiyou_list:[
    {value:1, label:"成約・解約報告", checked: false},
    {value:2, label:"空室対策提案", checked: false},
    {value:3, label:"原状回復工事", checked: false},
    {value:16, label:"貸方改善", checked: false},
    {value:4, label:"建物改善", checked: false},
    {value:5, label:"リノベーション", checked: false},
    {value:6, label:"大規模修繕", checked: false},
    {value:7, label:"BM", checked: false},
    {value:8, label:"売買相談", checked: false},
    {value:9, label:"管理受託", checked: false},
    {value:10, label:"相続関係", checked: false},
    {value:11, label:"定期報告", checked: false},
    {value:12, label:"他部署からの相談内容", checked: false},
    {value:15, label: "イベント招待", checked: false},
    {value:14, label:"お中元・お歳暮", checked: false},
    {value:13, label:"その他", checked: false}
    
],

  tm_owner_plan_list: [],
  t_owner_plan_counts_list: [],
  tm_tatemono_list_master: [],
  rent_m_todo_category_list: [],

}


///action 
const actionCreator = actionCreatorFactory();



export const change_calendar_type = actionCreator<number>('CHANGE_CALENDAR_TYPE');

export const get_calendar_list = actionCreator<any>('GET_CALENDAR_LIST');
export const change_calendar_list = actionCreator<any>('CHANGE_CALENDAR_LIST');

export const change_calendar_list_disp = actionCreator<any>('CHANGE_CALENDAR_LIST_DISP');

// export const change_contract_follow_list_loading = actionCreator<boolean>('CHANGE_CONTRACT_FOLLOW_LIST_LOADING');

export const change_calendar_list_form = actionCreator<any>('CHANGE_CALENDAR_LIST_FORM');
export const change_calendar_all_store_list_form = actionCreator<any>('CHANGE_CALENDAR_ALL_STORE_LIST_FORM');
export const change_calendar_staff_list_form = actionCreator<any>('CHANGE_CALENDAR_STAFF_LIST_FORM');
export const change_calendar_store_list_form = actionCreator<any>('CHANGE_CALENDAR_STORE_LIST_FORM');

export const change_calender_loading = actionCreator<any>('CHANGE_CALENDAR_LOADING');


export const get_rent_task_list = actionCreator<any>('GET_RENT_TASK_LIST');
export const change_rent_task_list = actionCreator<any>('CHANGE_RENT_TASK_LIST');
export const get_rent_task_detail = actionCreator<any>('GET_RENT_TASK_DETAIL');
export const change_rent_task_detail = actionCreator<any>('CHANGE_RENT_TASK_DETAIL');
export const update_rent_task_detail = actionCreator<any>('UPDATE_RENT_TASK_DETAIL');
export const insert_rent_task_detail = actionCreator<any>('INSERT_RENT_TASK_DETAIL');

export const set_calendar_list_raitenwaku_user = actionCreator<any>('SET_CALENDAR_LIST_RAITENWAKU_USER');
export const set_calendar_list_raitenwaku_allstore = actionCreator<any>('SET_CALENDAR_LIST_RAITENWAKU_ALLSTORE');


export const change_calendar_backdrop = actionCreator<any>('CHANGE_CALENDAR_BACKDROP');

export const change_calendar_event_type = actionCreator<any>('CHANGE_CALENDAR_EVENT_TYPE');

export const change_calendar_precalendar = actionCreator<any>('CHANGE_CALENDAR_PRECALENDAR');

export const change_calendar_free_dialog = actionCreator<any>('CHANGE_CALENDAR_FREE_DIALOG');
export const set_calendar_free_detail = actionCreator<any>('SET_CALENDAR_FREE_DETAIL');
export const change_calendar_free_detail = actionCreator<any>('CHANGE_CALENDAR_FREE_DETAIL');
export const insert_calendar_free_detail = actionCreator('INSERT_CALENDAR_FREE_DETAIL');
export const update_calendar_free_detail = actionCreator('UPDATE_CALENDAR_FREE_DETAIL');
export const delete_calendar_free_detail = actionCreator('DELETE_CALENDAR_FREE_DETAIL');


export const get_eigyou_rent_m_corporate_all_list = actionCreator<any>('GET_EIGYOU_RENT_M_CORPORATE_ALL_LIST');
export const change_eigyou_rent_m_corporate_all_list = actionCreator<any>('CHANGE_EIGYOU_RENT_M_CORPORATE_ALL_LIST');
export const get_eigyou_owner_room = actionCreator<any>('GET_EIGYOU_OWNER_ROOM');
export const change_eigyou_owner_room = actionCreator<any>('CHANGE_EIGYOU_OWNER_ROOM');
export const change_eigyou_detail = actionCreator<any>('CHANGE_EIGYO_DETAIL');
export const change_eigyo_edit_modal = actionCreator<any>('CHANGE_EIGYO_EDIT_MODAL');
export const update_eigyou_detail = actionCreator<any>('UPDATE_EIGYOU_DETAIL');
export const delete_eigyou_detail = actionCreator<any>('DELETE_EIGYOU_DETAIL');
export const insert_eigyou_detail = actionCreator<any>('INSERT_EIGYOU_DETAIL');
export const update_eigyou_detail_confirmers = actionCreator<any>('UPDATE_EIGYOU_DETAIL_CONFIRMERS');


export const change_task_edit_modal = actionCreator<any>('CHANGE_TASK_EDIT_MODAL');

export const get_users = actionCreator<any>("GET_USERS");
export const change_users = actionCreator<any>("CHANGE_USERS");
export const get_calendar_tm_owners = actionCreator<any>("GET_CALENDAR_TM_OWNERS");
export const change_calendar_tm_owners = actionCreator<any>("CHANGE_CALENDAR_TM_OWNERS");

export const change_calendar_toggle = actionCreator<any>("CHANGE_CALENDAR_TOGGLE");
export const change_calendar_viewType = actionCreator<any>("CHANGE_CALENDAR_VIEWTYPE");

export const get_calendar_list_edit = actionCreator<any>('GET_CALENDAR_LIST_EDIT');
export const change_calendar_list_edit = actionCreator<any>('CHANGE_CALENDAR_LIST_EDIT');
export const change_calendar_list_edit_pre = actionCreator<any>('CHANGE_CALENDAR_LIST_EDIT_PRE');
export const change_calendar_list_edit_dialog_disp = actionCreator<boolean>('CHANGE_CALENDAR_LIST_DIALOG_DISP');

export const get_calendar_search_store_list = actionCreator<any>("GET_CALENDAR_SEARCH_STORE_LIST");
export const change_calendar_search_store_list = actionCreator<any>("CHANGE_CALENDAR_SEARCH_STORE_LIST");
export const get_calendar_user_details = actionCreator<any>("GET_CALENDAR_USER_DETAILS");
export const change_calendar_user_details = actionCreator<any>("CHANGE_CALENDAR_USER_DETAILS");

export const get_calendar_type_total_csv = actionCreator<any>("GET_CALENDAR_TYPE_TOTAL_CSV");

export const get_tm_owner_search_owner_name_list = actionCreator<any>("GET_TM_OWNER_SEARCH_OWNER_NAME_LIST");
export const change_tm_owner_search_owner_name_list = actionCreator<any>("CHANGE_TM_OWNER_SEARCH_OWNER_NAME_LIST");
export const get_tm_owner_search_building_list = actionCreator<any>("GET_TM_OWNER_SEARCH_BUILDING_LIST");
export const change_tm_owner_search_building_list = actionCreator<any>("CHANGE_TM_OWNER_SEARCH_BUILDING_LIST");
export const change_tm_owner_search_form = actionCreator('CHANGE_TM_OWNER_SEARCH_FORM');

export const change_tm_owner_outai_naiyou_list = actionCreator('CHANGE_TM_OWNER_OUTAI_NAIYOU_LIST');

export const get_tm_owner_plan_list = actionCreator('GET_TM_OWNER_PLAN_LIST');
export const change_tm_owner_plan_list = actionCreator('CHANGE_TM_OWNER_PLAN_LIST');
export const get_t_owner_plan_counts_list = actionCreator('GET_T_OWNER_PLAN_COUNTS_LIST');
export const change_t_owner_plan_counts_list = actionCreator('CHANGE_T_OWNER_PLAN_COUNTS_LIST');
export const get_tm_tatemono_list_master = actionCreator('GET_TM_TATEMONO_LIST_MASTER');
export const change_tm_tatemono_list_master = actionCreator('CHANGE_TM_TATEMONO_LIST_MASTER');
export const get_rent_m_todo_category_list = actionCreator('GET_RENT_M_TODO_CATEGORY_LIST');
export const change_rent_m_todo_category_list = actionCreator('CHANGE_RENT_M_TODO_CATEGORY_LIST');


export const calendarReducer = reducerWithInitialState(initialState)

  .case(change_calendar_type, (state, payload) => ({ ...state, calendar_type: payload }))
  // .case(change_response_id, (state, payload) => ({ ...state, response_id: payload }))
  .case(change_calendar_list, (state, payload) => ({ ...state, calendar_list: payload }))
  .case(change_calendar_list_disp, (state, payload) => ({ ...state, calendar_list_disp: payload }))

  .case(change_calendar_list_form, (state, payload) => ({ ...state, calendar_list_form: payload }))
  .case(change_calendar_all_store_list_form, (state, payload) => ({ ...state, calendar_all_store_list_form: payload }))
  .case(change_calendar_staff_list_form, (state, payload) => ({ ...state, calendar_staff_list_form: payload }))
  .case(change_calendar_store_list_form, (state, payload) => ({ ...state, calendar_store_list_form: payload }))

  .case(change_calender_loading, (state, payload) => ({ ...state, calender_loading: payload }))

  .case(change_rent_task_list, (state, payload) => ({ ...state, rent_task_list: payload }))
  .case(change_rent_task_detail, (state, payload) => ({ ...state, rent_task_detail: payload }))



  .case(change_calendar_backdrop, (state, payload) => ({ ...state, calendar_backdrop: payload }))

  .case(change_calendar_event_type, (state, payload) => ({ ...state, calendar_event_type: payload }))

  .case(change_calendar_precalendar, (state, payload) => ({ ...state, calendar_precalendar: payload }))

  .case(change_calendar_free_dialog, (state, payload) => ({ ...state, calendar_free_dialog: payload }))

  .case(change_calendar_free_detail, (state, payload) => ({ ...state, calendar_free_detail: payload }))

  .case(change_eigyou_rent_m_corporate_all_list, (state, payload) => ({ ...state, eigyou_rent_m_corporate_all_list: payload }))
  .case(change_eigyou_owner_room, (state, payload) => ({ ...state, eigyou_owner_room: payload }))
  .case(change_eigyou_detail, (state, payload) => ({ ...state, eigyou_detail: payload }))
  .case(change_task_edit_modal, (state, payload) => ({ ...state, task_edit_modal: payload }))
  .case(change_eigyo_edit_modal, (state, payload) => ({ ...state, eigyo_edit_modal: payload }))

  .case(change_users, (state, payload) => ({ ...state, users: payload }))
  .case(change_calendar_tm_owners, (state, payload) => ({ ...state, tm_owners: payload }))

  .case(change_calendar_toggle, (state, payload) => ({ ...state, calendar_toggle: payload }))
  .case(change_calendar_viewType, (state, payload) => ({ ...state, calendar_viewType: payload }))

  .case(change_calendar_list_edit, (state, payload) => ({ ...state, calendar_list_edit: payload }))
  .case(change_calendar_list_edit_pre, (state, payload) => ({ ...state, calendar_list_edit_pre: payload }))
  .case(change_calendar_list_edit_dialog_disp, (state, payload) => ({ ...state, calendar_list_edit_dialog_disp: payload }))

  .case(change_calendar_search_store_list, (state, payload) => ({ ...state, calendar_search_store_list: payload }))
  .case(change_calendar_user_details, (state, payload) => ({ ...state, calendar_user_details: payload }))

  .case(change_tm_owner_search_owner_name_list, (state, payload) => ({ ...state, tm_owner_search_owner_name_list: payload }))
  .case(change_tm_owner_search_building_list, (state, payload) => ({ ...state, tm_owner_search_building_list: payload }))
  .case(change_tm_owner_search_form, (state, payload) => ({ ...state, tm_owner_search_form: payload }))

  .case(change_tm_owner_outai_naiyou_list, (state, payload) => ({ ...state, tm_owner_outai_naiyou_list: payload }))

  .case(change_tm_owner_plan_list, (state, payload) => ({ ...state, tm_owner_plan_list: payload }))
  .case(change_t_owner_plan_counts_list, (state, payload) => ({ ...state, t_owner_plan_counts_list: payload }))
  .case(change_tm_tatemono_list_master, (state, payload) => ({ ...state, tm_tatemono_list_master: payload }))
  .case(change_rent_m_todo_category_list, (state, payload) => ({ ...state, rent_m_todo_category_list: payload }))

// .case(change_contract_follow_list_loading, (state, payload) => ({ ...state, contract_follow_list_loading: payload }))

// // .case(change_contract_count, (state, payload) => ({ ...state, contract_count: payload }))
// .case(change_contract_follow_list_form, (state, payload) => ({ ...state, contract_follow_list_form: payload }))

// .case(change_rent_m_contract_follows, (state, payload) => ({ ...state, rent_m_contract_follows: payload }))

// .case(change_contract_follow_editer, (state, payload) => ({ ...state, contract_follow_editer: payload }))

// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const calendarSaga = [

  takeEvery('GET_CALENDAR_LIST', handle_get_calendar_list),

  // takeEvery('GET_RENT_TASK_LIST', handle_get_rent_task_list),
  takeEvery('UPDATE_RENT_TASK_DETAIL', handle_update_rent_task_detail),
  takeEvery('INSERT_RENT_TASK_DETAIL', handle_insert_rent_task_detail),
  takeEvery('SET_CALENDAR_LIST_RAITENWAKU_USER', handle_set_calendar_list_raitenwaku_user),
  takeEvery('SET_CALENDAR_LIST_RAITENWAKU_ALLSTORE', handle_set_calendar_list_raitenwaku_allstore),

  takeEvery('SET_CALENDAR_FREE_DETAIL', handle_set_calendar_free_detail),
  takeEvery('INSERT_CALENDAR_FREE_DETAIL', handle_insert_calendar_free_detail),
  takeEvery('UPDATE_CALENDAR_FREE_DETAIL', handle_update_calendar_free_detail),
  takeEvery('GET_USERS', handle_get_users),
  takeEvery('GET_CALENDAR_TM_OWNERS', handle_get_calendar_tm_owners),

  takeEvery('GET_CALENDAR_LIST_EDIT', handle_get_calendar_list_edit),

  takeEvery('GET_CALENDAR_SEARCH_STORE_LIST', handle_get_calendar_search_store_list),
  takeEvery('GET_CALENDAR_USER_DETAILS', handle_get_calendar_user_details),

  takeEvery('GET_CALENDAR_TYPE_TOTAL_CSV', handle_get_calendar_type_total_csv),
  takeEvery('GET_EIGYOU_OWNER_ROOM', handle_get_eigyou_owner_room),
  takeEvery('GET_EIGYOU_RENT_M_CORPORATE_ALL_LIST', handle_get_eigyou_rent_m_corporate_all_list),

  takeEvery('GET_TM_OWNER_SEARCH_OWNER_NAME_LIST', handle_get_tm_owner_search_owner_name_list),
  takeEvery('GET_TM_OWNER_SEARCH_BUILDING_LIST', handle_get_tm_owner_search_building_list),

  takeEvery('UPDATE_EIGYOU_DETAIL', handle_update_eigyou_detail),
  takeEvery('DELETE_EIGYOU_DETAIL', handle_delete_eigyou_detail),
  takeEvery('INSERT_EIGYOU_DETAIL', handle_insert_eigyou_detail),
  takeEvery('UPDATE_EIGYOU_DETAIL_CONFIRMERS', handle_update_eigyou_detail_confirmers),

  takeEvery('GET_TM_OWNER_PLAN_LIST', handle_get_tm_owner_plan_list),
  takeEvery('GET_T_OWNER_PLAN_COUNTS_LIST', handle_get_t_owner_plan_counts_list),
  takeEvery('GET_TM_TATEMONO_LIST_MASTER', handle_get_tm_tatemono_list_master),
  takeEvery('GET_RENT_M_TODO_CATEGORY_LIST', handle_get_rent_m_todo_category_list),


  // takeEvery('DELETE_CALENDAR_FREE_DETAIL', handle_delete_calendar_free_detail),

  // takeEvery('GET_CALENDAR_ALL_STORE_LIST', handle_get_calendar_all_store_list),
  // takeEvery('GET_CALENDAR_STAFF_LIST', handle_get_calendar_staff_list),
  // takeEvery('GET_RENT_M_CONTRACT_FOLLOWS', handle_get_rent_m_contract_follows),

  // takeEvery('SEARCH_CONTRACT_FOLLOW_LIST', handle_search_contract_follow_list),

];
// export default responsesSaga;


function* handle_get_calendar_search_store_list(action: any): any {

  const state = yield select();　　// store.getState()と同じ


  const { payload, error } = yield call(tm_tenpo_api.list, action.payload);


  if (payload && !error) {
    console.log(payload)
    let shops_t = payload.sort((a: any, b: any) => { return a.disp_order < b.disp_order ? -1 : 1; }).filter((a: any) => a.tenpo_no > 0 && 
    a.tenpo_no <= 6 || 
    a.tenpo_no == 7 || //開発課
    a.tenpo_no == 10 || //システム課
    a.tenpo_no == 16 || //法人営業課
    a.tenpo_no == 17 || //本社サンフラワー店
    a.tenpo_no == 26 || //反響対策課
    a.tenpo_no == 47 || //中央通り店
    a.tenpo_no == 34 || //賃貸管理部
    a.tenpo_no == 37 || //新入社員
    a.tenpo_no == 21 || //社長室
    a.tenpo_no == 25 || //広報
    a.tenpo_no == 45 || //管理統括本部
    a.tenpo_no == 24 || //賃貸営業部
    a.tenpo_no == 56 || //社宅サポート
    a.tenpo_no == 55)//web戦略課

    let shops =new Array()
    shops = shops.concat(shops_t.filter((a:any) => a.tenpo_no == 45))//管理統括本部
    shops = shops.concat(shops_t.filter((a:any) => a.tenpo_no == 24))//賃貸営業部
    shops = shops.concat(shops_t.filter((a:any) => a.tenpo_no != 45 && a.tenpo_no != 21 && a.tenpo_no != 25 && a.tenpo_no != 10 && a.tenpo_no != 24 && a.tenpo_no != 56))//以外
    shops = shops.concat(shops_t.filter((a:any) => a.tenpo_no == 56))//社宅サポート
    shops = shops.concat(shops_t.filter((a:any) => a.tenpo_no == 21))//社長室
    shops = shops.concat(shops_t.filter((a:any) => a.tenpo_no == 25))//広報
    shops = shops.concat(shops_t.filter((a:any) => a.tenpo_no == 10))//システム課

    let index = shops.findIndex((a: any) => a.tenpo_no == 5)
    let nika = { tenpo_no: 502, tenpo_name: "OS　Ⅱ課", ka: 2 }
    let sanka = { tenpo_no: 503, tenpo_name: "OS　Ⅲ課", ka: 3 }

    shops[index].tenpo_no = 501
    shops[index].tenpo_name = "OS　I課"
    shops[index].ka = 1


    shops.splice(index + 1, 0, sanka)
    shops.splice(index + 1, 0, nika)

    // console.log("bbbbbbbbbbbb")
    // console.log(shops)
    // alert()
    yield put(change_calendar_search_store_list(shops));


  }
}



function* handle_get_calendar_user_details(action: any): any {

  const state = yield select();　　// store.getState()と同じ

  const { payload, error } = yield call(users_api.details, action.payload);

  if (payload && !error) {
    yield put(change_calendar_user_details(payload));

    if (state.calendar.calendar_list_form.section_id <= 0) {
      let calendar_list_form = state.calendar.calendar_list_form
      // calendar_list_form.section_id = payload.am_section_id
      calendar_list_form.section_id = [payload.am_section_id == 5 ? payload.section_group == 1 ? 501 : payload.section_group == 2 ? 502 : 503 : payload.am_section_id]
      put(change_calendar_list_form(calendar_list_form))
    }

    if (state.calendar.calendar_staff_list_form.section_id <= 0) {
      let calendar_staff_list_form = state.calendar.calendar_staff_list_form
      calendar_staff_list_form.section_id = [payload.am_section_id == 5 ? payload.section_group == 1 ? 501 : payload.section_group == 2 ? 502 : 503 : payload.am_section_id]
      put(change_calendar_staff_list_form(calendar_staff_list_form))
    }

    if (state.calendar.calendar_store_list_form.section_id <= 0) {
      let calendar_store_list_form = state.calendar.calendar_store_list_form
      calendar_store_list_form.section_id = [payload.am_section_id]
      put(change_calendar_store_list_form(calendar_store_list_form))
    }


    // yield put(get_calendar_list([]))


  }

}


function* handle_get_calendar_list(action: any): any {

  yield put(change_calender_loading(true));
  yield put(change_calendar_backdrop(true));

  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し

  let calendar_type = state.calendar.calendar_type
  let calendar_list_form = calendar_type == 0 ? state.calendar.calendar_list_form : calendar_type == 1 ? state.calendar.calendar_all_store_list_form : calendar_type == 2 ? state.calendar.calendar_staff_list_form : state.calendar.calendar_store_list_form

  yield put(change_calendar_list([]));

  //  if(action.payload){
  //   // if (action.payload[0].sectionId) {
  //     action.payload.sectionId=action.payload.sectionId.value

  // alert("list")
  // }
  // const { payload, error } = yield call(RentResponsesApi.getlist, action.payload);
  calendar_list_form.calendar_type = calendar_type
  const { payload, error } = yield call(rent_customer_contacts_api.calendar, calendar_list_form);

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {

    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し
    // console.log("nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn")
    console.log(payload)

    let payloads = payload.map(function (value: any) {

      value.start_edit = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))
      value.end_edit = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))

      if (value.type == 0 || value.type == 4) {//出勤、予定
        value.start = new Date(moment(value.start).format('YYYY/MM/DD 00:00:00'))
        value.end = new Date(moment(value.end).format('YYYY/MM/DD 00:00:00'))

      } else if (value.type == 5) {//フリー
        value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))
        value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))

      } else if (value.type == 3) {//task

        // value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))
        // value.end = moment(value.end).utc().format('HH') == "00" ? new Date(moment(value.start).utc().add(30, 'm').format('YYYY/MM/DD HH:mm')) : new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))
        if (!value.start) {
          // value.start_edit = value.start
          // value.end_edit = value.end

          value.start = new Date(moment(value.date).utc().format('YYYY/MM/DD'))
          value.end = new Date(moment(value.date).utc().format('YYYY/MM/DD'))
        }else if (moment(value.end).utc().format('HH') == "00") {

          value.end = new Date(moment(value.start).utc().add(30, 'm').format('YYYY/MM/DD HH:mm'))
          value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))

        } else {
          // alert(value.title + " " + moment(value.start) + " " + moment(value.end))
          if (moment(value.start) >= moment(value.end)) {
            value.end = new Date(moment(value.start).utc().add(30, 'm').format('YYYY/MM/DD HH:mm'))
          } else {
            value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))
          }
          value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))
        }

      } else if (value.type == 6) {//6:オーナー営業

        if (value.start == null) {
          value.start_edit = value.start
          value.end_edit = value.end

          value.start = new Date(moment(value.date).utc().format('YYYY/MM/DD'))
          value.end = new Date(moment(value.date).utc().format('YYYY/MM/DD'))

        } else {
          value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))
          value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))
          if (!(value.start < value.end)) {
            value.end = new Date(moment(value.start).add(30, 'minutes').format('YYYY/MM/DD HH:mm'))
          }
          value.start_edit = value.start
          value.end_edit = value.end
        }



        value.date = moment(value.date).utc().format("YYYY/MM/DD")


      } else if (value.type == 1) {//1:来店

        // if (value.intend_time <= 1 && value.intend_time2 <= 1) {
        //   value.start = new Date(moment(value.date).utc().format('YYYY/MM/DD'))
        //   value.end = new Date(moment(value.date).utc().format('YYYY/MM/DD'))

        // } else {
        //   // alert()
        //   value.start = new Date(moment(start).format('YYYY/MM/DD HH:mm'))
        //   if (value.intend_time < value.intend_time2) {
        //     value.end = new Date(moment(end).format('YYYY/MM/DD HH:mm'))
        //   } else {
        //     value.end = new Date(moment(start).add(30, 'minutes').format('YYYY/MM/DD HH:mm'))
        //   }
        // }

        if (value.start == null) {
          value.start_edit = value.start
          value.end_edit = value.end

          value.start = new Date(moment(value.date).utc().format('YYYY/MM/DD'))
          value.end = new Date(moment(value.date).utc().format('YYYY/MM/DD'))

        } else {
          value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))
          value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))
          if (!(value.start < value.end)) {
            value.end = new Date(moment(value.start).add(30, 'minutes').format('YYYY/MM/DD HH:mm'))
          }
          value.start_edit = value.start
          value.end_edit = value.end
        }



        value.date = moment(value.date).utc().format("YYYY/MM/DD")

      } else { // 2:契約フォロー
        if (value.start == "Invalid date") {

          value.start = value.date

          value.start_edit = null

          // value.start_edit = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))

        }

        if (value.end == "Invalid date") {

          value.end = value.date

          value.end_edit = null

          // value.end_edit = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))

        }

        if (value.start == "Invalid date") {
          value.start = value.date
          value.start_edit = null
          // value.start_edit = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))
        }
        if (value.end == "Invalid date") {
          value.end = value.date
          value.end_edit = null
          // value.end_edit = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))
        }

        if (moment(value.start).utc().format('YYYY/MM/DD HH:mm') !== moment(value.start).utc().startOf('day').format('YYYY/MM/DD HH:mm')) {

          value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))

          if (moment(value.start) < moment(new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm')))) {

            // alert("1 " + value.title + " " + new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm')) + " " + new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm')))
            value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))

            // if (moment(value.start).format('YYYY/MM/DD HH:mm') == moment(value.end).format('YYYY/MM/DD HH:mm')) {
            //   alert(value.title)
            // }

          } else {

            // alert("2 " + value.title + " " + moment(value.start) + " " + moment(value.end))
            value.end = new Date(moment(value.start).add(30, 'm').format('YYYY/MM/DD HH:mm'))

          }



        } else {


          // alert(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))
          // if(!value.start){
          //   alert(value.title)
          //   alert(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))
          //   alert(moment(value.start).utc().startOf('day').format('YYYY/MM/DD HH:mm'))
          // }
          value.start = new Date(moment(value.start).format('YYYY/MM/DD'))
          value.end = new Date(moment(value.end).format('YYYY/MM/DD'))

        }

      }



      // value.start_edit = value.start
      // value.end_edit = value.end
      if (value.type == 1 || value.type == 2 || value.type == 3 || value.type == 6) {
        // value.start = moment(value.start).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm')
        // value.end = moment(value.end).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm')
        if (moment(value.start).tz("Asia/Tokyo").hour() < 9 || moment(value.start).tz("Asia/Tokyo").hour() > 18) {
          value.start = new Date(moment(value.start).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
          value.end = new Date(moment(value.end).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))

        }else if(moment(value.end).tz("Asia/Tokyo").diff(moment(value.start).tz("Asia/Tokyo"), 'minutes') <= 30){
          value.end = new Date(moment(value.end).tz("Asia/Tokyo").add(30-(moment(value.end).tz("Asia/Tokyo").diff(moment(value.start).tz("Asia/Tokyo"), 'minutes')), 'minutes').format('YYYY/MM/DD HH:mm'))
        }

      }

      if (value.type === 0) {
        value.title = "出勤" + value.title + "名"
      } else if (value.type === 3) {
        value.title = value.title
      } else if (value.type === 1) {
        if (value.name2 && value.name2 != "") {
          value.title = value.name1 + " " + value.name2 + "様(" + value.user_nickname + ")"
        } else {
          value.title = value.name1 + "様(" + value.user_nickname + ")"
        }
      } else {
        value.title = value.title + "様(" + value.user_nickname + ")"
      }
      // value.title = value.type === 0 ? "出勤" + value.title + "名" : value.type === 3 ? value.title : value.title + "様(" + value.user_nickname + ")"
      return value
    })






//来店枠ここを復活
//    let raiten_waku = []

//     if(state.calendar.calendar_type == 2 ){//担当別のカレンダーの場合


//       let user_list = state.calendar.users.filter((a:any)=> (state.calendar.calendar_staff_list_form.section_id.includes(a.am_section_id) || 
//         // (state.calendar.calendar_staff_list_form.section_id.includes(17) ? a.id == 1020 : "") ||//和田
//         // (state.calendar.calendar_staff_list_form.section_id.includes(4) ? a.id == 1109 : "") || //清藤
//         (state.calendar.calendar_staff_list_form.section_id.includes(2) ? a.id == 1124 : "") //小野
//         // (state.calendar.calendar_staff_list_form.section_id.includes(3) ? a.id == 1015 : "") || //吉田将
//         // (state.calendar.calendar_staff_list_form.section_id.includes(3) ? a.id == 1191 : "") || //株屋根
//         // (state.calendar.calendar_staff_list_form.section_id.includes(3) ? a.id == 1121 : "") || //山内
//         // (state.calendar.calendar_staff_list_form.section_id.includes(2) ? a.id == 1119 : "") || //中村
//         // (state.calendar.calendar_staff_list_form.section_id.includes(2) ? a.id == 1419 : "") || //大森
//         // (state.calendar.calendar_staff_list_form.section_id.includes(1) ? a.id == 1005 : "") ||//若さ
//         // (state.calendar.calendar_staff_list_form.section_id.includes(1) ? a.id == 1014 : "") ||//丸岡
//         // (state.calendar.calendar_staff_list_form.section_id.includes(1) ? a.id == 1232 : "") ||//村田
//         ) && !(30 > a.disp_order && a.disp_order >= 20))




//       //人→時間でソート
//       let list2 = payloads.filter((a:any) => a.type == 1 || a.type == 2 || a.type == 3 || a.type == 6)
//       list2 = list2.slice().sort((a: any, b: any) => {      
//         // 条件1が同じ場合、条件2で降順ソート
//         if (moment(a.start) < moment(b.start)) {
//           return -1;
//         }
//         if (moment(a.start) > moment(b.start)) {
//           return 1;
//         }
//         if (moment(a.end) < moment(b.end)) {
//           return -1;
//         }
//         if (moment(a.end) > moment(b.end)) {
//           return 1;
//         }
      
//         // 両方の条件が同じ場合は変更なし
//         return 0;
//       });

//       //表示するユーザをとってくる。何もない場合があるので。
//       for(let j = 0; j<user_list.length; j++){
//         let list3 = list2.filter((a:any) => a.user_id == user_list[j].id)

//         let holiday = payloads.filter((a:any) => a.type == 0).length > 0 ? payloads.filter((a:any) => a.type == 0)[0].users.filter((a:any) => a.id == user_list[j].id).length > 0 ? payloads.filter((a:any) => a.type == 0)[0].users.filter((a:any) => a.id == user_list[j].id)[0].holiday : "" : ""
//         // 0出勤 1公休 2有給 3AM半休 4特休 5遅刻 6早退 7出張 8取り消し(=出勤) 9欠勤 11その他 12PM半休 13外出 14旅行
//         if(holiday == 1 || holiday == 2 || holiday == 4 || holiday == 9 || holiday == 14){

//         }else{
            
//           if([1,3,6,2,4,47,17].includes(user_list[j].am_section_id)){
//             // && moment(a.start).utc().format('YYYY/MM/DD') == moment(state.calendar.calendar_staff_list_form.start).utc().format('YYYY/MM/DD')
//             // console.log("list3.length",list3.length)
//             // console.log(list3)

//             //10～12
//             if(list3.filter((a:any) => 
//               (moment(a.start) <= moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 10:00')) && moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 12:00')) < moment(a.end)) || 
//               (moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 10:00')) <= moment(a.start) && moment(a.start) < moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 12:00'))) || 
//               (moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 10:00')) < moment(a.end) && moment(a.end) <= moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 12:00')))).length == 0){

//                 raiten_waku.push({
//                   start : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 10:00')),
//                   end : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 12:00')),
//                   start_edit : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 10:00')),
//                   end_edit : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 12:00')),
  
//                   color:"#d9ed8e",
//                   completed_at: null,
//                   disp_order:user_list[j].disp_order,
//                   event_type: 0,
//                   id:0,
//                   is_deleted:0,
//                   remarks: "",
//                   section_group:0,
//                   section_id:user_list[j].am_section_id,
//                   title:"[来店枠]",
//                   togetheruser_id:"",
//                   type:3,
//                   user_id:user_list[j].id,
//                   user_nickname:user_list[j].nickname,
//                   way:0
  
//                 })


//             }


//             //13～15
//             if(list3.filter((a:any) => 
//             (moment(a.start) <= moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 13:00')) && moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')) < moment(a.end)) || 
//             (moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 13:00')) <= moment(a.start) && moment(a.start) < moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00'))) || 
//             (moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 13:00')) < moment(a.end) && moment(a.end) <= moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')))).length == 0){

//               raiten_waku.push({
//                 start : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 13:00')),
//                 end : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')),
//                 start_edit : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 13:00')),
//                 end_edit : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')),

//                 color:"#d9ed8e",
//                 completed_at: null,
//                 disp_order:user_list[j].disp_order,
//                 event_type: 0,
//                 id:0,
//                 is_deleted:0,
//                 remarks: "",
//                 section_group:0,
//                 section_id:user_list[j].am_section_id,
//                 title:"[来店枠]",
//                 togetheruser_id:"",
//                 type:3,
//                 user_id:user_list[j].id,
//                 user_nickname:user_list[j].nickname,
//                 way:0

//               })
//             }


//             //15～17
//             if(list3.filter((a:any) => 
//             (moment(a.start) <= moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')) && moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 17:00')) < moment(a.end)) || 
//             (moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')) <= moment(a.start) && moment(a.start) < moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 17:00'))) || 
//             (moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')) < moment(a.end) && moment(a.end) <= moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 17:00')))).length == 0){

//               raiten_waku.push({
//                 start : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')),
//                 end : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 17:00')),
//                 start_edit : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')),
//                 end_edit : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 17:00')),

//                 color:"#d9ed8e",
//                 completed_at: null,
//                 disp_order:user_list[j].disp_order,
//                 event_type: 0,
//                 id:0,
//                 is_deleted:0,
//                 remarks: "",
//                 section_group:0,
//                 section_id:user_list[j].am_section_id,
//                 title:"[来店枠]",
//                 togetheruser_id:"",
//                 type:3,
//                 user_id:user_list[j].id,
//                 user_nickname:user_list[j].nickname,
//                 way:0

//               })
//             }



//             // //予定がないとき
//             // if(list3.length == 0){          
//             //   raiten_waku.push({
//             //     start : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 10:00')),
//             //     end : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 17:00')),
//             //     start_edit : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 10:00')),
//             //     end_edit : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 17:00')),

//             //     color:"#d9ed8e",
//             //     completed_at: null,
//             //     disp_order:user_list[j].disp_order,
//             //     event_type: 0,
//             //     id:0,
//             //     is_deleted:0,
//             //     remarks: "",
//             //     section_group:0,
//             //     section_id:user_list[j].section_id,
//             //     title:"[来店枠]",
//             //     togetheruser_id:"",
//             //     type:3,
//             //     user_id:user_list[j].id,
//             //     user_nickname:user_list[j].nickname,
//             //     way:0

//             //   })
//             //   // console.log("raiten_waku", raiten_waku)
            
//             // //予定が一つでもあるとき
//             // }else{


//             //   for(let i = 0; i<list3.length; i++){

                
//             //     if(i==0){
//             //       /////////////////////////
//             //       //10時から一つ目
//             //       //////////////////////

//             //       // 2つの日時を作成
//             //       const startDate = moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 10:00'))
//             //       let endDate = moment(moment(list3[i].start).format('YYYY/MM/DD HH:mm'))
//             //       if(parseInt(moment(endDate).format("HHmm")) > 1700){
//             //         endDate = moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 17:00'))
//             //       }
          
//             //       // 分単位の差分を計算
//             //       const diffInMinutes = endDate.diff(startDate, 'minutes');
//             //       // console.log(user_list[j].nickname)
//             //       // console.log("startDate",startDate)
//             //       // console.log("endDate",endDate)
//             //       // console.log("diffInMinutes",diffInMinutes)
//             //       if(diffInMinutes > 120){
//             //         raiten_waku.push({
//             //           start : new Date(moment(startDate).format('YYYY/MM/DD HH:mm')),
//             //           end : new Date(moment(endDate).format('YYYY/MM/DD HH:mm')),
//             //           start_edit : new Date(moment(startDate).format('YYYY/MM/DD HH:mm')),
//             //           end_edit : new Date(moment(endDate).format('YYYY/MM/DD HH:mm')),
      
//             //           color:"#d9ed8e",
//             //           completed_at: null,
//             //           disp_order:user_list[j].disp_order,
//             //           event_type: 0,
//             //           id:0,
//             //           is_deleted:0,
//             //           remarks: "",
//             //           section_group:0,
//             //           section_id:user_list[j].section_id,
//             //           title:"[来店枠]",
//             //           togetheruser_id:"",
//             //           type:3,
//             //           user_id:user_list[j].id,
//             //           user_nickname:user_list[j].nickname,
//             //           way:0
      
//             //         })
//             //       }

//             //     }else{
//             //       //////////////////////////////
//             //       //i-1目からi
//             //       ///////////////////////////

//             //       // 2つの日時を作成
//             //       let startDate = moment(moment(list3[i-1].end).format('YYYY/MM/DD HH:mm'))
//             //       if(parseInt(moment(startDate).format("HH")) < 10){
//             //         startDate = moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 10:00'))
//             //       }
//             //       let endDate = moment(moment(list3[i].start).format('YYYY/MM/DD HH:mm'))
//             //       if(parseInt(moment(endDate).format("HHmm")) > 1700){
//             //         endDate = moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 17:00'))
//             //       }
          
//             //       // 分単位の差分を計算
//             //       const diffInMinutes = endDate.diff(startDate, 'minutes');

//             //       if(diffInMinutes >= 120 && parseInt(moment(endDate).format("HH")) != 10){
//             //         raiten_waku.push({
//             //           start : new Date(moment(startDate).format('YYYY/MM/DD HH:mm')),
//             //           end : new Date(moment(endDate).format('YYYY/MM/DD HH:mm')),
//             //           start_edit : new Date(moment(startDate).format('YYYY/MM/DD HH:mm')),
//             //           end_edit : new Date(moment(endDate).format('YYYY/MM/DD HH:mm')),
      
//             //           color:"#d9ed8e",
//             //           completed_at: null,
//             //           disp_order:user_list[j].disp_order,
//             //           event_type: 0,
//             //           id:0,
//             //           is_deleted:0,
//             //           remarks: "",
//             //           section_group:0,
//             //           section_id:user_list[j].section_id,
//             //           title:"[来店枠]",
//             //           togetheruser_id:"",
//             //           type:3,
//             //           user_id:user_list[j].id,
//             //           user_nickname:user_list[j].nickname,
//             //           way:0
      
//             //         })
//             //       }



//             //     }


//             //     //////////////////////////////////
//             //     //このデータが最後なら　最後～17時
//             //     ////////////////////////////////
//             //     console.log(user_list[j].nickname)
//             //     console.log("list3.length", list3.length)
//             //     console.log("i+1", (i + 1))
//             //     if(list3.length == (i + 1)){
//             //       // 2つの日時を作成
//             //       console.log("nakaaaaaaaaaaaaaaaa")
//             //       let startDate = moment(moment(list3[i].end).format('YYYY/MM/DD HH:mm'))
//             //       if(parseInt(moment(startDate).format("HH")) < 10){
//             //         startDate = moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 10:00'))
//             //       }
//             //       let endDate = moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 17:00'))

          
//             //       // 分単位の差分を計算
//             //       const diffInMinutes = endDate.diff(startDate, 'minutes');
//             //     // console.log(user_list[j].nickname)
//             //     // console.log(list3)
//             //     console.log("startDate",startDate)
//             //     console.log("endDate",endDate)
//             //     console.log("diffInMinutes",diffInMinutes)
//             //       if(diffInMinutes >= 120){
//             //         raiten_waku.push({
//             //           start : new Date(moment(startDate).format('YYYY/MM/DD HH:mm')),
//             //           end : new Date(moment(endDate).format('YYYY/MM/DD HH:mm')),
//             //           start_edit : new Date(moment(startDate).format('YYYY/MM/DD HH:mm')),
//             //           end_edit : new Date(moment(endDate).format('YYYY/MM/DD HH:mm')),
      
//             //           color:"#d9ed8e",
//             //           completed_at: null,
//             //           disp_order:user_list[j].disp_order,
//             //           event_type: 0,
//             //           id:0,
//             //           is_deleted:0,
//             //           remarks: "",
//             //           section_group:0,
//             //           section_id:user_list[j].section_id,
//             //           title:"[来店枠]",
//             //           togetheruser_id:"",
//             //           type:3,
//             //           user_id:user_list[j].id,
//             //           user_nickname:user_list[j].nickname,
//             //           way:0
      
//             //         })
//             //       }
//             //     }




//             //   }
//             // }








//           }
//         }
//       }



  
    
//     }else if(state.calendar.calendar_type == 1){//全店のカレンダーの場合

//       let user_list = state.calendar.users.filter((a:any)=> ([1,3,6,2,4,47,17].includes(a.am_section_id) || 
//         // (state.calendar.calendar_staff_list_form.section_id.includes(17) ? a.id == 1020 : "") ||//和田
//         // (state.calendar.calendar_staff_list_form.section_id.includes(4) ? a.id == 1109 : "") || //清藤
//         ([1,3,6,2,4,47,17].includes(2) ? a.id == 1124 : "") //小野
//         // (state.calendar.calendar_staff_list_form.section_id.includes(3) ? a.id == 1015 : "") || //吉田将
//         // (state.calendar.calendar_staff_list_form.section_id.includes(3) ? a.id == 1191 : "") || //株屋根
//         // (state.calendar.calendar_staff_list_form.section_id.includes(3) ? a.id == 1121 : "") || //山内
//         // (state.calendar.calendar_staff_list_form.section_id.includes(2) ? a.id == 1119 : "") || //中村
//         // (state.calendar.calendar_staff_list_form.section_id.includes(2) ? a.id == 1419 : "") || //大森
//         // (state.calendar.calendar_staff_list_form.section_id.includes(1) ? a.id == 1005 : "") ||//若さ
//         // (state.calendar.calendar_staff_list_form.section_id.includes(1) ? a.id == 1014 : "") ||//丸岡
//         // (state.calendar.calendar_staff_list_form.section_id.includes(1) ? a.id == 1232 : "") ||//村田
//         ) && !(30 > a.disp_order && a.disp_order >= 20))


//       // console.log("user_list",user_list)

//       //人→時間でソート
//       let list2 = payloads.filter((a:any) => a.type == 1 || a.type == 2 || a.type == 3 || a.type == 6)
      

//       //表示するユーザをとってくる。何もない場合があるので。
//       for(let j = 0; j<user_list.length; j++){
//         let list3 = list2.filter((a:any) => a.user_id == user_list[j].id || (","+a.together_user_id+",").includes(","+user_list[j].id+","))
//         // console.log("list33333333333333", list3)
//         let holiday = payloads.filter((a:any) => a.type == 0).length > 0 ? payloads.filter((a:any) => a.type == 0)[0].users.filter((a:any) => a.id == user_list[j].id).length > 0 ? payloads.filter((a:any) => a.type == 0)[0].users.filter((a:any) => a.id == user_list[j].id)[0].holiday : "" : ""
//         // 0出勤 1公休 2有給 3AM半休 4特休 5遅刻 6早退 7出張 8取り消し(=出勤) 9欠勤 11その他 12PM半休 13外出 14旅行
//         if(holiday == 1 || holiday == 2 || holiday == 4 || holiday == 9 || holiday == 14){

//         }else{
            
//           if([1,3,6,2,4,47,17].includes(user_list[j].am_section_id)){

//             //10～12
//             if(list3.filter((a:any) => 
//               (moment(a.start) <= moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 10:00')) && moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 12:00')) < moment(a.end)) || 
//               (moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 10:00')) <= moment(a.start) && moment(a.start) < moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 12:00'))) || 
//               (moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 10:00')) < moment(a.end) && moment(a.end) <= moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 12:00')))).length == 0){

//                 raiten_waku.push({
//                   start : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 10:00')),
//                   end : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 12:00')),
//                   start_edit : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 10:00')),
//                   end_edit : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 12:00')),
  
//                   color:"#d9ed8e",
//                   completed_at: null,
//                   disp_order:user_list[j].disp_order,
//                   event_type: 0,
//                   id:0,
//                   is_deleted:0,
//                   remarks: "",
//                   section_group:0,
//                   section_id:user_list[j].am_section_id,
//                   title:"[来店枠]",
//                   togetheruser_id:"",
//                   type:3,
//                   user_id:user_list[j].id,
//                   user_nickname:user_list[j].nickname,
//                   way:0
  
//                 })


//             }


//             //13～15
//             if(list3.filter((a:any) => 
//             (moment(a.start) <= moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 13:00')) && moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')) < moment(a.end)) || 
//             (moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 13:00')) <= moment(a.start) && moment(a.start) < moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00'))) || 
//             (moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 13:00')) < moment(a.end) && moment(a.end) <= moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')))).length == 0){

//               raiten_waku.push({
//                 start : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 13:00')),
//                 end : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')),
//                 start_edit : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 13:00')),
//                 end_edit : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')),

//                 color:"#d9ed8e",
//                 completed_at: null,
//                 disp_order:user_list[j].disp_order,
//                 event_type: 0,
//                 id:0,
//                 is_deleted:0,
//                 remarks: "",
//                 section_group:0,
//                 section_id:user_list[j].am_section_id,
//                 title:"[来店枠]",
//                 togetheruser_id:"",
//                 type:3,
//                 user_id:user_list[j].id,
//                 user_nickname:user_list[j].nickname,
//                 way:0

//               })
//             }


//             //15～17
//             if(list3.filter((a:any) => 
//             (moment(a.start) <= moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')) && moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 17:00')) < moment(a.end)) || 
//             (moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')) <= moment(a.start) && moment(a.start) < moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 17:00'))) || 
//             (moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')) < moment(a.end) && moment(a.end) <= moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 17:00')))).length == 0){

//               raiten_waku.push({
//                 start : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')),
//                 end : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 17:00')),
//                 start_edit : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')),
//                 end_edit : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 17:00')),

//                 color:"#d9ed8e",
//                 completed_at: null,
//                 disp_order:user_list[j].disp_order,
//                 event_type: 0,
//                 id:0,
//                 is_deleted:0,
//                 remarks: "",
//                 section_group:0,
//                 section_id:user_list[j].am_section_id,
//                 title:"[来店枠]",
//                 togetheruser_id:"",
//                 type:3,
//                 user_id:user_list[j].id,
//                 user_nickname:user_list[j].nickname,
//                 way:0

//               })
//             }
//           }
//         }
//       }
      
//     }

// console.log("raiten_waku", raiten_waku)
//     let list = payloads.concat(raiten_waku)
//     list.sort(function (a: any, b: any) {
//       if (moment(a.start) > moment(b.start)) return 1;
//       if (moment(a.start) < moment(b.start)) return -1;
//       return 0;
//     });

    let list = payloads


  
    // alert(payloads.length)
    yield put(change_calendar_list(list));
    
    yield put(change_calendar_list_disp(list));
    yield put(change_calender_loading(false));
    // yield put(search_contract_follow_list(payload));
    yield put(change_calendar_backdrop(false));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}

function* handle_get_calendar_list_edit(action: any): any {

  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し

  yield put(change_calendar_list_edit([]));

  const { payload, error } = yield call(rent_customer_contacts_api.calendar, action.payload);


  if (payload && !error) {

    console.log(payload)

    //5メモは省く
    let payloads = payload.filter((a: any) => ((a.section_id == action.payload.section_id) || a.type == 0 || a.type == 4) && a.type != 5).map(function (value: any) {

      value.start_edit = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))
      value.end_edit = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))

      if (value.type == 0 || value.type == 4) {//出勤、予定
        value.start = new Date(moment(value.start).format('YYYY/MM/DD 00:00:00'))
        value.end = new Date(moment(value.end).format('YYYY/MM/DD 00:00:00'))

      } else if (value.type == 5) {//フリー
        value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))
        value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))

      } else if (value.type == 3) {//task

        // value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))
        // value.end = moment(value.end).utc().format('HH') == "00" ? new Date(moment(value.start).utc().add(30, 'm').format('YYYY/MM/DD HH:mm')) : new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))
        if (!value.start) {
          value.start = new Date(moment(value.date).utc().format('YYYY/MM/DD'))
          value.end = new Date(moment(value.date).utc().format('YYYY/MM/DD'))
        }else if (moment(value.end).utc().format('HH') == "00") {

          value.end = new Date(moment(value.start).utc().add(30, 'm').format('YYYY/MM/DD HH:mm'))
          value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))

        } else {
          // alert(value.title + " " + moment(value.start) + " " + moment(value.end))
          if (moment(value.start) >= moment(value.end)) {
            value.end = new Date(moment(value.start).utc().add(30, 'm').format('YYYY/MM/DD HH:mm'))
          } else {
            value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))
          }
          value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))
        }

      } else if (value.type == 6) {//6:オーナー営業

        if (value.start == null) {
          value.start_edit = value.start
          value.end_edit = value.end

          value.start = new Date(moment(value.date).utc().format('YYYY/MM/DD'))
          value.end = new Date(moment(value.date).utc().format('YYYY/MM/DD'))

        } else {
          value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))
          value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))
          if (!(value.start < value.end)) {
            value.end = new Date(moment(value.start).add(30, 'minutes').format('YYYY/MM/DD HH:mm'))
          }
          value.start_edit = value.start
          value.end_edit = value.end
        }



        value.date = moment(value.date).utc().format("YYYY/MM/DD")


      } else if (value.type == 1) {//1:来店

        if (value.start == null) {
          value.start_edit = value.start
          value.end_edit = value.end

          value.start = new Date(moment(value.date).utc().format('YYYY/MM/DD'))
          value.end = new Date(moment(value.date).utc().format('YYYY/MM/DD'))

        } else {
          value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))
          value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))
          if (!(value.start < value.end)) {
            value.end = new Date(moment(value.start).add(30, 'minutes').format('YYYY/MM/DD HH:mm'))
          }
          value.start_edit = value.start
          value.end_edit = value.end
        }



        value.date = moment(value.date).utc().format("YYYY/MM/DD")

      } else { // 2:契約フォロー
        if (value.start == "Invalid date") {

          value.start = value.date

          value.start_edit = null

          // value.start_edit = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))

        }

        if (value.end == "Invalid date") {

          value.end = value.date

          value.end_edit = null

          // value.end_edit = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))

        }

        if (value.start == "Invalid date") {
          value.start = value.date
          value.start_edit = null
          // value.start_edit = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))
        }
        if (value.end == "Invalid date") {
          value.end = value.date
          value.end_edit = null
          // value.end_edit = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))
        }

        if (moment(value.start).utc().format('YYYY/MM/DD HH:mm') !== moment(value.start).utc().startOf('day').format('YYYY/MM/DD HH:mm')) {

          value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))

          if (moment(value.start) < moment(new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm')))) {

            // alert("1 " + value.title + " " + new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm')) + " " + new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm')))
            value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))

            // if (moment(value.start).format('YYYY/MM/DD HH:mm') == moment(value.end).format('YYYY/MM/DD HH:mm')) {
            //   alert(value.title)
            // }

          } else {

            // alert("2 " + value.title + " " + moment(value.start) + " " + moment(value.end))
            value.end = new Date(moment(value.start).add(30, 'm').format('YYYY/MM/DD HH:mm'))

          }



        } else {

          value.start = new Date(moment(value.start).format('YYYY/MM/DD'))
          value.end = new Date(moment(value.end).format('YYYY/MM/DD'))

        }

      }



      // value.start_edit = value.start
      // value.end_edit = value.end
      if (value.type == 1 || value.type == 2 || value.type == 3 || value.type == 6) {
        // value.start = moment(value.start).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm')
        // value.end = moment(value.end).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm')
        if (moment(value.start).tz("Asia/Tokyo").hour() < 9 || moment(value.start).tz("Asia/Tokyo").hour() > 18) {
          value.start = new Date(moment(value.start).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
          value.end = new Date(moment(value.end).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
        }else if(moment(value.end).tz("Asia/Tokyo").diff(moment(value.start).tz("Asia/Tokyo"), 'minutes') <= 30){
          value.end = new Date(moment(value.end).tz("Asia/Tokyo").add(30-(moment(value.end).tz("Asia/Tokyo").diff(moment(value.start).tz("Asia/Tokyo"), 'minutes')), 'minutes').format('YYYY/MM/DD HH:mm'))
        }

      }

      if (value.type === 0) {
        value.title = "出勤" + value.title + "名"
      } else if (value.type === 3) {
        value.title = value.title
      } else if (value.type === 1) {
        if (value.name2 && value.name2 != "") {
          value.title = value.name1 + " " + value.name2 + "様(" + value.user_nickname + ")"
        } else {
          value.title = value.name1 + "様(" + value.user_nickname + ")"
        }
      } else {
        value.title = value.title + "様(" + value.user_nickname + ")"
      }
      // value.title = value.type === 0 ? "出勤" + value.title + "名" : value.type === 3 ? value.title : value.title + "様(" + value.user_nickname + ")"
      return value
    })

    let list = payloads
    list.sort(function (a: any, b: any) {
      if (moment(a.start) > moment(b.start)) return 1;
      if (moment(a.start) < moment(b.start)) return -1;
      return 0;
    });


    // 日付が移動されたとき　
    if (action.payload.calendar_list_edit_data) {
      // リスト上にそのデータある場合
      if (list.filter((a: any) => a.id == action.payload.calendar_list_edit_data.id && a.type == 1).length > 0) {
        // alert("ある")
        list = list.map((value: any) => {
          if (value.id == action.payload.calendar_list_edit_data.id && value.type == 1) {
            // alert(action.payload.calendar_list_edit_data.id)
            value.start = new Date(action.payload.rent_customer_contacts_calendar_forms.start)
            value.end = new Date(action.payload.rent_customer_contacts_calendar_forms.end)
          }
          return value
        })

        // 新規の場合
      } else {
        // alert("nai")
        let calendar_list_edit_data_tmp = action.payload.calendar_list_edit_data
        calendar_list_edit_data_tmp.type = 1
        calendar_list_edit_data_tmp.date = new Date(action.payload.rent_customer_contacts_calendar_forms.date)
        calendar_list_edit_data_tmp.start = new Date(action.payload.rent_customer_contacts_calendar_forms.start)
        calendar_list_edit_data_tmp.end = new Date(action.payload.rent_customer_contacts_calendar_forms.end)

        list = list.concat(calendar_list_edit_data_tmp)
      }

    }


    // console.log("vvvvvvvvvvvvvv",list)
    // alert(payloads.length)
    yield put(change_calendar_list_edit(list));


  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}

//担当別
function* handle_set_calendar_list_raitenwaku_user(action: any): any {
  const state = yield select();

  let calendar_list = action.payload.calendar_list.filter((a:any) => !(a.type == 3 && a.id == 0 && a.user_id == action.payload.user_id))//来店枠をのけたリスト
  let list2 = calendar_list.filter((a:any) => (a.type == 1 || a.type == 2 || a.type == 3 || a.type == 6) && a.user_id == action.payload.user_id)

  //営業の人リスト
  let user_list = state.calendar.users.filter((a:any)=> (state.calendar.calendar_staff_list_form.section_id.includes(a.am_section_id) 
  // (state.calendar.calendar_staff_list_form.section_id.includes(17) ? a.id == 1020 : "") ||//和田
  // (state.calendar.calendar_staff_list_form.section_id.includes(4) ? a.id == 1109 : "") || //清藤
  // (state.calendar.calendar_staff_list_form.section_id.includes(2) ? a.id == 1124 : "") //小野
  || (state.calendar.calendar_staff_list_form.section_id.includes(3) ? a.id == 1015 : "")  //吉田将 駅前
  // || (state.calendar.calendar_staff_list_form.section_id.includes(6) ? a.id == 1015 : "")  //吉田将 中央公園
  || (state.calendar.calendar_staff_list_form.section_id.includes(3) ? a.id == 1079 : "")  //景山 駅前
  // (state.calendar.calendar_staff_list_form.section_id.includes(3) ? a.id == 1191 : "") || //株屋根
  // (state.calendar.calendar_staff_list_form.section_id.includes(3) ? a.id == 1121 : "") || //山内
  // (state.calendar.calendar_staff_list_form.section_id.includes(2) ? a.id == 1119 : "") || //中村
  // (state.calendar.calendar_staff_list_form.section_id.includes(2) ? a.id == 1419 : "") || //大森
  // (state.calendar.calendar_staff_list_form.section_id.includes(1) ? a.id == 1005 : "") ||//若さ
  // (state.calendar.calendar_staff_list_form.section_id.includes(1) ? a.id == 1014 : "") ||//丸岡
  // (state.calendar.calendar_staff_list_form.section_id.includes(1) ? a.id == 1232 : "") ||//村田
  ) && !(30 > a.disp_order && a.disp_order >= 20))

  // alert(action.user_id)
  // alert(user_list.filter((a:any)=>a.id == action.payload.user_id).length)
  console.log("user_list",user_list)
  //対象の営業の人がいたら
  if(user_list.filter((a:any)=>a.id == action.payload.user_id).length > 0){

    let raiten_waku = []
    let list3 = list2

    let holiday = calendar_list.filter((a:any) => a.type == 0).length > 0 ? calendar_list.filter((a:any) => a.type == 0)[0].users.filter((a:any) => a.id == action.payload.user_id).length > 0 ? calendar_list.filter((a:any) => a.type == 0)[0].users.filter((a:any) => a.id == action.payload.user_id)[0].holiday : "" : ""
    // 0出勤 1公休 2有給 3AM半休 4特休 5遅刻 6早退 7出張 8取り消し(=出勤) 9欠勤 11その他 12PM半休 13外出 14旅行
    if(holiday == 1 || holiday == 2 || holiday == 4 || holiday == 9 || holiday == 14){

    }else{

      const user_detail = user_list.filter((a:any)=>a.id == action.payload.user_id)[0]
      if([1,3,6,2,4,47,17].includes(user_detail.am_section_id)){

        //10～12
        if(list3.filter((a:any) => 
          (moment(a.start) <= moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 10:00')) && moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 12:00')) < moment(a.end)) || 
          (moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 10:00')) <= moment(a.start) && moment(a.start) < moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 12:00'))) || 
          (moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 10:00')) < moment(a.end) && moment(a.end) <= moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 12:00')))).length == 0){

            raiten_waku.push({
              start : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 10:00')),
              end : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 12:00')),
              start_edit : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 10:00')),
              end_edit : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 12:00')),

              color:"#d9ed8e",
              completed_at: null,
              limited_at: null,
              client_user_id: user_detail.id,
              disp_order:user_detail.disp_order,
              event_type: 0,
              id:0,
              is_deleted:0,
              remarks: "",
              section_group:0,
              section_id:user_detail.am_section_id,
              title:"[来店枠]",
              togetheruser_id:"",
              type:3,
              user_id:user_detail.id,
              user_nickname:user_detail.nickname,
              way:0

            })


        }


        //13～15
        if(list3.filter((a:any) => 
        (moment(a.start) <= moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 13:00')) && moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')) < moment(a.end)) || 
        (moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 13:00')) <= moment(a.start) && moment(a.start) < moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00'))) || 
        (moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 13:00')) < moment(a.end) && moment(a.end) <= moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')))).length == 0){

          raiten_waku.push({
            start : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 13:00')),
            end : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')),
            start_edit : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 13:00')),
            end_edit : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')),

            color:"#d9ed8e",
            completed_at: null,
            limited_at: null,
            client_user_id: user_detail.id,
            disp_order:user_detail.disp_order,
            event_type: 0,
            id:0,
            is_deleted:0,
            remarks: "",
            section_group:0,
            section_id:user_detail.am_section_id,
            title:"[来店枠]",
            togetheruser_id:"",
            type:3,
            user_id:user_detail.id,
            user_nickname:user_detail.nickname,
            way:0

          })
        }


        //15～17
        if(list3.filter((a:any) => 
        (moment(a.start) <= moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')) && moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 17:00')) < moment(a.end)) || 
        (moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')) <= moment(a.start) && moment(a.start) < moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 17:00'))) || 
        (moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')) < moment(a.end) && moment(a.end) <= moment(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 17:00')))).length == 0){

          raiten_waku.push({
            start : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')),
            end : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 17:00')),
            start_edit : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 15:00')),
            end_edit : new Date(moment(state.calendar.calendar_staff_list_form.start).format('YYYY/MM/DD 17:00')),

            color:"#d9ed8e",
            completed_at: null,
            limited_at: null,
            client_user_id: user_detail.id,
            disp_order:user_detail.disp_order,
            event_type: 0,
            id:0,
            is_deleted:0,
            remarks: "",
            section_group:0,
            section_id:user_detail.am_section_id,
            title:"[来店枠]",
            togetheruser_id:"",
            type:3,
            user_id:user_detail.id,
            user_nickname:user_detail.nickname,
            way:0

          })
        }


      }

    }

    console.log("raiten_waku", raiten_waku)
    yield put(change_calendar_list(calendar_list.concat(raiten_waku)));

  }





  

}

//全店
function* handle_set_calendar_list_raitenwaku_allstore(action: any): any {
  const state = yield select();

  let calendar_list = action.payload.calendar_list.filter((a:any) => !(a.type == 3 && a.id == 0))//来店枠をのけたリスト
  let list2 = calendar_list.filter((a:any) => (a.type == 1 || a.type == 2 || a.type == 3 || a.type == 6))

  //営業の人リスト
  let user_list = state.calendar.users.filter((a:any)=> ([1,3,6,2,4,47,17].includes(a.am_section_id) 
  // (state.calendar.calendar_staff_list_form.section_id.includes(17) ? a.id == 1020 : "") ||//和田
  // (state.calendar.calendar_staff_list_form.section_id.includes(4) ? a.id == 1109 : "") || //清藤
  // ([1,3,6,2,4,47,17].includes(2) ? a.id == 1124 : "") //小野
  || (state.calendar.calendar_staff_list_form.section_id.includes(3) ? a.id == 1015 : "") //吉田将 駅前
  // || (state.calendar.calendar_staff_list_form.section_id.includes(6) ? a.id == 1015 : "") //吉田将 中央公園
  || (state.calendar.calendar_staff_list_form.section_id.includes(3) ? a.id == 1079 : "") //景山 駅前
  // (state.calendar.calendar_staff_list_form.section_id.includes(3) ? a.id == 1191 : "") || //株屋根
  // (state.calendar.calendar_staff_list_form.section_id.includes(3) ? a.id == 1121 : "") || //山内
  // (state.calendar.calendar_staff_list_form.section_id.includes(2) ? a.id == 1119 : "") || //中村
  // (state.calendar.calendar_staff_list_form.section_id.includes(2) ? a.id == 1419 : "") || //大森
  // (state.calendar.calendar_staff_list_form.section_id.includes(1) ? a.id == 1005 : "") ||//若さ
  // (state.calendar.calendar_staff_list_form.section_id.includes(1) ? a.id == 1014 : "") ||//丸岡
  // (state.calendar.calendar_staff_list_form.section_id.includes(1) ? a.id == 1232 : "") ||//村田
  ) && !(30 > a.disp_order && a.disp_order >= 20))


  // console.log("user_list",user_list)
  //対象の営業の人がいたら
  if(user_list.length > 0){

    let raiten_waku = []

    //表示するユーザをとってくる。何もない場合があるので。
    for(let j = 0; j<user_list.length; j++){
      let list3 = list2.filter((a:any) => a.user_id == user_list[j].id || (","+a.together_user_id+",").includes(","+user_list[j].id+","))

      let holiday = calendar_list.filter((a:any) => a.type == 0 && a.user_id == user_list[j].id).length > 0 ? calendar_list.filter((a:any) => a.type == 0)[0].users.filter((a:any) => a.id == user_list[j].id).length > 0 ? calendar_list.filter((a:any) => a.type == 0)[0].users.filter((a:any) => a.id == user_list[j].id)[0].holiday : "" : ""
      // 0出勤 1公休 2有給 3AM半休 4特休 5遅刻 6早退 7出張 8取り消し(=出勤) 9欠勤 11その他 12PM半休 13外出 14旅行
      if(holiday == 1 || holiday == 2 || holiday == 4 || holiday == 9 || holiday == 14){

      }else{

        const user_detail = user_list.filter((a:any)=>a.id == user_list[j].id)[0]
        if([1,3,6,2,4,47,17].includes(user_detail.am_section_id)){

          //10～12
          if(list3.filter((a:any) => 
            (moment(a.start) <= moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 10:00')) && moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 12:00')) < moment(a.end)) || 
            (moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 10:00')) <= moment(a.start) && moment(a.start) < moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 12:00'))) || 
            (moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 10:00')) < moment(a.end) && moment(a.end) <= moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 12:00')))).length == 0){

              raiten_waku.push({
                start : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 10:00')),
                end : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 12:00')),
                start_edit : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 10:00')),
                end_edit : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 12:00')),

                color:"#d9ed8e",
                completed_at: null,
                limited_at: null,
                client_user_id: user_detail.id,
                disp_order:user_detail.disp_order,
                event_type: 0,
                id:0,
                is_deleted:0,
                remarks: "",
                section_group:0,
                section_id:user_detail.am_section_id,
                title:"[来店枠]",
                togetheruser_id:"",
                type:3,
                user_id:user_detail.id,
                user_nickname:user_detail.nickname,
                way:0

              })


          }


          //13～15
          if(list3.filter((a:any) => 
          (moment(a.start) <= moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 13:00')) && moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')) < moment(a.end)) || 
          (moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 13:00')) <= moment(a.start) && moment(a.start) < moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00'))) || 
          (moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 13:00')) < moment(a.end) && moment(a.end) <= moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')))).length == 0){

            raiten_waku.push({
              start : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 13:00')),
              end : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')),
              start_edit : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 13:00')),
              end_edit : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')),

              color:"#d9ed8e",
              completed_at: null,
              limited_at: null,
              client_user_id: user_detail.id,
              disp_order:user_detail.disp_order,
              event_type: 0,
              id:0,
              is_deleted:0,
              remarks: "",
              section_group:0,
              section_id:user_detail.am_section_id,
              title:"[来店枠]",
              togetheruser_id:"",
              type:3,
              user_id:user_detail.id,
              user_nickname:user_detail.nickname,
              way:0

            })
          }


          //15～17
          if(list3.filter((a:any) => 
          (moment(a.start) <= moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')) && moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 17:00')) < moment(a.end)) || 
          (moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')) <= moment(a.start) && moment(a.start) < moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 17:00'))) || 
          (moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')) < moment(a.end) && moment(a.end) <= moment(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 17:00')))).length == 0){

            raiten_waku.push({
              start : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')),
              end : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 17:00')),
              start_edit : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 15:00')),
              end_edit : new Date(moment(state.calendar.calendar_all_store_list_form.start).format('YYYY/MM/DD 17:00')),

              color:"#d9ed8e",
              completed_at: null,
              limited_at: null,
              client_user_id: user_detail.id,
              disp_order:user_detail.disp_order,
              event_type: 0,
              id:0,
              is_deleted:0,
              remarks: "",
              section_group:0,
              section_id:user_detail.am_section_id,
              title:"[来店枠]",
              togetheruser_id:"",
              type:3,
              user_id:user_detail.id,
              user_nickname:user_detail.nickname,
              way:0

            })
          }


        }


        

      }
    }
        // console.log("raiten_waku", raiten_waku)
        yield put(change_calendar_list(calendar_list.concat(raiten_waku)));

  }





  

}

function* handle_insert_rent_task_detail(action: any): any {
  const state = yield select();

  let calendar_list = state.calendar.calendar_list
  let rent_task_detail = JSON.parse(JSON.stringify(state.calendar.rent_task_detail))
  let users = state.masterReducer.users

  if(action.payload == 3){// 完了
    rent_task_detail.completed_at = moment().format("YYYY/MM/DD hh:mm:ss")
    rent_task_detail.is_deleted = 0
  }else if(action.payload == 4){// 未完了
    rent_task_detail.completed_at = null
    rent_task_detail.is_deleted = 0
  }else{
    rent_task_detail.is_deleted = action.payload
  }

  rent_task_detail.together_user_id = rent_task_detail.together_user_id ? rent_task_detail.together_user_id.map(function (item:any) {
    return item.id
  }).join(',') : ""

  const { payload, error } = yield call(rent_tasks_api.insert, rent_task_detail);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    let result = {...payload}

    result.type = 3

    result.date = new Date(moment(result.date).utc().format('YYYY/MM/DD'))
    if (!result.start_at) {
      result.start = new Date(moment(result.date).format('YYYY/MM/DD'))
      result.end = new Date(moment(result.date).format('YYYY/MM/DD'))
    }else{
      result.start = new Date(moment(result.start_at).utc().format('YYYY/MM/DD HH:mm'))
      result.end = new Date(moment(result.end_at).utc().format('YYYY/MM/DD HH:mm'))
    }

    // result.start = new Date(moment(result.start_at).utc().format('YYYY/MM/DD HH:mm'))
    // result.end = new Date(moment(result.end_at).utc().format('YYYY/MM/DD HH:mm'))
    if (moment(result.start_at).tz("Asia/Tokyo").utc().hour() < 9 || moment(result.start_at).tz("Asia/Tokyo").utc().hour() > 18) {
      result.start = new Date(moment(result.start_at).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
      result.end = new Date(moment(result.start_at).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
    }else if(moment(result.end_at).tz("Asia/Tokyo").utc().diff(moment(result.start_at).tz("Asia/Tokyo").utc(), 'minutes') <= 30){
      result.end = new Date(moment(result.end_at).tz("Asia/Tokyo").utc().add(30-(moment(result.end_at).tz("Asia/Tokyo").utc().diff(moment(result.start_at).tz("Asia/Tokyo").utc(), 'minutes')), 'minutes').format('YYYY/MM/DD HH:mm'))
    }
    result.start_edit = new Date(moment(result.start_at).utc().format('YYYY/MM/DD HH:mm'))
    result.end_edit = new Date(moment(result.end_at).utc().format('YYYY/MM/DD HH:mm'))
    result.remarks = result.remarks
    result.way = 0
    result.user_id = result.user_id
    result.user_nickname = users.filter((a: any) => parseInt(a.id) == parseInt(result.user_id))[0].nickname
    result.title = result.title
    result.color = result.color
    result.event_type = result.event_type
    result.rent_m_todo_category_id = result.rent_m_todo_category_id
    result.completed_at = result.completed_at
    result.limited_at = result.limited_at
    result.client_user_id = result.client_user_id
    result.is_deleted = result.is_deleted
    result.section_id = result.section_id
    result.section_group = result.section_group


    if(!result.is_deleted){
      //同行者
      if(result.together_user_id){

        let together_user_ids = result.together_user_id.split(',')
        for(let ti=0; ti < together_user_ids.length; ti++){
          let value_task=Object.assign({}, result);
          let user_detail = state.calendar.users.filter((a:any)=>a.id == parseInt(together_user_ids[ti]))[0]
          value_task.section_id = user_detail.am_section_id
          value_task.user_nickname = user_detail.nickname
          value_task.section_group = user_detail.section_group
          value_task.user_id = together_user_ids[ti]
          value_task.is_together = 1
          value_task.back_user_id = result.user_id
          value_task.back_section_id = result.section_id
          value_task.back_section_group = result.section_group

          // console.log(value_task)


          //　　　分ける基準
          // 今週　人が選ばれている時　同行者が違う店舗いる
          // 担当別　いつも
          // 全店　同行者が違う店舗にいる。
          // 日別　同行者が違う店舗にいる
          if (state.calendar.calendar_type == 0 && (state.calendar.calendar_list_form.user_id > 0)) {//今週
            if (parseInt(together_user_ids[ti]) == parseInt(state.calendar.calendar_list_form.user_id)) {
              calendar_list = calendar_list.concat(value_task)
            }
          } else if (state.calendar.calendar_type == 0 && (result.section_id != value_task.section_id)) {//今週
            calendar_list = calendar_list.concat(value_task)
          } else if (state.calendar.calendar_type == 1 && result.section_id != value_task.section_id) {//全店
            calendar_list = calendar_list.concat(value_task)
          } else if (state.calendar.calendar_type == 2) {//担当別
            calendar_list = calendar_list.concat(value_task)
          } else if (state.calendar.calendar_type == 3 && result.section_id != value_task.section_id) {//日別
            calendar_list = calendar_list.concat(value_task)
          } else {
            result.user_nickname = result.user_nickname + "," + user_detail.nickname
          }


        }
      }
      calendar_list = calendar_list.concat(result)
    }

    yield put(change_calendar_list(calendar_list));
    yield put(change_task_edit_modal(false))

    //来店枠ここを復活
    // //来店枠をのけるため
    // if(state.calendar.calendar_type == 2 ){//担当別のカレンダーの場合
    //   yield put(set_calendar_list_raitenwaku_user({calendar_list: calendar_list, user_id: result.user_id}));
    // }else if(state.calendar.calendar_type == 1){//全店のカレンダーの場合
    //   yield put(set_calendar_list_raitenwaku_allstore({calendar_list: calendar_list}));
    // }



  } else {
    alert("保存に失敗しました。")
  }
}

function* handle_update_rent_task_detail(action: any): any {
  const state = yield select();

  let calendar_list = state.calendar.calendar_list
  let rent_task_detail = {...state.calendar.rent_task_detail}
  let users = state.masterReducer.users  

  if(action.payload == 3){// 完了
    rent_task_detail.completed_at = moment().format("YYYY/MM/DD hh:mm:ss")
    rent_task_detail.is_deleted = 0
    rent_task_detail.deleted_at = null
  }else if(action.payload == 4){// 未完了
    rent_task_detail.completed_at = null
    rent_task_detail.is_deleted = 0
    rent_task_detail.deleted_at = null
  }else if(action.payload == 1){// 削除
    rent_task_detail.is_deleted = action.payload
    rent_task_detail.deleted_at = moment().format("YYYY/MM/DD HH:mm")
  }else{//保存
    rent_task_detail.is_deleted = action.payload
    rent_task_detail.deleted_at = null
  }

  rent_task_detail.together_user_id = rent_task_detail.together_user_id ? rent_task_detail.together_user_id.map(function (item:any) {
    return item.id
  }).join(',') : ""

  if(rent_task_detail.start_at == "Invalid date"){
    rent_task_detail.start_at = null
  }
  if(rent_task_detail.end_at == "Invalid date"){
    rent_task_detail.end_at = null
  }

  const { payload, error } = yield call(rent_tasks_api.update, rent_task_detail);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {



    //一覧から削除
    calendar_list = calendar_list.filter((value: any) => {
      return (!(value.type == 3 && value.id == payload.id));
    });

    let result = {...payload}
    result.type = 3
    result.date = new Date(moment(result.date).utc().format('YYYY/MM/DD'))
    if (!result.start_at) {
      result.start = new Date(moment(result.date).format('YYYY/MM/DD'))
      result.end = new Date(moment(result.date).format('YYYY/MM/DD'))
    }else{
      result.start = new Date(moment(result.start_at).utc().format('YYYY/MM/DD HH:mm'))
      result.end = new Date(moment(result.end_at).utc().format('YYYY/MM/DD HH:mm'))
    }

    if (moment(result.start_at).tz("Asia/Tokyo").utc().hour() < 9 || moment(result.start_at).tz("Asia/Tokyo").utc().hour() > 18) {
      result.start = new Date(moment(result.start_at).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
      result.end = new Date(moment(result.start_at).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
    }else if(moment(result.end_at).tz("Asia/Tokyo").utc().diff(moment(result.start_at).tz("Asia/Tokyo").utc(), 'minutes') <= 30){
      result.end = new Date(moment(result.end_at).tz("Asia/Tokyo").utc().add(30-(moment(result.end_at).tz("Asia/Tokyo").utc().diff(moment(result.start_at).tz("Asia/Tokyo").utc(), 'minutes')), 'minutes').format('YYYY/MM/DD HH:mm'))
    }
    result.start_edit = new Date(moment(result.start_at).utc().format('YYYY/MM/DD HH:mm'))
    result.end_edit = new Date(moment(result.end_at).utc().format('YYYY/MM/DD HH:mm'))
    result.remarks = result.remarks
    result.way = 0
    result.user_id = result.user_id
    result.user_nickname = users.filter((a: any) => parseInt(a.id) == parseInt(result.user_id))[0].nickname
    result.title = result.title
    result.color = result.color
    result.event_type = result.event_type
    result.rent_m_todo_category_id = result.rent_m_todo_category_id
    result.completed_at = result.completed_at
    result.limited_at = result.limited_at
    result.client_user_id = result.client_user_id

    result.is_deleted = result.is_deleted
    result.section_id = result.section_id
    result.section_group = result.section_group


    if(!result.is_deleted){
      //同行者
      if(result.together_user_id){

        let together_user_ids = result.together_user_id.split(',')
        for(let ti=0; ti < together_user_ids.length; ti++){
          let value_task=Object.assign({}, result);
          let user_detail = state.calendar.users.filter((a:any)=>a.id == parseInt(together_user_ids[ti]))[0]
          value_task.section_id = user_detail.am_section_id
          value_task.user_nickname = user_detail.nickname
          value_task.section_group = user_detail.section_group
          value_task.user_id = together_user_ids[ti]
          value_task.is_together = 1
          value_task.back_user_id = result.user_id
          value_task.back_section_id = result.section_id
          value_task.back_section_group = result.section_group

          // console.log(value_task)


          //　　　分ける基準
          // 今週　人が選ばれている時　同行者が違う店舗いる
          // 担当別　いつも
          // 全店　同行者が違う店舗にいる。
          // 日別　同行者が違う店舗にいる
          if (state.calendar.calendar_type == 0 && (state.calendar.calendar_list_form.user_id > 0)) {//今週
            if (parseInt(together_user_ids[ti]) == parseInt(state.calendar.calendar_list_form.user_id)) {
              calendar_list = calendar_list.concat(value_task)
            }
          } else if (state.calendar.calendar_type == 0 && (result.section_id != value_task.section_id)) {//今週
            calendar_list = calendar_list.concat(value_task)
          } else if (state.calendar.calendar_type == 1 && result.section_id != value_task.section_id) {//全店
            calendar_list = calendar_list.concat(value_task)
          } else if (state.calendar.calendar_type == 2) {//担当別
            calendar_list = calendar_list.concat(value_task)
          } else if (state.calendar.calendar_type == 3 && result.section_id != value_task.section_id) {//日別
            calendar_list = calendar_list.concat(value_task)
          } else {
            result.user_nickname = result.user_nickname + "," + user_detail.nickname
          }


        }
      }
      calendar_list = calendar_list.concat(result)
    }
    
    // 成功時アクション呼び出し
    // ブログ一覧更新
    yield put(change_calendar_list(calendar_list))
    yield put(change_task_edit_modal(false))


    /////////////////////////////////////////////////////////////////////////////
    //カレンダー付のダイアログからの保存
    let calendar_list_edit = state.calendar.calendar_list_edit

    yield put(change_calendar_list_edit([]))

    //一覧から削除
    calendar_list_edit = calendar_list_edit.filter((value: any) => {
      return (!(value.type == 3 && value.id == payload.id));
    });

    result = {...payload}

    result.type = 3
    result.date = new Date(moment(result.date).utc().format('YYYY/MM/DD HH:mm'))
    if (!result.start_at) {
      result.start = new Date(moment(result.date).format('YYYY/MM/DD'))
      result.end = new Date(moment(result.date).format('YYYY/MM/DD'))
    }else{
      result.start = new Date(moment(result.start_at).utc().format('YYYY/MM/DD HH:mm'))
      result.end = new Date(moment(result.end_at).utc().format('YYYY/MM/DD HH:mm'))
    }
    if (moment(result.start_at).tz("Asia/Tokyo").utc().hour() < 9 || moment(result.start_at).tz("Asia/Tokyo").utc().hour() > 18) {
      result.start = new Date(moment(result.start_at).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
      result.end = new Date(moment(result.start_at).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
    }else if(moment(result.end_at).tz("Asia/Tokyo").utc().diff(moment(result.start_at).tz("Asia/Tokyo").utc(), 'minutes') <= 30){
      result.end = new Date(moment(result.end_at).tz("Asia/Tokyo").utc().add(30-(moment(result.end_at).tz("Asia/Tokyo").utc().diff(moment(result.start_at).tz("Asia/Tokyo").utc(), 'minutes')), 'minutes').format('YYYY/MM/DD HH:mm'))
    }
    result.start_edit = new Date(moment(result.start_at).utc().format('YYYY/MM/DD'))
    result.end_edit = new Date(moment(result.end_at).utc().format('YYYY/MM/DD HH:mm'))
    result.remarks = result.remarks
    result.way = 0
    result.user_id = result.user_id
    result.user_nickname = users.filter((a: any) => parseInt(a.id) == parseInt(result.user_id))[0].nickname
    result.title = result.title
    result.color = result.color
    result.event_type = result.event_type
    result.rent_m_todo_category_id = result.rent_m_todo_category_id
    result.completed_at = result.completed_at
    result.limited_at = result.limited_at
    result.client_user_id = result.client_user_id

    result.is_deleted = result.is_deleted
    result.section_id = result.section_id
    result.section_group = result.section_group


    if(!result.is_deleted){
      //同行者
      if(result.together_user_id){

        let together_user_ids = result.together_user_id.split(',')
        for(let ti=0; ti < together_user_ids.length; ti++){
          let value_task=Object.assign({}, result);
          let user_detail = state.calendar.users.filter((a:any)=>a.id == parseInt(together_user_ids[ti]))[0]
          value_task.section_id = user_detail.am_section_id
          value_task.user_nickname = user_detail.nickname
          value_task.section_group = user_detail.section_group
          value_task.user_id = together_user_ids[ti]
          value_task.is_together = 1
          value_task.back_user_id = result.user_id
          value_task.back_section_id = result.section_id
          value_task.back_section_group = result.section_group

          // console.log(value_task)


          //　　　分ける基準
          // 今週　人が選ばれている時　同行者が違う店舗いる
          // 担当別　いつも
          // 全店　同行者が違う店舗にいる。
          // 日別　同行者が違う店舗にいる。

            result.user_nickname = result.user_nickname + "," + user_detail.nickname

        }
      }
      calendar_list_edit = calendar_list_edit.concat(result)
    }


    // 成功時アクション呼び出し
    // ブログ一覧更新
    yield put(change_calendar_list_edit(calendar_list_edit))
    yield put(change_calendar_list_edit_dialog_disp(false))//カレンダー付のダイアログ






    //ダッシュボードのダイアログからの保存
    let task_rent_responses_list = state.task.task_rent_responses_list

    // yield put(Task.change_task_rent_responses_list([]))


    //一覧から削除
    task_rent_responses_list = task_rent_responses_list.filter((value: any) => {
      return (!(value.type == 3 && value.id == payload.id));
    });

    result = {...payload}

    result.type = 3
    result.date = new Date(moment(result.date).format('YYYY/MM/DD'))
    if (!result.start_at) {
      result.start = new Date(moment(result.date).format('YYYY/MM/DD'))
      result.end = new Date(moment(result.date).format('YYYY/MM/DD'))
    }else{
      result.start = new Date(moment(result.start_at).format('YYYY/MM/DD HH:mm'))
      result.end = new Date(moment(result.end_at).format('YYYY/MM/DD HH:mm'))
    }
        
    result.remarks = result.remarks
    result.way = 0
    result.user_id = result.user_id
    result.user_nickname = users.filter((a: any) => parseInt(a.id) == parseInt(result.user_id))[0].nickname
    result.title = result.title
    result.color = result.color
    result.event_type = result.event_type
    result.rent_m_todo_category_id = result.rent_m_todo_category_id
    result.completed_at = result.completed_at
    result.limited_at = result.limited_at
    result.client_user_id = result.client_user_id

    result.is_deleted = result.is_deleted
    result.section_id = result.section_id
    result.section_group = result.section_group


    if(!result.is_deleted){
      //同行者
      if(result.together_user_id){

        let together_user_ids = result.together_user_id.split(',')
        for(let ti=0; ti < together_user_ids.length; ti++){
          let value_task=Object.assign({}, result);
          let user_detail = state.calendar.users.filter((a:any)=>a.id == parseInt(together_user_ids[ti]))[0]
          value_task.section_id = user_detail.am_section_id
          value_task.user_nickname = user_detail.nickname
          value_task.section_group = user_detail.section_group
          value_task.user_id = together_user_ids[ti]
          value_task.is_together = 1
          value_task.back_user_id = result.user_id
          value_task.back_section_id = result.section_id
          value_task.back_section_group = result.section_group

          if(state.task.rent_tasks_disp_form.section_id > 0 && state.task.rent_tasks_disp_form.section_id == value_task.section_id){
            task_rent_responses_list = task_rent_responses_list.concat(value_task)
          }
          

        }
      }
      if(state.task.rent_tasks_disp_form.section_id > 0 && state.task.rent_tasks_disp_form.section_id == result.section_id){
        task_rent_responses_list = task_rent_responses_list.concat(result)
      }
    }

    // 成功時アクション呼び出し
    // ブログ一覧更新
    yield put(Task.change_task_rent_responses_list(task_rent_responses_list))
    yield put(Task.search_task_rent_responses_list(task_rent_responses_list))
    yield put(Task.change_rent_tasks_modal(false))// ダッシュボードのダイアログ

    //来店枠ここを復活
    // //来店枠をのけるため
    // if(state.calendar.calendar_type == 2 ){//担当別のカレンダーの場合
    //   yield put(set_calendar_list_raitenwaku_user({calendar_list: calendar_list, user_id: result.user_id}));
    // }else if(state.calendar.calendar_type == 1){//全店のカレンダーの場合
    //   yield put(set_calendar_list_raitenwaku_allstore({calendar_list: calendar_list}));
    // }

  } else {
      alert("保存に失敗しました。")
  }


    // yield put(Task.change_rent_tasks_modal(false))
}

//
function* handle_set_calendar_free_detail(action: any): any {

  const state = yield select();

  let calendar_free_list_base = state.calendar.calendar_list.filter((a: any) => a.id === action.payload)[0]

  let calendar_free_detail: any = {}
  calendar_free_detail.id = calendar_free_list_base.id
  calendar_free_detail.is_free = 1
  calendar_free_detail.remarks = calendar_free_list_base.remarks
  calendar_free_detail.section_id = calendar_free_list_base.section_id == 5 && calendar_free_list_base.section_group == 1 ? 501 : calendar_free_list_base.section_id == 5 && calendar_free_list_base.section_group == 2 ? 502 : calendar_free_list_base.section_id == 5 && calendar_free_list_base.section_group == 3 ? 503 : calendar_free_list_base.section_id
  calendar_free_detail.user_id = calendar_free_list_base.user_id ? calendar_free_list_base.user_id : 0
  calendar_free_detail.start_at = moment(calendar_free_list_base.start).format('YYYY/MM/DD HH:mm:ss')
  calendar_free_detail.end_at = moment(calendar_free_list_base.end).format('YYYY/MM/DD HH:mm:ss')
  calendar_free_detail.color = calendar_free_list_base.color
  calendar_free_detail.completed_at = calendar_free_list_base.completed_at
  calendar_free_detail.limited_at = calendar_free_list_base.limited_at
  calendar_free_detail.client_user_id = calendar_free_list_base.client_user_id
  calendar_free_detail.is_deleted = calendar_free_list_base.is_deleted

  yield put(change_calendar_free_detail(calendar_free_detail))


}

// 自由入力更新
function* handle_update_calendar_free_detail(action: any): any {

  // alert(action.payload)

  const state = yield select();

  let calendar_free_detail = state.calendar.calendar_free_detail

  calendar_free_detail.deleted_at = null

  if(action.payload == 3){// 完了
    calendar_free_detail.completed_at = moment().format("YYYY/MM/DD hh:mm:ss")
  }else if(action.payload == 4){// 未完了
    calendar_free_detail.completed_at = null
  }else if(action.payload == 1){// 削除
    calendar_free_detail.is_deleted = action.payload
    calendar_free_detail.deleted_at = moment().format("YYYY/MM/DD HH:mm")
  }else{
    calendar_free_detail.is_deleted = action.payload
  }
  calendar_free_detail.date = calendar_free_detail.start_at

  const { payload, error } = yield call(rent_tasks_api.update, calendar_free_detail);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    let calendar_list = state.calendar.calendar_list

    // 一覧の情報更新
    calendar_list = calendar_list.map(function (value: any) {
      if (value.id === payload.id) {
        value.remarks = payload.remarks
        value.user_id = payload.user_id
        // value.start = payload.start_at
        // value.end = payload.end_at
        value.date = new Date(moment(payload.start_at).utc().format('YYYY/MM/DD HH:mm'))
        value.start = new Date(moment(payload.start_at).utc().format('YYYY/MM/DD HH:mm'))
        value.end = new Date(moment(payload.end_at).utc().format('YYYY/MM/DD HH:mm'))
        value.color = payload.color
        value.completed_at = payload.completed_at
        value.is_deleted = payload.is_deleted
        value.limited_at = payload.limited_at
        value.client_user_id = payload.client_user_id
        // value.yoyaku_date = payload.Yoyaku_Date
        // value.tantou_user_id = payload.Tantou_User_Id
        // value.raiten_kbn_no = paylo
        // value.naiyou = payload.Naiyou
        // value.komatte = payload.Komatte
        // value.outai_naiyou_no = payload.Outai_Naiyou_No
        // value.outai_shubetsu_no = payload.Outai_Shubetsu_No
        // value.promise = payload.Promise
        // value.suggestions = payload.Suggestions
        // value.no_count = payload.No_Count
      }
      return value
    })

    //一覧から削除
    let result = calendar_list.filter((value: any) => {
      return (!(value.id == payload.id && value.is_deleted == 1));
    });
    calendar_list = result

    // 成功時アクション呼び出し
    // ブログ一覧更新
    yield put(change_calendar_list(calendar_list))
    // 情報更新
    // yield put(change_rent_corporate_contacts(payload))




    let task_rent_responses_list = state.task.task_rent_responses_list

    // 一覧の情報更新
    task_rent_responses_list = task_rent_responses_list.map(function (value: any) {
      if (value.id === payload.id) {
        value.remarks = payload.remarks
        value.user_id = payload.user_id
        value.date = new Date(moment(payload.start_at).utc().format('YYYY/MM/DD HH:mm'))
        value.start = new Date(moment(payload.start_at).utc().format('YYYY/MM/DD HH:mm'))
        value.end = new Date(moment(payload.end_at).utc().format('YYYY/MM/DD HH:mm'))
        value.color = payload.color
        value.completed_at = payload.completed_at
        value.is_deleted = payload.is_deleted
        value.limited_at = payload.limited_at
        value.client_user_id = payload.client_user_id
      }
      return value
    })

    //一覧から削除
    result = task_rent_responses_list.filter((value: any) => {
      return (!(value.id == payload.id && value.is_deleted == 1));
    });
    task_rent_responses_list = result

    // 成功時アクション呼び出し
    // ブログ一覧更新
    yield put(Task.change_task_rent_responses_list(task_rent_responses_list))
    yield put(Task.search_task_rent_responses_list(task_rent_responses_list))
    yield put(Task.change_rent_tasks_modal(false))// ダッシュボードのダイアログ




  } else {

  }
}
// 自由入力新規
function* handle_insert_calendar_free_detail(action: any): any {

  const state = yield select();

  let calendar_free_detail = state.calendar.calendar_free_detail

  if(action.payload == 3){// 完了
    calendar_free_detail.completed_at = moment().format("YYYY/MM/DD hh:mm:ss")
  }else if(action.payload == 4){// 未完了
    calendar_free_detail.completed_at = null
  }else{
    calendar_free_detail.is_deleted = action.payload
  }

  calendar_free_detail.date = calendar_free_detail.start_at

  const { payload, error } = yield call(rent_tasks_api.insert, calendar_free_detail);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    let calendar_list = state.calendar.calendar_list

    // let list = owner_eigyou.concat(payload)
    // list.sort(function (a: any, b: any) {
    //     if (moment(a.yoyaku_date) > moment(b.yoyaku_date)) return -1;
    //     if (moment(a.yoyaku_date) < moment(b.yoyaku_date)) return 1;
    //     return 0;
    // });



    // // 成功時アクション呼び出し
    yield put(change_calendar_list(calendar_list.concat({
      color: payload.color, id: payload.id, is_deleted: 0, completed_at: payload.completed_at,
      remarks: payload.remarks, section_id: payload.section_id, 
      date: new Date(moment(payload.start_at).utc().format('YYYY/MM/DD HH:mm')),
      start:  new Date(moment(payload.start_at).utc().format('YYYY/MM/DD HH:mm')), 
      end:  new Date(moment(payload.end_at).utc().format('YYYY/MM/DD HH:mm')), 
      title: payload.title, type: 5, user_id: payload.user_id,
      limited_at: payload.limited_at, client_user_id: payload.client_user_id,
    })))

  } else {

  }
}

// 項目別集計 CSV
function* handle_get_calendar_type_total_csv(action: any): any {

  // yield put(change_tm_owner_detail_backdrop(true));

  const state = yield select();

  let calendar_staff_list_form = state.calendar.calendar_staff_list_form

  const { payload, error } = yield call(rent_tasks_api.total_csv, calendar_staff_list_form);
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
      // 成功時アクション呼び出し
      // yield put(change_tm_owner_list_csv(payload.list))
      // yield put(change_tm_owner_list_csv_disp(true))

      const a = document.createElement('a');
      // ダウンロードされるファイル名
      a.download = "項目別集計.csv";
      // a.download = "10.pdf";
      a.href = URL.createObjectURL(payload);
      // ダウンロード開始
      a.click();

  } else {

  }

  // yield put(change_tm_owner_detail_backdrop(false));

}

// オーナー所有 営業対応ダイアログの物件リスト用
function* handle_get_eigyou_owner_room(action: any): any {
  const state = yield select();

  const { payload, error } = yield call(t_owner_room_api.detaillist, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
      // console.log(payload)

      let list = [
          {
              tatemono_id: "",
              tatemono_name: "",
              kanri_corp_name: "",
              tatemono_address: "",
              shunkou: "",
              area: "",
              kanri_1: 0,
              kanri_kbn1_1: 0,
              kanri_kbn2_1: 0,
              kanri_kbn3_1: 0,
              kanri_kbn4_1: 0,
              kanri_kbn5_1: 0,
              kanri_kbn6_1: 0,
              aki_1: 0,

              kanri_2: 0,
              kanri_kbn1_2: 0,
              kanri_kbn2_2: 0,
              kanri_kbn3_2: 0,
              kanri_kbn4_2: 0,
              kanri_kbn5_2: 0,
              kanri_kbn6_2: 0,
              aki_2: 0,

          }
      ];

      payload.forEach(function (value: any) {

          let index = list.findIndex(e => e.tatemono_id == value.tatemono_id)

          if (index > -1) {// ある場合
              if (value.attribute == 1) {
                  if (value.kanri_kubun_no == 1) {
                      list[index].kanri_kbn1_1++
                  } else if (value.kanri_kubun_no == 2) {
                      list[index].kanri_kbn2_1++
                  } else if (value.kanri_kubun_no == 3) {
                      list[index].kanri_kbn3_1++
                  } else if (value.kanri_kubun_no == 4) {
                      list[index].kanri_kbn4_1++
                  } else if (value.kanri_kubun_no == 5) {
                      list[index].kanri_kbn5_1++
                  } else if (value.kanri_kubun_no == 6) {
                      list[index].kanri_kbn6_1++
                  }
                  if (!(value.kuushitsu_joukyou_no == 3 || value.kuushitsu_joukyou_no == 4 || value.kuushitsu_joukyou_no == 100)) {
                      list[index].aki_1++
                  }
                  list[index].kanri_1++
              } else {
                  if (value.kanri_kubun_no == 1) {
                      list[index].kanri_kbn1_2++
                  } else if (value.kanri_kubun_no == 2) {
                      list[index].kanri_kbn2_2++
                  } else if (value.kanri_kubun_no == 3) {
                      list[index].kanri_kbn3_2++
                  } else if (value.kanri_kubun_no == 4) {
                      list[index].kanri_kbn4_2++
                  } else if (value.kanri_kubun_no == 5) {
                      list[index].kanri_kbn5_2++
                  } else if (value.kanri_kubun_no == 6) {
                      list[index].kanri_kbn6_2++
                  }
                  if (!(value.kuushitsu_joukyou_no == 3 || value.kuushitsu_joukyou_no == 4 || value.kuushitsu_joukyou_no == 100)) {
                      list[index].aki_2++
                  }
                  list[index].kanri_2++
              }


              // list[index].keiyaku.push({id : value.rent_contract_id, contractor_name : value.contractor_name1, renter_name : value.renter1_name1, rent_response_id:value.rent_response_id, start_date : value.start_date, end_date : value.end_date, payment_date : value.payment_date, decision_date : value.decision_date})
          } else {// ない場合
              let tmp = {
                  tatemono_id: value.tatemono_id,
                  tatemono_name: value.tatemono_name,
                  kanri_corp_name: value.kanri_corp_name,
                  tatemono_address: value.tatemono_address,
                  shunkou: value.shunkou,
                  area: value.area,

                  kanri_1: value.attribute == 1 ? 1 : 0,
                  kanri_kbn1_1: value.attribute == 1 && value.kanri_kubun_no == 1 ? 1 : 0,
                  kanri_kbn2_1: value.attribute == 1 && value.kanri_kubun_no == 2 ? 1 : 0,
                  kanri_kbn3_1: value.attribute == 1 && value.kanri_kubun_no == 3 ? 1 : 0,
                  kanri_kbn4_1: value.attribute == 1 && value.kanri_kubun_no == 4 ? 1 : 0,
                  kanri_kbn5_1: value.attribute == 1 && value.kanri_kubun_no == 5 ? 1 : 0,
                  kanri_kbn6_1: value.attribute == 1 && value.kanri_kubun_no == 6 ? 1 : 0,
                  aki_1: value.attribute == 1 && !(value.kuushitsu_joukyou_no == 3 || value.kuushitsu_joukyou_no == 4 || value.kuushitsu_joukyou_no == 100) ? 1 : 0,

                  kanri_2: value.attribute == 2 ? 1 : 0,
                  kanri_kbn1_2: value.attribute == 2 && value.kanri_kubun_no == 1 ? 1 : 0,
                  kanri_kbn2_2: value.attribute == 2 && value.kanri_kubun_no == 2 ? 1 : 0,
                  kanri_kbn3_2: value.attribute == 2 && value.kanri_kubun_no == 3 ? 1 : 0,
                  kanri_kbn4_2: value.attribute == 2 && value.kanri_kubun_no == 4 ? 1 : 0,
                  kanri_kbn5_2: value.attribute == 2 && value.kanri_kubun_no == 5 ? 1 : 0,
                  kanri_kbn6_2: value.attribute == 2 && value.kanri_kubun_no == 6 ? 1 : 0,
                  aki_2: value.attribute == 2 && !(value.kuushitsu_joukyou_no == 3 || value.kuushitsu_joukyou_no == 4 || value.kuushitsu_joukyou_no == 100) ? 1 : 0,

              }
              list.push(tmp)
          }
      })

      list.shift()

      yield put(change_eigyou_owner_room(list));

   

  } else {
      alert(error)
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
  }
}

// オーナー営業対応履歴　新プランプラマイリスト
function* handle_get_t_owner_plan_counts_list(action: any): any {
  const state = yield select();


  const { payload, error } = yield call(t_owner_plan_counts_api.list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
      // console.log(payload)
      yield put(change_t_owner_plan_counts_list(payload));
      yield put(get_tm_owner_plan_list());

  } else {
      alert(error)
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
  }
}

// 物件
function* handle_get_tm_tatemono_list_master(action: any) {

  const { payload, error } = yield call(tm_tatemono_api.list, {});
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

      // 成功時アクション呼び出し
      yield put(change_tm_tatemono_list_master(payload))

  } else {

  }

}

// 物件
function* handle_get_rent_m_todo_category_list(action: any) {

  const { payload, error } = yield call(rent_m_todo_categories_api.list, {});
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

      // 成功時アクション呼び出し
      yield put(change_rent_m_todo_category_list(payload))

  } else {

  }

}

// プラン一覧
function* handle_get_tm_owner_plan_list(action: any) {

  const { payload, error } = yield call(tm_owner_plan_api.list, {});
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

      // 成功時アクション呼び出し
      yield put(change_tm_owner_plan_list(payload))

  } else {

  }

}

// 法人 全件
function* handle_get_eigyou_rent_m_corporate_all_list(action: any): any {
  const state = yield select();

  const { payload, error } = yield call(rent_m_corporates_api.list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
      // console.log(payload)
      yield put(change_eigyou_rent_m_corporate_all_list(payload));

  } else {
      alert(error)
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
  }

}

// オーナー検索用　オーナー名
function* handle_get_tm_owner_search_owner_name_list(action: any): any {

  const state = yield select();

  let tm_owner_search_form = state.calendar.tm_owner_search_form

  const { payload, error } = yield call(tm_owner_api.list_owner_search, tm_owner_search_form);
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

      // 成功時アクション呼び出し
      yield put(change_tm_owner_search_owner_name_list(payload))
  } else {

  }
}
// オーナー検索用　物件
function* handle_get_tm_owner_search_building_list(action: any): any {

  const state = yield select();

  let tm_owner_search_form = state.calendar.tm_owner_search_form

  const { payload, error } = yield call(tm_owner_api.list_bukken_search, tm_owner_search_form);
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

      // 成功時アクション呼び出し
      yield put(change_tm_owner_search_building_list(payload))
  } else {

  }
}

// 営業対応履歴 いいね
function* handle_update_eigyou_detail_confirmers(action: any): any {

  // alert(action.payload)

  const state = yield select();

  let eigyou_detail = JSON.parse(JSON.stringify(state.calendar.eigyou_detail))


  // いいね済み
  // if ( ("," + eigyou_detail.confirmers).indexOf(action.payload) != -1) {
  if (eigyou_detail.confirmers && eigyou_detail.confirmers.split(",").includes(String(action.payload))) {
    //削除
    eigyou_detail.confirmers = eigyou_detail.confirmers.split(",").filter((a:any) => a != String(action.payload)).join(',')

  }else{
    let confirmers = eigyou_detail.confirmers ? eigyou_detail.confirmers.split(",") : []
    confirmers.push(action.payload)
    eigyou_detail.confirmers = confirmers.join(',')
  }

  const { payload, error } = yield call(t_eigyou_api.confirmers, eigyou_detail);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

      let calendar_list = state.calendar.calendar_list
// alert(eigyou_detail.type + " " + eigyou_detail.before_owner_id + " " + eigyou_detail.eigyou_no)
      //一覧から削除
      calendar_list = calendar_list.filter((value: any) => {
        if(value.type == 6 && value.type6 == eigyou_detail.type && value.owner_id === eigyou_detail.before_owner_id && value.eigyou_no === eigyou_detail.eigyou_no){
          value.confirmers = eigyou_detail.confirmers
        }
        return value
      });
  
      // 成功時アクション呼び出し
      // ブログ一覧更新
      yield put(change_eigyou_detail(JSON.parse(JSON.stringify(eigyou_detail))))
      yield put(change_calendar_list(calendar_list))
      // yield put(change_eigyo_edit_modal(false))

  } else {

  }

}

// 営業対応履歴更新
function* handle_update_eigyou_detail(action: any): any {

  // alert(action.payload)

  const state = yield select();

  let eigyou_detail = JSON.parse(JSON.stringify(state.calendar.eigyou_detail))

  eigyou_detail.start_at = eigyou_detail.start_at.replace('__:__', "")
  eigyou_detail.end_at = eigyou_detail.end_at.replace('__:__', "")

  let aaa = eigyou_detail.together_user_id ? eigyou_detail.together_user_id.map(function (item:any) {
    
    return String(item.id)
  }).join(',') : ""
  eigyou_detail.together_user_id =aaa

  aaa = eigyou_detail.outai_naiyou_id ? eigyou_detail.outai_naiyou_id.map(function (item:any) {
    
    return String(item.value)
  }).join(',') : ""
  eigyou_detail.outai_naiyou_id =aaa

  eigyou_detail.new_plan = state.calendar.t_owner_plan_counts_list

  const { payload, error } = yield call(eigyou_detail.type == 1 ? t_eigyou_api.update : rent_corporate_contacts_api.update, eigyou_detail);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

      let calendar_list = state.calendar.calendar_list
// alert(eigyou_detail.type + " " + eigyou_detail.before_owner_id + " " + eigyou_detail.eigyou_no)
      //一覧から削除
      calendar_list = calendar_list.filter((value: any) => {
        return (!(value.type == 6 && value.type6 == eigyou_detail.type && value.owner_id === eigyou_detail.before_owner_id && value.eigyou_no === eigyou_detail.eigyou_no));
      });

      let result = payload

      if (result.start == null) {
        result.start_edit = result.start
        result.end_edit = result.end

        result.start = new Date(moment(result.date).utc().format('YYYY/MM/DD'))
        result.end = new Date(moment(result.date).utc().format('YYYY/MM/DD'))

      } else {
        result.start = new Date(moment(result.start).utc().format('YYYY/MM/DD HH:mm'))
        result.end = new Date(moment(result.end).utc().format('YYYY/MM/DD HH:mm'))
        if (!(result.start < result.end)) {
          result.end = new Date(moment(result.start).add(30, 'minutes').format('YYYY/MM/DD HH:mm'))
        }
        result.start_edit = result.start
        result.end_edit = result.end
      }

      if (moment(result.start).tz("Asia/Tokyo").hour() < 9 || moment(result.start).tz("Asia/Tokyo").hour() > 18) {
        result.start = new Date(moment(result.start).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
        result.end = new Date(moment(result.end).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
      }else if(moment(result.end).tz("Asia/Tokyo").diff(moment(result.start).tz("Asia/Tokyo"), 'minutes') <= 30){
        result.end = new Date(moment(result.end).tz("Asia/Tokyo").add(30-(moment(result.end).tz("Asia/Tokyo").diff(moment(result.start).tz("Asia/Tokyo"), 'minutes')), 'minutes').format('YYYY/MM/DD HH:mm'))
      }

      result.date = moment(result.date).utc().format("YYYY/MM/DD")
      let back_title = result.title 
      

      

      // 同行者
      if(result.together_user_id){

        let together_user_ids = result.together_user_id.split(',')
        for(let ti=0; ti < together_user_ids.length; ti++){
          let value_eigyo=Object.assign({}, result);
          let user_detail = state.calendar.users.filter((a:any)=>a.id == parseInt(together_user_ids[ti]))[0]
          value_eigyo.section_id = user_detail.am_section_id
          value_eigyo.user_nickname = user_detail.nickname
          value_eigyo.section_group = user_detail.section_group
          value_eigyo.user_id = together_user_ids[ti]
          value_eigyo.is_together = 1
          value_eigyo.back_user_id = result.user_id
          value_eigyo.title = back_title + "様(" + value_eigyo.user_nickname + ")"
          // console.log(value_eigyo)


          //　　　分ける基準
          // 今週　人が選ばれている時　同行者が違う店舗いる
          // 担当別　いつも
          // 全店　同行者が違う店舗にいる。
          // 日別　同行者が違う店舗にいる。
          if(state.calendar.calendar_type == 0 && (result.section_id != value_eigyo.section_id)){//今週
            calendar_list = calendar_list.concat(value_eigyo)
          }else if(state.calendar.calendar_type == 0 && (state.calendar.calendar_list_form.user_id > 0)){//今週
            if(together_user_ids[ti] == state.calendar.calendar_list_form.user_id){
              calendar_list = calendar_list.concat(value_eigyo)
            }
          }else if(state.calendar.calendar_type == 1 && result.section_id != value_eigyo.section_id){//全店
            calendar_list = calendar_list.concat(value_eigyo)
          }else if(state.calendar.calendar_type == 2){//担当別
            calendar_list = calendar_list.concat(value_eigyo)
          }else if(state.calendar.calendar_type == 3 && result.section_id != value_eigyo.section_id){//日別
            calendar_list = calendar_list.concat(value_eigyo)
          }else{
            result.user_nickname = result.user_nickname + "," + user_detail.nickname
          }
        }
      }

      result.title = back_title + "様(" + result.user_nickname + ")"
      calendar_list = calendar_list.concat(result)
  
      // 成功時アクション呼び出し
      // ブログ一覧更新
      yield put(change_calendar_list(calendar_list))
      yield put(change_eigyo_edit_modal(false))

  } else {

  }

}

// 営業履歴削除
function* handle_delete_eigyou_detail(action: any): any {

  // alert(action.payload)

  const state = yield select();

  let eigyou_detail = state.calendar.eigyou_detail

  eigyou_detail.is_deleted = 1
  const { payload, error } = yield call(eigyou_detail.type == 1 ? t_eigyou_api.deleteeigyou : rent_corporate_contacts_api.update, eigyou_detail);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    let calendar_list = state.calendar.calendar_list

      //一覧から削除
      calendar_list = calendar_list.filter((value: any) => {
        return (!(value.type == 6 && value.type6 == eigyou_detail.type && value.owner_id === eigyou_detail.before_owner_id && value.eigyou_no === eigyou_detail.eigyou_no));
      });

      yield put(change_calendar_list(calendar_list))
      yield put(change_eigyo_edit_modal(false))


  } else {
      // alert()
  }
}

// 営業対応履歴新規
function* handle_insert_eigyou_detail(action: any): any {

  const state = yield select();

  let eigyou_detail = JSON.parse(JSON.stringify(state.calendar.eigyou_detail))
  
  eigyou_detail.start_at = eigyou_detail.start_at.replace('__:__', "")
  eigyou_detail.end_at = eigyou_detail.end_at.replace('__:__', "")

  eigyou_detail.together_user_id = eigyou_detail.together_user_id ? eigyou_detail.together_user_id.map(function (item:any) {
    return item.id
  }).join(',') : ""

  eigyou_detail.outai_naiyou_id = eigyou_detail.outai_naiyou_id ? eigyou_detail.outai_naiyou_id.map(function (item:any) {
    return item.value
  }).join(',') : ""

  eigyou_detail.new_plan = state.calendar.t_owner_plan_counts_list

  const { payload, error } = yield call(eigyou_detail.type == 1 ? t_eigyou_api.insert : rent_corporate_contacts_api.insert, eigyou_detail);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    let calendar_list = state.calendar.calendar_list

    let result = payload
    if (result.start == null) {
      result.start_edit = result.start
      result.end_edit = result.end

      result.start = new Date(moment(result.date).utc().format('YYYY/MM/DD'))
      result.end = new Date(moment(result.date).utc().format('YYYY/MM/DD'))

    } else {
      result.start = new Date(moment(result.start).utc().format('YYYY/MM/DD HH:mm'))
      result.end = new Date(moment(result.end).utc().format('YYYY/MM/DD HH:mm'))
      if (!(result.start < result.end)) {
        result.end = new Date(moment(result.start).add(30, 'minutes').format('YYYY/MM/DD HH:mm'))
      }
      result.start_edit = result.start
      result.end_edit = result.end
    }
    result.date = moment(result.date).utc().format("YYYY/MM/DD")



    let back_title = result.title 
    

    // 同行者
    if(result.together_user_id){

      let together_user_ids = result.together_user_id.split(',')
      for(let ti=0; ti < together_user_ids.length; ti++){
        let value_eigyo=Object.assign({}, result);
        let user_detail = state.calendar.users.filter((a:any)=>a.id == parseInt(together_user_ids[ti]))[0]
        value_eigyo.section_id = user_detail.am_section_id
        value_eigyo.user_nickname = user_detail.nickname
        value_eigyo.section_group = user_detail.section_group
        value_eigyo.user_id = together_user_ids[ti]
        value_eigyo.is_together = 1
        value_eigyo.back_user_id = result.user_id
        value_eigyo.title = back_title + "様(" + value_eigyo.user_nickname + ")"
        // console.log(value_eigyo)


        //　　　分ける基準
        // 今週　人が選ばれている時　同行者が違う店舗いる
        // 担当別　いつも
        // 全店　同行者が違う店舗にいる。
        // 日別　同行者が違う店舗にいる。
        if(state.calendar.calendar_type == 0 && (result.section_id != value_eigyo.section_id)){//今週
          calendar_list = calendar_list.concat(value_eigyo)
        }else if(state.calendar.calendar_type == 0 && (state.calendar.calendar_list_form.user_id > 0)){//今週
          if(together_user_ids[ti] == state.calendar.calendar_list_form.user_id){
            calendar_list = calendar_list.concat(value_eigyo)
          }
        }else if(state.calendar.calendar_type == 1 && result.section_id != value_eigyo.section_id){//全店
          calendar_list = calendar_list.concat(value_eigyo)
        }else if(state.calendar.calendar_type == 2){//担当別
          calendar_list = calendar_list.concat(value_eigyo)
        }else if(state.calendar.calendar_type == 3 && result.section_id != value_eigyo.section_id){//日別
          calendar_list = calendar_list.concat(value_eigyo)
        }else{
          result.user_nickname = result.user_nickname + "," + user_detail.nickname
        }
      }
    }

    result.title = back_title + "様(" + result.user_nickname + ")"
    calendar_list = calendar_list.concat(result)

    // 成功時アクション呼び出し
    // ブログ一覧更新
    yield put(change_calendar_list(calendar_list))
    yield put(change_eigyo_edit_modal(false))
      

  } else {

  }
}


function* handle_get_users(action: any) {

  const { payload, error } = yield call(users_api.list, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // 成功時アクション呼び出し
    yield put(change_users(payload))
  } else {

  }
}

function* handle_get_calendar_tm_owners(action: any) {

  const { payload, error } = yield call(tm_owner_api.list_master, {});
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // 成功時アクション呼び出し
    yield put(change_calendar_tm_owners(payload))
  } else {

  }
}