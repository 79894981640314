import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Calendarsaga from './Saga/Calendar';
import StoreCalendar from "./StoreCalendar";
import StoreSearch from "./StoreSearch";
import FreeDialog from "./FreeDialog";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Scrollbars } from 'react-custom-scrollbars';

import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import Editor from '../ContractFollow/Editor';
import EigyoDialog from './EigyoDialog';
import * as ContractFollow from '../ContractFollow/Saga/ContractFollow';
import * as CustomerContacts from '../CustomerContacts/Saga/CustomerContacts';
import ContactEditComponent from '../CustomerContacts/ContactEditComponent';
import TaskEditor from './TaskEditor';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Modal } from 'reactstrap';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
// import { start } from 'repl';

var moment = require('moment-timezone');
var top = null
var left = null

var useStyles = makeStyles({
  avatar: {
    // backgroundColor: blue[100],
    // color: blue[600],
  },
  dialog: {
    position: 'absolute',
    left: 520 + left <= window.innerWidth ? left : (left - 520 > 0 ? left - 520 : 0),
    top: 580 + top <= window.innerHeight ? top : (top - 580 > 0 ? top - 580 : 0),
  }
});


function TaskDialog(props) {


  const classes = useStyles();
  const { onClose, open, event_type, change_task_edit_modal, task_edit_modal, change_contract_follow_edit_modal, contract_follow_edit_modal, change_customer_contacts_modal, customer_contacts_modal, eigyo_edit_modal } = props;


  const handleClose = () => {
    onClose(event_type);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const close_task_edit_modal = (value) => {
    onClose(event_type);
  };

  const close_contract_follow_edit_modal = (value) => {
    onClose(event_type);
    // change_contract_follow_edit_modal(false);
  };

  const close_customer_contacts_modal = (value) => {

    onClose(event_type);
    // change_contract_follow_edit_modal(false);
  };

  const close_eigyo_edit_modal = (value) => {

    onClose(event_type);
    // change_contract_follow_edit_modal(false);
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog
      }}
      fullWidth
      maxWidth={event_type === 6 ? "xl":"sm"}
      onClose={handleClose} aria-labelledby="simple-dialog-title" open={event_type === 1 ? customer_contacts_modal : (event_type === 2 ? contract_follow_edit_modal : event_type === 6 ? eigyo_edit_modal : task_edit_modal)}>

      {event_type === 1 ? <ContactEditComponent onClose={close_customer_contacts_modal} />
        : event_type === 2 ? <Editor onClose={close_contract_follow_edit_modal} /> :
        event_type === 6 ? <EigyoDialog onClose={close_eigyo_edit_modal} /> :
          <TaskEditor onClose={close_task_edit_modal} />}


      {/* {event_type === 1 && (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 1 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 2 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 5 || (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 3 && Number(this.props.rent_customer_contacts_forms.intend) !== 1) || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 12 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 13 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 14) ? "" : <button className="btn btn-primary float-right" type="button" onClick={this.props.add_rent_customer_contacts}>保存</button>}
      {event_type === 1 && (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 1 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 2 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 5 || (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 3 && Number(this.props.rent_customer_contacts_forms.intend) !== 1) || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 12 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 13 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 14) ? "" : (this.state.contactstate && this.state.contactstate.id ? <button className="btn btn-danger float-right" type="button" onClick={this.toggledelete}>削除</button> : "")}*/}
    </Dialog>
  );
}


class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reflesh: false,
      open: false,
      event_type: 0
    }
    this.set = this.set.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    // this.props.get_calendar_list();

    // this.props.get_users()

  }

  set(event_type) {

    if (event_type === 1) {
      this.props.change_customer_contacts_modal(true)
    } else if (event_type === 2) {
      this.props.change_contract_follow_edit_modal(true)
    } else if (event_type === 3) {
      this.props.change_task_edit_modal(true)
    } else if (event_type === 6) {
      this.props.change_eigyo_edit_modal(true)
    }
    this.setState({ event_type: event_type })
    this.setState({ reflesh: !this.state.reflesh })
  }



  handleClose = (value) => {

    if (value === 3) {
      // this.setState({ open: false })

      // let rent_task_detail = this.props.rent_task_detail

      // if (rent_task_detail.start_at_pre) {

      //   let calendar_list = this.props.calendar_list
      //   // 一覧のもとにもどす。
      //   calendar_list = calendar_list.map(function (value) {
      //     if (value.type == 3 && value.id === rent_task_detail.id) {
      //       // alert(rent_task_detail.start_at_pre)
      //       value.start = new Date(moment(rent_task_detail.start_at_pre).format('YYYY/MM/DD HH:mm'))
      //       value.end = new Date(moment(rent_task_detail.end_at_pre).format('YYYY/MM/DD HH:mm'))

      //       // value.end = moment(rent_task_detail.end_at_pre).utc().format('YYYY/MM/DD HH:mm')
      //     }
      //     return value
      //   })


      //   this.props.change_calendar_list(calendar_list)
      // }

      // rent_task_detail.id = ""
      // rent_task_detail.title = ""
      // rent_task_detail.section_id = ""
      // rent_task_detail.user_id = ""
      // rent_task_detail.start_at = ""
      // rent_task_detail.end_at = ""
      // rent_task_detail.remarks = ""
      // rent_task_detail.color = "#F9A7A7"
      // rent_task_detail.start_at_pre = ""
      // rent_task_detail.end_at_pre = ""




      // // alert( this.props.user_details.id)
      // this.props.change_rent_task_detail(rent_task_detail)
      this.props.change_task_edit_modal(false)

      this.props.change_calendar_list(this.props.calendar_precalendar)
      
    } else if (value === 2) {

      this.props.change_contract_follow_edit_modal(false)

      this.props.change_calendar_list(this.props.calendar_precalendar)



    } else if (value === 1) {

      this.props.change_customer_contacts_modal(false)

      // this.props.change_calendar_list(this.state.precalendar)
      this.props.change_calendar_list(this.props.calendar_precalendar)


    } else if (value === 6) {

      this.props.change_eigyo_edit_modal(false)

      // this.props.change_calendar_list(this.state.precalendar)
      this.props.change_calendar_list(this.props.calendar_precalendar)


    }

    this.setState({ reflesh: !this.state.reflesh })
    this.props.change_calendar_precalendar([])

  };

  handleOpen(id, section_id, user_id, start, end) {
    if (id) {
        this.props.set_calendar_free_detail(id)
    } else {
        // 新規
        let calendar_free_detail = { 
            id : "",
            is_free : 1,
            remarks : "",
            section_id : section_id,
            user_id : user_id,
            start_at : start,
            end_at : end,
            color : "",
            completed_at: null,
            limited_at: null,
            client_user_id : user_id,
            is_deleted : 0,
        }
        this.props.change_calendar_free_detail(calendar_free_detail)
    }
    this.props.change_calendar_free_dialog(true)
    // this.setState({ "modal": true })
}

  render() {
    return (
      <div>
        <Card style={{display:'flex'}}>
          <CardContent style={{padding:0}}>
            <StoreSearch />
          </CardContent >
      {/* 槙塚さん"1007"　のけるとき1007でフィルターかけてるところはずす  */}
          <CardContent style={{padding:1,width:window.innerWidth-170}}>
            {/* {this.props.calendar_store_list_form.section_id} */}
            
            <div style={{ overflow: "hidden" }}>
              <div style={{ float: "left", width: (window.innerWidth - 170 - 88) / 5 }}><div><div style={{ fontWeight: "bold", width: "100%", textAlign: "center", border: "1px solid #DDD", whiteSpace: "nowrap" }}>来店</div></div></div>
              <div style={{ float: "left", width: (window.innerWidth - 170 - 88) / 5 }}><div><div style={{ fontWeight: "bold", width: "100%", textAlign: "center", border: "1px solid #DDD", whiteSpace: "nowrap" }}>契約</div></div></div>
              <div style={{ float: "left", width: (window.innerWidth - 170 - 88) / 5 }}><div><div style={{ fontWeight: "bold", width: "100%", textAlign: "center", border: "1px solid #DDD", whiteSpace: "nowrap" }}>予定</div></div></div>
              <div style={{ float: "left", width: (window.innerWidth - 170 - 88) / 5 }}><div><div style={{ fontWeight: "bold", width: "100%", textAlign: "center", border: "1px solid #DDD", whiteSpace: "nowrap" }}>営業</div></div></div>
              <div style={{ float: "left", width: (window.innerWidth - 170 - 88) / 5 }}><div><div style={{ fontWeight: "bold", width: "100%", textAlign: "center", border: "1px solid #DDD", whiteSpace: "nowrap" }}>その他</div></div></div>
            </div>

            
            {/* <div>
              {[0,1,2].map(function (value) {
                return <Scrollbars style={{float: "left", border:"1px solid rgb(221, 221, 221)", height:60, width: (window.innerWidth - 170 - 88) / this.props.users.filter(a=> String(a.am_section_id) == String(this.props.calendar_store_list_form.section_id > 0 ? this.props.calendar_store_list_form.section_id : this.props.user_details.amSectionId)).length}}>
                    {this.props.calendar_list.filter(a => a.type == 5 && a.user_id == value.id && moment(a.start).format("YYYY-MM-DD") == moment(this.props.calendar_store_list_form.start).format("YYYY-MM-DD")).map(function (value2) {
                      return <div style={{position:"relative", width:"100%", paddingLeft:2, paddingTop:2, paddingRight:2}}>
                          <div style={{borderBottom:"1px solid rgb(221, 221, 221)", padding:0, backgroundColor:value2.color}} onClick={(e) => {this.handleOpen(value2.id, this.props.calendar_store_list_form.section_id, value.id, this.props.calendar_store_list_form.start, this.props.calendar_store_list_form.end)}}>{value2.remarks}{value2.user_id > 0 ? "("+this.props.users.filter(a => a.id == value2.user_id)[0].nickname+")" : ""}</div>
                        </div>
                    },this)}
                    <div style={{position:"relative", width:"100%"}}>
                      <div style={{borderBottom:"1px solid rgb(221, 221, 221)", padding:5}}>　</div>
                      <IconButton style={{position:"absolute", top:0, right:3, padding:5}} onClick={(e) => {this.handleOpen("", this.props.calendar_store_list_form.section_id, value.id, this.props.calendar_store_list_form.start, this.props.calendar_store_list_form.end)}}><AddCircleOutlineIcon /></IconButton>
                    </div>
              </Scrollbars>
            },this)}
            </div> */}
            <Scrollbars className="store_calendar" style={{ width: window.innerWidth - 140, height: window.innerHeight - 131  }}>
              <div style={{ float: "left", width: (window.innerWidth - 170 - 88) / 5 }}><div><StoreCalendar type={1} set={this.set} /></div></div>
              <div style={{ float: "left", width: (window.innerWidth - 170 - 88) / 5 }}><div><StoreCalendar type={2} set={this.set} /></div></div>
              <div style={{ float: "left", width: (window.innerWidth - 170 - 88) / 5 }}><div><StoreCalendar type={3} set={this.set} /></div></div>
              <div style={{ float: "left", width: (window.innerWidth - 170 - 88) / 5 }}><div><StoreCalendar type={6} set={this.set} /></div></div>
              <div style={{ float: "left", width: (window.innerWidth - 170 - 88) / 5 }}>
                <div>

                  {this.props.users ? <Scrollbars style={{float: "left", border:"1px solid rgb(221, 221, 221)", height:window.innerHeight - 131, width:"100%"}}>
                    {this.props.calendar_list.filter(a => a.type == 5 && a.section_id == this.props.calendar_store_list_form.section_id && moment(a.start).format("YYYY-MM-DD") == moment(this.props.calendar_store_list_form.start).format("YYYY-MM-DD")).map(function (value2) {
                      return <div style={{position:"relative", width:"100%", paddingLeft:2, paddingTop:2, paddingRight:2}}>
                          <div style={{borderBottom:"1px solid rgb(221, 221, 221)", height:40, padding:0, backgroundColor: value2.color}} onClick={(e) => {this.handleOpen(value2.id, this.props.calendar_store_list_form.section_id, '', this.props.calendar_store_list_form.start, this.props.calendar_store_list_form.end)}}>
                            {value2.completed_at ? <CheckCircleIcon style={{color:"#666666"}} />:""}
                            {value2.remarks}{value2.user_id > 0 ? "("+this.props.users.filter(a => a.id == value2.user_id)[0].nickname+")" : ""}
                          </div>
                        </div>
                    },this)}
                    <div style={{position:"relative", width:"100%"}}>
                      <div style={{borderBottom:"1px solid rgb(221, 221, 221)", height:40, padding:5}}>　</div>
                      <IconButton
                        style={{position:"absolute", top:0, right:3, padding:5}}
                        onClick={(e) => {this.handleOpen("", this.props.calendar_store_list_form.section_id, '', this.props.calendar_store_list_form.start, this.props.calendar_store_list_form.end)}}
                        size="large"><AddCircleOutlineIcon /></IconButton>
                    </div>
                  </Scrollbars>:""}
                </div>
              </div>
            </Scrollbars>
            
            {/* {console.log("aaaaaaaaaaaaaaaaaaaaa")}
            {console.log(this.props.calendar_all_store_list.filter(a=>a.section_id == 1 || a.type == 0))} */}
          </CardContent >
        </Card >
        <TaskDialog

          open={this.state.open}
          onClose={this.handleClose}
          event_type={this.props.calendar_event_type}
          change_eigyo_edit_modal={this.props.change_eigyo_edit_modal}
          eigyo_edit_modal={this.props.eigyo_edit_modal}
          change_task_edit_modal={this.props.change_task_edit_modal}
          task_edit_modal={this.props.task_edit_modal}
          change_contract_follow_edit_modal={this.props.change_contract_follow_edit_modal}
          contract_follow_edit_modal={this.props.contract_follow_edit_modal}
          change_customer_contacts_modal={this.props.change_customer_contacts_modal}
          customer_contacts_modal={this.props.customer_contacts_modal} />

        <Modal isOpen={this.props.calendar_free_dialog} fade={false} >
          <FreeDialog />
        </Modal>



        {/* {this.props.customer_contacts_modal ? "open" : "close"} */}
      </div>
    );
  }
};




const mapStateToProps = (state) => {
  return {
    // users: state.masterReducer.users,
    user_details: state.masterReducer.user_details,
    all_store: state.calendar.all_store,
    calendar_all_store_list: state.calendar.calendar_all_store_list,
    // users_select: state.masterReducer.users_select,
    calendar_event_type: state.calendar.calendar_event_type,
    eigyo_edit_modal: state.calendar.eigyo_edit_modal,
    eigyou_detail: state.calendar.eigyou_detail,
    task_edit_modal: state.calendar.task_edit_modal,
    contract_follow_edit_modal: state.contractfollow.contract_follow_edit_modal,
    customer_contacts_modal: state.customerContactsReducer.customer_contacts_modal,
    calendar_precalendar: state.calendar.calendar_precalendar,
    calendar_list: state.calendar.calendar_list,
    calendar_store_list_form: state.calendar.calendar_store_list_form,
    calendar_free_dialog: state.calendar.calendar_free_dialog,
    users: state.calendar.users,
    
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_calendar_list(state) {
      dispatch(Calendarsaga.get_calendar_list(state))
    },
    change_task_edit_modal(state) {
      dispatch(Calendarsaga.change_task_edit_modal(state))
    },
    change_contract_follow_edit_modal(state) {
      dispatch(ContractFollow.change_contract_follow_edit_modal(state))
    },
    change_customer_contacts_modal(state) {
      dispatch(CustomerContacts.change_customer_contacts_modal(state))
    },
    change_calendar_list(state) {
      dispatch(Calendarsaga.change_calendar_list(state))
    },
    get_eigyou_rent_m_corporate_all_list(state) {
      dispatch(Calendarsaga.get_eigyou_rent_m_corporate_all_list(state))
    },
    get_eigyou_owner_room(state) {
      dispatch(Calendarsaga.get_eigyou_owner_room(state))
    },
    change_eigyo_edit_modal(state) {
      dispatch(Calendarsaga.change_eigyo_edit_modal(state))
    },
    change_eigyou_detail(state) {
      dispatch(Calendarsaga.change_eigyou_detail(state))
    },
    change_rent_task_detail(state) {
      dispatch(Calendarsaga.change_rent_task_detail(state))
    },
    change_calendar_precalendar(state) {
      dispatch(Calendarsaga.change_calendar_precalendar(state))
    },
    change_calendar_free_dialog(state) {
      dispatch(Calendarsaga.change_calendar_free_dialog(state))
    },
    set_calendar_free_detail(state) {
      dispatch(Calendarsaga.set_calendar_free_detail(state))
    },
    change_calendar_free_detail(state) {
      dispatch(Calendarsaga.change_calendar_free_detail(state))
    },
    get_users(state) {
      dispatch(Calendarsaga.get_users(state))
    },
    // get_calendar_list(state) {
    //   dispatch(Calendarsaga.get_calendar_list(state))
    // },
    // change_calendar_list_form(state) {
    //   dispatch(Calendarsaga.change_calendar_list_form(state))
    // },
    // change_calendar_list(state) {
    //   dispatch(Calendarsaga.change_calendar_list(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);