import React, { Component } from 'react';
// import Select from 'react-select';
import { connect } from 'react-redux';
import * as Responses from './Saga/Responses';
import * as Customers from '../Customers/Saga/Customers';

import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";
import Alert from '@mui/material/Alert';
import ChomeiEdit from '../Search/ChomeiEditComponent';
import ShougakkouEdit from '../Search/ShougakkouEditComponent';
import ChuugakkouEdit from '../Search/ChuugakkouEditComponent';

import MaterialUiSelect from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { reduxForm } from 'redux-form';
// import '../../Css/Table.css';
import moment from 'moment'
import { Grid } from '@mui/material';

moment.locale('ja')

// redux-form 用バリデーション関数
const validate = (values) => {

    // 検証に失敗したら values と同じキーで
    // エラーメッセージをセットする。
    const errors = {};

    if (!values.date) {
        errors.date = "反響日を入力してください。";
    }

    if (!(values.rentMMediumId > 0)) {
        errors.rentMMediumId = "メインメディアを入力してください。";
    }
    if (!(values.userId > 0)) {
        errors.userId = "反響担当を入力してください。";
    }


    return errors;
};

// フォームの入力用コンポーネント。
// input と meta は redux-form が渡してくる props。
// type や label は Field に渡した props がそのまま渡ってくる。
//const renderField = ({ input, type, label, id, meta: { touched, error } }) => (
//    <div >

//        {type === 'textarea' ?
//            <textarea className="form-control" {...input} type={type} id={id} />
//            : type === 'select' ?
//                <select className="form-control" type={type} id={id}>{this.props.children}</select>
//                :
//                <input {...input} type={type} id={id} className="form-control" />
//        }




//        {touched && error && <span className="text-danger">{error}</span>}
//    </div>
//);

export class RenderField extends React.Component {
    render() {
        const { className, type, input, disabled, meta: { touched, error } } = this.props;
        return (
            <div>
                {type === 'textarea' ?
                    <textarea className={className} {...input} type={type} disabled={disabled} />
                    : type === 'select' ?
                        <select className={className} {...input} type={type} disabled={disabled}>{this.props.children}</select>
                        :
                        <input className={className} {...input} type={type} disabled={disabled} />
                }
                {touched && error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
};








export class CheckboxGroup extends React.Component {

    checkboxGroup() {
        let { label, required, options, input, meta } = this.props;

        return options.map((option, index) => {
            console.log(input.value)

            return (
                <div className="checkbox" key={index}>
                    <label>
                        <input type="checkbox"
                            name={`${input.name}[${index + 1}]`}
                            value={index}
                            checked={input.value.indexOf((index + 1)) !== -1}
                            onChange={(event) => {

                                const newValue = [...input.value];
                                if (event.target.checked) {
                                    newValue.push(index + 1);
                                } else {
                                    newValue.splice(newValue.indexOf(index + 1), 1);
                                }

                                return input.onChange(newValue);
                            }} />
                        {option.name}
                    </label>
                </div>)
        });
    }

    render() {
        return (
            <div>
                {this.checkboxGroup()}
            </div>
        )
    }
}









export class CheckboxGroupTypes extends React.Component {

    checkboxGroup() {
        let { label, required, options, input, meta } = this.props;

        return options.map((option, index) => {
            console.log(input.value)

            return (
                <div className="checkbox" key={index}>
                    <label>
                        <input type="checkbox"
                            name={`${input.name}[${option.id}]`}
                            value={index}
                            checked={input.value.indexOf((option.id)) !== -1}
                            onChange={(event) => {

                                let newValue = [...input.value];
                                if (event.target.checked) {

                                    newValue.push(option.id);
                                } else {
                                    newValue = newValue.filter(a => a !== option.id)
                                    //    newValue.splice(newValue.indexOf(option.id), 1);
                                }

                                return input.onChange(newValue);
                            }} />
                        {option.name}
                    </label>
                </div>)
        });
    }

    render() {
        return (
            <div>
                {this.checkboxGroup()}
            </div>
        )
    }
}




const ReduxFormDateRange = (props) => {


    console.log(props)
    return (

        <DatePicker

            selected={props.input.value !== "" ? new Date(props.input.value) : ""}
            //selected={moment(props.input.value, "YYYY-MM-DD").format("YYYY-MM-DD") || null}
            onChange={props.input.onChange}
            // onChange={(event) => props.handleSubmit(event)}
            //showTimeSelect
            dateFormat="yyyy/MM/dd"
            className="form-control"
        />
    )
}





// const RenderSelectInput = ({ input, options, name, id }) => (

//     <Select
//         {...input}
//         id={id}
//         name={name}
//         options={options}
//         value={input.value}
//         onChange={(a) => input.onChange(a ? a.value : 0)}
//         onBlur={(a) => input.onBlur(a ? a.value : 0)}
//     // placeholder="店舗を選択"
//     />


// )


class ResponseInfoEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            setRentMWayId: this.props.responsedetail != null ? String(this.props.responsedetail.rent_m_way_id) : "0",
            setRentMMediumId: this.props.responsedetail != null ? String(this.props.responsedetail.rent_m_medium_id) : "0",
            setStudentIndependent: this.props.responsedetail != null ? String(this.props.responsedetail.student_independent) : "0",
            setAloneFamily: this.props.responsedetail != null ? String(this.props.responsedetail.alone_family) : "0",
            chomeiEdit: this.props.responsedetail && this.props.responsedetail.request_chomei ? (this.props.responsedetail.request_chomei.split(',').length > 0 ? true : false) : false,
            shougakkouEdit: this.props.responsedetail && this.props.responsedetail.request_shougakkou ? (this.props.responsedetail.request_shougakkou.split(',').length > 0 ? true : false) : false,
            chuugakkouEdit: this.props.responsedetail && this.props.responsedetail.request_chuugakkou ? (this.props.responsedetail.request_chuugakkou.split(',').length > 0 ? true : false) : false,




            gasu: this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(",").some(function (e) { return e == 24 || e == 23 || e == 22 || e == 95 }) : false,
            gasu_id: this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(",").find(e => e == 24 || e == 23 || e == 22 || e == 95) : false,

            ih: this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(",").some(function (e) { return e == 29 || e == 30 || e == 59 || e == 92 }) : false,
            ih_id: this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(",").find(e => e == 29 || e == 30 || e == 59 || e == 92) : false,

            eakon: this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(",").some(function (e) { return e == 58 || e == 7 || e == 8 || e == 119 || e == 151 || e == 121 || e == 115 }) : false,
            eakon_id: this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(",").find(e => e == 58 || e == 7 || e == 8 || e == 119 || e == 151 || e == 121 || e == 115) : false,


            // // gasu: this.props.responsedetail.gasu,
            // denki: this.props.responsedetail.denki,
            // ih: this.props.responsedetail.ih,
            // gasuka: this.props.responsedetail.gasuka,
            // denkika: this.props.responsedetail.denkika,
            // ihka: this.props.responsedetail.ihka,
            // eakon: this.props.responsedetail.eakon,

        };


        // this.handleDate = this.handleDate.bind(this);
        // this.handlerequestMoveDate = this.handlerequestMoveDate.bind(this);
        this.change_date = this.change_date.bind(this);
        this.change_gakusei_yoyaku_date = this.change_gakusei_yoyaku_date.bind(this);
        this.handle_agent_change = this.handle_agent_change.bind(this);

        this.handleUserIdChangeSet = this.handleUserIdChangeSet.bind(this);

        this.handlePrimaryUserIdChangeSet = this.handlePrimaryUserIdChangeSet.bind(this);


        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);

    }




    componentWillMount() {

    }

    componentDidMount() {
        console.log(this.props)

        console.log(this.props.responsedetail)

        this.props.responsedetail.section_id = !this.props.responsedetail.section_id ? this.props.section_id : this.props.responsedetail.section_id;
        this.props.responsedetail.user_id = !this.props.responsedetail.user_id ? this.props.user_id : this.props.responsedetail.user_id;
        console.log(this.props.responsedetail.user_id)




        this.props.initialize(this.props.responsedetail);//フォームに初期値を反映させる
        console.log(this.props.responsedetail)


    }




    componentDidUpdate() {


    }

    customerUpdate() {

    }

    onChange(property, event) {
        console.log(property);
        console.log(event.target);

        this.setState({ [property]: event.target.value });

        let responsedetail = this.props.responsedetail
        responsedetail[event.target.name] = event.target.value

        // this.props.change_responsedetail(responsedetail);
        this.setState({ reflash: !this.state.reflash });
    }


    change_date(value) {

        let rent_responses_editor = this.props.rent_responses_editor
        rent_responses_editor.date = value
        this.props.change_rent_responses_editor(rent_responses_editor)
        // this.props.change_responsedetail(responsedetail);
        this.setState({ reflash: !this.state.reflash });
    }

    change_gakusei_yoyaku_date(value) {

        let rent_responses_editor = this.props.rent_responses_editor
        rent_responses_editor.gakusei_yoyaku_date = value
        this.props.change_rent_responses_editor(rent_responses_editor)
        // this.props.change_responsedetail(responsedetail);
        this.setState({ reflash: !this.state.reflash });
    }

    handleChange = (event) => {
        // alert(event.target.value);
        // alert(event.target.name);

        // this.setState({ [property]: event.target.value });

        let rent_responses_editor = this.props.rent_responses_editor
        if (event.target.name == "areas" || event.target.name == "request_tatemono_types" || event.target.name == "jyukenyotei") {

            if (rent_responses_editor[event.target.name] && rent_responses_editor[event.target.name].split(",").includes(event.target.value)) {

                //削除
                rent_responses_editor[event.target.name] = rent_responses_editor[event.target.name].split(",").filter(item => parseInt(item) != parseInt(event.target.value)).join(',')
            } else {

                let ary = rent_responses_editor[event.target.name] ? rent_responses_editor[event.target.name].split(",") : []

                ary.push(parseInt(event.target.value))

                ary = ary.filter(Boolean)

                rent_responses_editor[event.target.name] = ary.join(',')
            }
        } else if (event.target.name === "gakusei_yoyaku") {
            rent_responses_editor[event.target.name] = event.target.checked ? 1 : 0
        } else if (event.target.name === "response_requests" && (event.target.value == "24" || event.target.value == "23" || event.target.value == "22" || event.target.value == "95")) {//ガスコンロ

            let ary = rent_responses_editor[event.target.name] ? rent_responses_editor[event.target.name].split(",").filter(item => parseInt(item) != 24 && parseInt(item) != 23 && parseInt(item) != 22 && parseInt(item) != 95) : [];
            ary.push(parseInt(event.target.value))
            ary = ary.filter(Boolean)
            rent_responses_editor[event.target.name] = ary.join(',')

        } else if (event.target.name === "response_requests" && (event.target.value == "29" || event.target.value == "30" || event.target.value == "59" || event.target.value == "92")) {//IH

            let ary = rent_responses_editor[event.target.name] ? rent_responses_editor[event.target.name].split(",").filter(item => parseInt(item) != 29 && parseInt(item) != 30 && parseInt(item) != 59 && parseInt(item) != 92) : [];
            ary.push(parseInt(event.target.value))
            ary = ary.filter(Boolean)
            rent_responses_editor[event.target.name] = ary.join(',')

        } else if (event.target.name === "response_requests" && (event.target.value == "58" || event.target.value == "7" || event.target.value == "8" || event.target.value == "119" || event.target.value == "151" || event.target.value == "121" || event.target.value == "115")) {//エアコン

            let ary = rent_responses_editor[event.target.name] ? rent_responses_editor[event.target.name].split(",").filter(item => parseInt(item) != 58 && parseInt(item) != 7 && parseInt(item) != 8 && parseInt(item) != 119 && parseInt(item) != 151 && parseInt(item) != 121 && parseInt(item) != 115) : [];
            ary.push(parseInt(event.target.value))
            ary = ary.filter(Boolean)
            rent_responses_editor[event.target.name] = ary.join(',')
        } else if (event.target.name === "response_requests") {
            if (rent_responses_editor[event.target.name] && rent_responses_editor[event.target.name].split(",").includes(event.target.value)) {

                //削除
                rent_responses_editor[event.target.name] = rent_responses_editor[event.target.name].split(",").filter(item => parseInt(item) != parseInt(event.target.value)).join(',')

            } else {
                let ary = rent_responses_editor[event.target.name] ? rent_responses_editor[event.target.name].split(",") : [];
                ary.push(parseInt(event.target.value))
                ary = ary.filter(Boolean)
                rent_responses_editor[event.target.name] = ary.join(',')
            }

        } else if(event.target.name === "rent_m_way_id" && this.props.customerNewDisp == 0){
            rent_responses_editor[event.target.name] = event.target.value
            rent_responses_editor.coming_user_id = 0

        } else if(event.target.name === "student_independent"){
            rent_responses_editor[event.target.name] = event.target.value
            rent_responses_editor.gakusei_yoyaku = 0
            rent_responses_editor.rent_m_school_id = 0
            rent_responses_editor.highschool = ""
            rent_responses_editor.gakunen = 0
            rent_responses_editor.jyukenyotei = 0
            rent_responses_editor.gouhi = 0
            rent_responses_editor.reserveroom = ""
            rent_responses_editor.alone_family = 0
        } else {

            rent_responses_editor[event.target.name] = event.target.value
        }

        this.props.change_rent_responses_editor(rent_responses_editor);



        this.setState({ reflash: !this.state.reflash, });
    }

    handle_agent_change(e, value) {
        console.log(value)
        let rent_responses_editor = this.props.rent_responses_editor


        if (value) {

            rent_responses_editor.rent_m_agent_id = value.value
            if (value.value === 5) {
                rent_responses_editor.agent_etc = ''
            }
        } else {

            rent_responses_editor.rent_m_agent_id = 0
            rent_responses_editor.agent_etc = ''
        }
        this.props.change_rent_responses_editor(rent_responses_editor);
        this.setState({ reflash: !this.state.reflash, });
    }

    handleRequestsChange(event) {

        let rent_responses_editor = this.props.rent_responses_editor

        if (event.target.name == "response_requests_gasu") {
            //ガスコンロ
            rent_responses_editor["response_requests"] = rent_responses_editor["response_requests"] ? rent_responses_editor["response_requests"].split(",").filter(item => parseInt(item) != 24 && parseInt(item) != 23 && parseInt(item) != 22 && parseInt(item) != 95).join(',') : '';
        } else if (event.target.name == "response_requests_ih") {
            //IH
            rent_responses_editor["response_requests"] = rent_responses_editor["response_requests"] ? rent_responses_editor["response_requests"].split(",").filter(item => parseInt(item) != 29 && parseInt(item) != 30 && parseInt(item) != 59 && parseInt(item) != 92).join(',') : '';
        } else if (event.target.name == "response_requests_eakon") {
            //エアコン
            rent_responses_editor["response_requests"] = rent_responses_editor["response_requests"] ? rent_responses_editor["response_requests"].split(",").filter(item => parseInt(item) != 58 && parseInt(item) != 7 && parseInt(item) != 8 && parseInt(item) != 119 && parseInt(item) != 151 && parseInt(item) != 121 && parseInt(item) != 115).join(',') : '';
        }
        // console.log(rent_responses_editor["response_requests"])
        // alert()
        this.props.change_rent_responses_editor(rent_responses_editor);
        this.setState({ reflash: !this.state.reflash });
    }

    handleUserIdChangeSet(e, value) {

        let rent_responses_editor = this.props.rent_responses_editor
        if (value) {
            rent_responses_editor.user_id = value.value

        } else {
            rent_responses_editor.user_id = 0
        }

        this.props.change_rent_responses_editor(rent_responses_editor);
        this.setState({ reflash: !this.state.reflash });
        // this.props.change_responsedetail(responsedetail);
        // this.setState({ reflash: !this.state.reflash });
    }
    handlePrimaryUserIdChangeSet(e, value) {


        let rent_responses_editor = this.props.rent_responses_editor
        if (value) {
            rent_responses_editor.primary_user_id = value.value

        } else {
            rent_responses_editor.primary_user_id = 0
        }

        this.props.change_rent_responses_editor(rent_responses_editor);
        this.setState({ reflash: !this.state.reflash });
        // this.props.change_responsedetail(responsedetail);
        // this.setState({ reflash: !this.state.reflash });
    }
    handleSelectChange = (event, value) => {
        // console.log(event.target)

        let rent_responses_editor = this.props.rent_responses_editor

        if (value && value.name) {
            rent_responses_editor[value.name] = value.value;


            this.props.change_rent_responses_editor(rent_responses_editor);
            this.setState({ reflash: !this.state.reflash });
        }
    }

    handleCancel(){
        this.props.change_response_attention_disp(false)

        let rent_customer_editor_forms = this.props.rent_customer_editor_forms
        rent_customer_editor_forms.attentions_check = 1
        this.props.change_rent_customer_editor_forms(rent_customer_editor_forms)

        this.setState({ reflash: !this.state.reflash });
    }



    render() {
        const requestAreaList = [{ id: 1, name: '高松市中心部' }, { id: 2, name: 'レインボー・サンフラワー周辺' }, { id: 3, name: '高松市南部' }, { id: 4, name: '高松市東部' }, { id: 5, name: '高松市西部' }, { id: 6, name: '香川町　三木町' }, { id: 7, name: '庵治町・牟礼町' }, { id: 8, name: '高松市全域' }]
        const { handleDate, handlerequestMoveDate, handleSetsubiSubmit, handleSubmit } = this.props;
        const requestTatemonoTypesList = [{ id: 1, name: 'アパート' }, { id: 2, name: 'マンション' }, { id: 7, name: '戸建て' }]
        return (
            <div>
                <form onSubmit={handleSubmit}  >


                    <Grid container>

                        
                        <Grid xs={11} style={{padding: "5px"}}>

                            <TextField
                                id="date"
                                label="反響日"
                                type="date"
                                name="date"
                                size="small"
                                variant='outlined'
                                onChange={this.handleChange}
                                error={!this.props.rent_responses_editor.date}
                                value={moment(this.props.rent_responses_editor.date).format("YYYY-MM-DD")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>

                                <KeyboardDatePicker
                                    disableToolbar
                                    inputVariant="outlined"
                                    format="yyyy/MM/dd"
                                    size="small"
                                    id="date-picker-inline1"
                                    label="反響日"
                                    //   showTodayButton={true}
                                    style={{ float: "left", marginTop:9 }}
                                    autoOk={true}
                                    error={!this.props.rent_responses_editor.date}
                                    InputLabelProps={{ shrink: true }}
                                    // error={true}
                                    // helperText="契約日を選択してください"
                                    //   errorMessages={['契約日を選択してください']}
                                    name="date"
                                    value={this.props.rent_responses_editor.date}
                                    // value={new Date()}
                                    // value={this.props.estimates.contractDate}
                                    onChange={this.change_date}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    //   minDateMessage="契約日を選択してください"
                                    // minDate={new Date("2000-01-01")}
                                    invalidDateMessage={"日付の形式が違います"}
                                />

                            </MuiPickersUtilsProvider> */}
                        </Grid>
                        <Grid xs={1} style={{padding: "5px"}}>

                            {!this.props.response_detail_dialog_disp ? <Tooltip title="閉じる" style={{ float: "right" }}>
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => this.props.responseeditchange(0)}
                                    style={{ display: (this.props.notclose === 1) ? 'none' : '' }}
                                    size="large">
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>:""}
                        </Grid>
                        <Grid item xs={12} style={{padding: "5px"}}>
                            <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 10 }}>反響経路</FormLabel>
                            <RadioGroup row aria-label="position" name="rent_m_way_id" onChange={this.handleChange} value={Number(this.props.rent_responses_editor.rent_m_way_id)} >
                                <FormControlLabel control={<Radio value={1} />} label="TEL" />
                                <FormControlLabel control={<Radio value={2} />} label="メール" />
                                <FormControlLabel control={<Radio value={6} />} label="LINE" />
                                <FormControlLabel control={<Radio value={7} />} label="SNS" />
                                <FormControlLabel control={<Radio value={3} />} label="社宅斡旋" />
                                <FormControlLabel control={<Radio value={5} />} label="飛込" />
                            </RadioGroup>
                        </Grid>
                        {Number(this.props.rent_responses_editor.rent_m_way_id) === 2 ? <Grid item xs={12} style={{padding: "5px"}}>
                            <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 10 }}>初回メール内容</FormLabel>
                            <RadioGroup row aria-label="position" name="mail_is_tel" onChange={this.handleChange} value={Number(this.props.rent_responses_editor.mail_is_tel)} >
                                <FormControlLabel control={<Radio value={0} />} label="TEL無" />
                                <FormControlLabel control={<Radio value={1} />} label="TELあり" />
                            </RadioGroup>
                        </Grid> : ""}
                        {String(this.props.rent_responses_editor.rent_m_way_id) === "3" ? <Grid item xs={12} style={{padding: "5px"}}>

                            {this.props.rent_m_agents ? <Autocomplete
                                id="combo-box-demo"
                                // options={this.props.users_select}
                                options={this.props.rent_m_agents.map((value) => ({
                                    value: value.id,
                                    label: value.name
                                }))}
                                getOptionLabel={(option) => option.label}
                                // defaultValue={this.props.masterReducer.users_select.filter(a => a.value === this.props.masterReducer.user_details.id)[0]}
                                // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                                style={{ width: "100%", padding:0 }}
                                name="rent_m_agent_id"
                                value={this.props.rent_responses_editor.rent_m_agent_id ? this.props.rent_m_agents.filter(a => a.id === this.props.rent_responses_editor.rent_m_agent_id).map((value) => ({
                                    value: value.id,
                                    label: value.name
                                }))[0] : {}}
                                onChange={this.handle_agent_change}

                                renderInput={(params) => <TextField
                                    variant="outlined"
                                    size="small"
                                    style={{backgroundColor:"#e8e8e8"}}
                                    error={!this.props.rent_responses_editor.rent_m_agent_id}
                                    {...params} label="代行会社" />}

                            /> : ""}


                            {/* <FormControl variant="outlined" fullWidth>
                                <InputLabel id="rent_m_agent_id-label">代行会社</InputLabel>
                                <MaterialUiSelect
                                    labelId="rent_m_agent_id-label"
                                    id="rent_m_agent_id"
                                    name="rent_m_agent_id"
                                    value={this.props.rent_responses_editor.rent_m_agent_id}
                                    onChange={this.handleChange}
                                    error={!this.props.rent_responses_editor.rent_m_agent_id}
                                    label="rent_m_agent_id"
                                >
                                    {this.props.rentAgents.map(function (item, i) {
                                        return (
                                            <MenuItem key={i} value={item.rentMAgentId}>{item.name1}</MenuItem>
                                        );

                                    })}
                                </MaterialUiSelect>
                            </FormControl> */}
                        </Grid> : ""}
                        
                        {String(this.props.rent_responses_editor.rent_m_way_id) === "3" && this.props.rent_responses_editor.rent_m_agent_id == 5 ? <Grid item xs={12} style={{padding: "5px"}}>

                            <TextField fullWidth
                                label="代行会社その他"
                                variant="outlined"
                                size="small"
                                onChange={this.handleChange}
                                name="agent_etc"
                                value={this.props.rent_responses_editor.agent_etc}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid> : ""}

                        <Grid item xs={12} style={{padding: "5px 5px 0px 5px"}}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="rent_m_medium_id-label">メディア</InputLabel>
                                <MaterialUiSelect
                                    labelId="rent_m_medium_id-label"
                                    id="rent_m_medium_id"
                                    value={this.props.rent_responses_editor.rent_m_medium_id}
                                    name="rent_m_medium_id"
                                    onChange={this.handleChange}
                                    error={!this.props.rent_responses_editor.rent_m_medium_id}
                                    // onChange={this.onChange.bind(this, "setRentMMediumId")}
                                    label="rent_m_medium_id"
                                    
                                >

                                    {this.props.rent_responses_editor.rent_m_way_id == 7 ? this.props.rent_m_medea.filter(a => a.web == 2 && (a.display == 0 || a.id == this.props.rent_responses_editor.rent_m_medium_id)).map(function (item, i) {
                                        return (
                                            <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                        );

                                    }) : this.props.rent_m_medea.filter(a => a.web !== 2 && (a.display == 0 || a.id == this.props.rent_responses_editor.rent_m_medium_id)).map(function (item, i) {
                                        return (
                                            <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                        );

                                    })}
                                </MaterialUiSelect>
                            </FormControl>
                        </Grid>
                        {/* {this.props.rent_responses_editor.rent_m_way_id === "5" ? <Grid justify="space-between" item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={this.props.rent_responses_editor.employed == 0 ? true : false} onChange={this.handleChange} name="employed" />}
                                label="案内あり"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.props.rent_responses_editor.employed == 0 ? true : false} onChange={this.handleChange} name="itEmployed" />}
                                label="IT案内"
                            />
                        </Grid> : ""} */}

                    </Grid>




                    <Grid container style={{marginTop:5}}>
                        
                        <Grid item xs={6} style={{padding: "5px"}}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="sectionId-label">店舗</InputLabel>
                                <MaterialUiSelect
                                    labelId="sectionId-label"
                                    id="section_id"
                                    name="section_id"
                                    value={this.props.rent_responses_editor.section_id}
                                    onChange={this.handleChange}
                                    disabled={this.props.rent_responses_editor.id}
                                    label="section_id"
                                    error={this.props.rent_responses_editor.section_id == -1}
                                    margin='dense'
                                    style={{}}
                                >
                                    {this.props.shops.map(function (item, i) {
                                        return (
                                            <MenuItem key={i} value={item.tenpoNo}>{item.tenpoName}</MenuItem>
                                        );

                                    })}
                                </MaterialUiSelect>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} style={{padding: "5px"}}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="first_contact_section_id-label">初回対応店舗</InputLabel>
                                <MaterialUiSelect
                                    labelId="first_contact_section_id-label"
                                    id="first_contact_section_id"
                                    name="first_contact_section_id"
                                    value={this.props.rent_responses_editor.first_contact_section_id}
                                    onChange={this.handleChange}
                                    disabled={this.props.user_details.amSectionId == 10 || this.props.user_details.amSectionId == 26 || this.props.user_details.id == 1063 || this.props.user_details.id == 1392 || this.props.user_details.id == 1015 ? false : this.props.rent_responses_editor.id}
                                    label="first_contact_section_id"
                                    error={this.props.rent_responses_editor.first_contact_section_id == -1}
                                    margin='dense'
                                    style={{}}
                                >
                                    {this.props.shops.map(function (item, i) {
                                        return (
                                            <MenuItem key={i} value={item.tenpoNo}>{item.tenpoName}</MenuItem>
                                        );

                                    })}
                                </MaterialUiSelect>
                            </FormControl>
                        </Grid>






                        <Grid xs={6} style={{padding: "5px"}}>
                            <FormControl >

                                {this.props.section_select.length > 0 && this.props.user_details ? <Autocomplete
                                    id="combo-box-demo"
                                    options={this.props.section_select.map((value) => ({
                                        value: value.value,
                                        label: value.label,
                                        name: 'select_section_id',
                                    }))}
                                    getOptionLabel={(option) => option.label}
                                    value={this.props.rent_responses_editor.select_section_id ? this.props.section_select.filter(a => a.value === this.props.rent_responses_editor.select_section_id)[0] : this.props.rent_responses_editor.user_id ? this.props.section_select.filter(a => a.value === this.props.users.filter(a => a.id === this.props.rent_responses_editor.user_id)[0].amSectionId)[0] : this.props.section_select.filter(a => a.value === this.props.rent_responses_editor.section_id)[0]}
                                    // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                                    style={{ width: 180 }}
                                    onChange={this.handleSelectChange}
                                    size="small"
                                    name="select_section_id"
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        style={{backgroundColor:"#e8e8e8"}}
                                        {...params} label="反響担当所属店舗を選択" />}

                                /> : ""}
                            </FormControl>

                        </Grid>


                        <Grid xs={6} style={{padding: "5px"}}>



                            <FormControl style={{ width: "160px" }}>
                                {this.props.users_select.length > 0 && this.props.user_details ? <Autocomplete
                                    id="combo-box-demo"
                                    options={this.props.rent_responses_editor.select_section_id ? this.props.users.filter(a => a.amSectionId === this.props.rent_responses_editor.select_section_id).map((value) => ({
                                        value: value.id,
                                        label: value.userNameAll,
                                        name: 'user_id',
                                        section_id: value.amSectionId,
                                    })) : this.props.rent_responses_editor.user_id ? this.props.users.filter(a => a.amSectionId === this.props.users.filter(a => a.id === this.props.rent_responses_editor.user_id)[0].amSectionId).map((value) => ({
                                        value: value.id,
                                        label: value.userNameAll,
                                        name: 'user_id',
                                        section_id: value.amSectionId,
                                    })) : this.props.users.map((value) => ({
                                        value: value.id,
                                        label: value.userNameAll,
                                        name: 'user_id',
                                        section_id: value.amSectionId,
                                    }))}
                                    getOptionLabel={(option) => option.label}
                                    value={this.props.rent_responses_editor.user_id ? this.props.users.map((value) => ({
                                        value: value.id,
                                        label: value.userNameAll,
                                        name: 'user_id',
                                        section_id: value.amSectionId,
                                    })).filter(a => a.value === this.props.rent_responses_editor.user_id)[0] : {}}


                                    // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                                    style={{ width: 150, padding:0 }}
                                    name="user_id"
                                    // value={this.props.rent_contract_editor_froms.user_id}
                                    // value={this.props.contract_follow_list_form.operation_user_id ? this.props.users_select.filter(a => a.value === this.props.contract_follow_list_form.operation_user_id)[0] : null}
                                    onChange={this.handleSelectChange}
                                    size="small"
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        error={!this.props.rent_responses_editor.user_id}
                                        name="user_id"
                                        {...params} label="反響担当を選択" />}

                                /> : ""}
                            </FormControl>
                        </Grid>



                        <Grid xs={6} style={{padding: "5px", display:"none"}}>
                            <FormControl >

                                {this.props.section_select.length > 0 && this.props.user_details ? <Autocomplete
                                    id="combo-box-demo"
                                    options={this.props.section_select.map((value) => ({
                                        value: value.value,
                                        label: value.label,
                                        name: 'select_primary_section_id',
                                    }))}
                                    getOptionLabel={(option) => option.label}
                                    name="select_primary_section_id"
                                    value={this.props.rent_responses_editor.select_primary_section_id ? this.props.section_select.filter(a => a.value === this.props.rent_responses_editor.select_primary_section_id)[0] : this.props.rent_responses_editor.primary_user_id ? this.props.section_select.filter(a => a.value === this.props.users.filter(a => a.id === this.props.rent_responses_editor.primary_user_id)[0].amSectionId)[0] : this.props.section_select.filter(a => a.value === this.props.rent_responses_editor.select_primary_section_id)[0]}

                                    // value={this.props.rent_responses_editor.select_primary_section_id ? this.props.section_select.filter(a => a.value === this.props.users.filter(a => a.id === this.props.rent_responses_editor.select_primary_id)[0].amSectionId)[0] : {}}
                                    // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                                    style={{ width: 180 }}
                                    onChange={this.handleSelectChange}
                                    size="small"
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        style={{backgroundColor:"#e8e8e8"}}
                                        {...params} label="追客担当所属店舗を選択" />}

                                /> : ""}
                            </FormControl>

                        </Grid>

                        <Grid xs={6} style={{padding: "5px", display:"none"}}>
                            <FormControl style={{ width: "160px" }}>
                                {this.props.users_select.length > 0 && this.props.user_details ? <Autocomplete
                                    id="combo-box-demo"
                                    options={this.props.rent_responses_editor.select_primary_section_id ? this.props.users.filter(a => a.amSectionId === this.props.rent_responses_editor.select_primary_section_id).map((value) => ({
                                        value: value.id,
                                        label: value.userNameAll,
                                        name: 'primary_user_id',
                                        section_id: value.amSectionId,
                                    })) : this.props.rent_responses_editor.primary_user_id ? this.props.users.filter(a => a.amSectionId === this.props.users.filter(a => a.id === this.props.rent_responses_editor.primary_user_id)[0].amSectionId).map((value) => ({
                                        value: value.id,
                                        label: value.userNameAll,
                                        name: 'primary_user_id',
                                        section_id: value.amSectionId,
                                    })) :

                                        this.props.users.map((value) => ({
                                            value: value.id,
                                            label: value.userNameAll,
                                            name: 'primary_user_id',
                                            section_id: value.amSectionId,
                                        }))}
                                    getOptionLabel={(option) => option.label}
                                    // defaultValue={this.props.users_select.filter(a => a.value === this.props.user_details.id)[0]}
                                    // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                                    style={{ width: 150, padding:0 }}
                                    name="primary_user_id"
                                    value={this.props.rent_responses_editor.primary_user_id ? this.props.users.map((value) => ({
                                        value: value.id,
                                        label: value.userNameAll,
                                        name: 'primary_user_id',
                                        section_id: value.amSectionId,
                                    })).filter(a => a.value === this.props.rent_responses_editor.primary_user_id)[0] : {}}
                                    // value={this.props.rent_contract_editor_froms.operation_user_id}
                                    // value={this.props.contract_follow_list_form.operation_user_id ? this.props.users_select.filter(a => a.value === this.props.contract_follow_list_form.operation_user_id)[0] : null}
                                    onChange={this.handleSelectChange}
                                    size="small"
                                    renderInput={(params) => <TextField
                                        error={!this.props.rent_responses_editor.primary_user_id}
                                        variant="outlined"
                                        name="primary_user_id"
                                        {...params} label="追客担当" />}

                                /> : ""}
                            </FormControl>

                        </Grid>

                        {(this.props.customerNewDisp == 0 && Number(this.props.rent_responses_editor.rent_m_way_id) === 5) || this.props.customerNewDisp == 1 ? <Grid xs={6} style={{ padding: "5px" }}>
                            <FormControl >

                                {this.props.section_select.length > 0 && this.props.user_details ? <Autocomplete
                                    disabled = {this.props.customerNewDisp == 1}
                                    id="combo-box-demo"
                                    options={this.props.section_select.map((value) => ({
                                        value: value.value,
                                        label: value.label,
                                        name: 'select_coming_section_id',
                                    }))}
                                    getOptionLabel={(option) => option.label}
                                    name="select_coming_section_id"
                                    value={this.props.rent_responses_editor.select_coming_section_id ? this.props.section_select.filter(a => a.value === this.props.rent_responses_editor.select_coming_section_id)[0] : this.props.rent_responses_editor.coming_user_id ? this.props.section_select.filter(a => a.value === this.props.users.filter(a => a.id === this.props.rent_responses_editor.coming_user_id)[0].amSectionId)[0] : this.props.section_select.filter(a => a.value === this.props.rent_responses_editor.select_coming_section_id)[0]}

                                    style={{ width: 180 }}
                                    onChange={this.handleSelectChange}
                                    size="small"
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        style={{backgroundColor:"#e8e8e8"}}
                                        {...params} label="来店担当所属店舗を選択" />}

                                /> : ""}
                            </FormControl>

                        </Grid>:""}

                        {(this.props.customerNewDisp == 0 && Number(this.props.rent_responses_editor.rent_m_way_id) === 5) || this.props.customerNewDisp == 1 ? <Grid xs={6} style={{ padding: "5px" }}>
                            <FormControl style={{ width: "160px" }}>
                                {this.props.users_select.length > 0 && this.props.user_details ? <Autocomplete
                                    disabled = {this.props.customerNewDisp == 1}
                                    id="combo-box-demo"
                                    options={this.props.rent_responses_editor.select_coming_section_id ? this.props.users.filter(a => a.amSectionId === this.props.rent_responses_editor.select_coming_section_id).map((value) => ({
                                        value: value.id,
                                        label: value.userNameAll,
                                        name: 'coming_user_id',
                                        section_id: value.amSectionId,
                                    })) : this.props.rent_responses_editor.coming_user_id ? this.props.users.filter(a => a.amSectionId === this.props.users.filter(a => a.id === this.props.rent_responses_editor.coming_user_id)[0].amSectionId).map((value) => ({
                                        value: value.id,
                                        label: value.userNameAll,
                                        name: 'coming_user_id',
                                        section_id: value.amSectionId,
                                    })) :

                                        this.props.users.map((value) => ({
                                            value: value.id,
                                            label: value.userNameAll,
                                            name: 'coming_user_id',
                                            section_id: value.amSectionId,
                                        }))}
                                    getOptionLabel={(option) => option.label}
                                    // defaultValue={this.props.users_select.filter(a => a.value === this.props.user_details.id)[0]}
                                    // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                                    style={{ width: 150, padding: 0 }}
                                    name="coming_user_id"
                                    value={this.props.rent_responses_editor.coming_user_id ? this.props.users.map((value) => ({
                                        value: value.id,
                                        label: value.userNameAll,
                                        name: 'coming_user_id',
                                        section_id: value.amSectionId,
                                    })).filter(a => a.value === this.props.rent_responses_editor.coming_user_id)[0] : {}}
                                    // value={this.props.rent_contract_editor_froms.operation_user_id}
                                    // value={this.props.contract_follow_list_form.operation_user_id ? this.props.users_select.filter(a => a.value === this.props.contract_follow_list_form.operation_user_id)[0] : null}
                                    onChange={this.handleSelectChange}
                                    size="small"
                                    renderInput={(params) => <TextField
                                        // error={!this.props.rent_responses_editor.coming_user_id}
                                        variant="outlined"
                                        name="coming_user_id"
                                        {...params} label="来店担当" />}

                                /> : ""}
                            </FormControl>

                        </Grid> : ""}


                        <Grid xs={6} style={{ padding:"5px", display:"none" }}>
                            <FormControl >

                                {this.props.section_select.length > 0 && this.props.user_details ? <Autocomplete
                                    id="combo-box-demo"
                                    options={this.props.section_select.map((value) => ({
                                        value: value.value,
                                        label: value.label,
                                        name: 'select_web_section_id',
                                    }))}
                                    getOptionLabel={(option) => option.label}
                                    name="select_web_section_id"
                                    value={this.props.rent_responses_editor.select_web_section_id ? this.props.section_select.filter(a => a.value === this.props.rent_responses_editor.select_web_section_id)[0] : this.props.rent_responses_editor.web_user_id ? this.props.section_select.filter(a => a.value === this.props.users.filter(a => a.id === this.props.rent_responses_editor.web_user_id)[0].amSectionId)[0] : this.props.section_select.filter(a => a.value === this.props.rent_responses_editor.select_web_section_id)[0]}
                                    style={{ width: 180 }}
                                    onChange={this.handleSelectChange}
                                    size="small"
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        style={{backgroundColor:"#e8e8e8"}}
                                        {...params} label="Web担当所属店舗を選択" />}

                                /> : ""}
                            </FormControl>

                        </Grid>

                        {this.props.user_details.amSectionId == 10 || this.props.user_details.amSectionId == 26 || this.props.user_details.amSectionId == 37 || this.props.user_details.amSectionId == 55 ?
                            <Grid xs={6} style={{ padding: "5px", display:"none" }}>
                                <FormControl style={{ width: "160px" }}>
                                    {this.props.users_select.length > 0 && this.props.user_details ? <Autocomplete
                                        id="combo-box-demo"
                                        options={this.props.rent_responses_editor.select_web_section_id ? this.props.users.filter(a => a.amSectionId === this.props.rent_responses_editor.select_web_section_id).map((value) => ({
                                            value: value.id,
                                            label: value.userNameAll,
                                            name: 'web_user_id',
                                            section_id: value.amSectionId,
                                        })) : this.props.rent_responses_editor.web_user_id ? this.props.users.filter(a => a.amSectionId === this.props.users.filter(a => a.id === this.props.rent_responses_editor.web_user_id)[0].amSectionId).map((value) => ({
                                            value: value.id,
                                            label: value.userNameAll,
                                            name: 'web_user_id',
                                            section_id: value.amSectionId,
                                        })) :

                                            this.props.users.map((value) => ({
                                                value: value.id,
                                                label: value.userNameAll,
                                                name: 'web_user_id',
                                                section_id: value.amSectionId,
                                            }))}
                                        getOptionLabel={(option) => option.label}
                                        // defaultValue={this.props.users_select.filter(a => a.value === this.props.user_details.id)[0]}
                                        // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                                        style={{ width: 150, padding: 0 }}
                                        name="web_user_id"
                                        value={this.props.rent_responses_editor.web_user_id ? this.props.users.map((value) => ({
                                            value: value.id,
                                            label: value.userNameAll,
                                            name: 'web_user_id',
                                            section_id: value.amSectionId,
                                        })).filter(a => a.value === this.props.rent_responses_editor.web_user_id)[0] : {}}
                                        // value={this.props.rent_contract_editor_froms.operation_user_id}
                                        // value={this.props.contract_follow_list_form.operation_user_id ? this.props.users_select.filter(a => a.value === this.props.contract_follow_list_form.operation_user_id)[0] : null}
                                        onChange={this.handleSelectChange}
                                        size="small"
                                        renderInput={(params) => <TextField
                                            error={!this.props.rent_responses_editor.web_user_id}
                                            variant="outlined"
                                            name="web_user_id"
                                            {...params} label="Web担当" />}

                                    /> : ""}
                                </FormControl>

                            </Grid>
                            : ""}

                        {(this.props.customerNewDisp == 0 && Number(this.props.rent_responses_editor.rent_m_way_id) === 5) || this.props.customerNewDisp == 1 ? <Grid xs={6} style={{ padding: "5px" }}>
                            <FormControl >

                                {this.props.section_select.length > 0 && this.props.user_details ? <Autocomplete
                                    disabled = {true}
                                    id="combo-box-demo"
                                    options={this.props.section_select.map((value) => ({
                                        value: value.value,
                                        label: value.label,
                                        name: 'select_going_section_id',
                                    }))}
                                    getOptionLabel={(option) => option.label}
                                    name="select_going_section_id"
                                    value={this.props.rent_responses_editor.select_going_section_id ? this.props.section_select.filter(a => a.value === this.props.rent_responses_editor.select_going_section_id)[0] : this.props.rent_responses_editor.going_user_id ? this.props.section_select.filter(a => a.value === this.props.users.filter(a => a.id === this.props.rent_responses_editor.going_user_id)[0].amSectionId)[0] : this.props.section_select.filter(a => a.value === this.props.rent_responses_editor.select_going_section_id)[0]}

                                    style={{ width: 180 }}
                                    onChange={this.handleSelectChange}
                                    size="small"
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        style={{backgroundColor:"#e8e8e8"}}
                                        {...params} label="案内担当所属店舗を選択" />}

                                /> : ""}
                            </FormControl>

                        </Grid>:""}

                        {(this.props.customerNewDisp == 0 && Number(this.props.rent_responses_editor.rent_m_way_id) === 5) || this.props.customerNewDisp == 1 ? <Grid xs={6} style={{ padding: "5px" }}>
                            <FormControl style={{ width: "160px" }}>
                                {this.props.users_select.length > 0 && this.props.user_details ? <Autocomplete
                                    disabled = {true}
                                    id="combo-box-demo"
                                    options={this.props.rent_responses_editor.select_going_section_id ? this.props.users.filter(a => a.amSectionId === this.props.rent_responses_editor.select_going_section_id).map((value) => ({
                                        value: value.id,
                                        label: value.userNameAll,
                                        name: 'going_user_id',
                                        section_id: value.amSectionId,
                                    })) : this.props.rent_responses_editor.going_user_id ? this.props.users.filter(a => a.amSectionId === this.props.users.filter(a => a.id === this.props.rent_responses_editor.going_user_id)[0].amSectionId).map((value) => ({
                                        value: value.id,
                                        label: value.userNameAll,
                                        name: 'going_user_id',
                                        section_id: value.amSectionId,
                                    })) :

                                        this.props.users.map((value) => ({
                                            value: value.id,
                                            label: value.userNameAll,
                                            name: 'going_user_id',
                                            section_id: value.amSectionId,
                                        }))}
                                    getOptionLabel={(option) => option.label}
                                    // defaultValue={this.props.users_select.filter(a => a.value === this.props.user_details.id)[0]}
                                    // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                                    style={{ width: 150, }}
                                    name="going_user_id"
                                    value={this.props.rent_responses_editor.going_user_id ? this.props.users.map((value) => ({
                                        value: value.id,
                                        label: value.userNameAll,
                                        name: 'going_user_id',
                                        section_id: value.amSectionId,
                                    })).filter(a => a.value === this.props.rent_responses_editor.going_user_id)[0] : {}}
                                    onChange={this.handleSelectChange}
                                    size="small"
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        name="going_user_id"
                                        {...params} label="案内担当" />}

                                /> : ""}
                            </FormControl>

                        </Grid> : ""}


                        {/* <Grid justify="space-between" item xs={6}>
                            <Autocomplete
                                id="combo-box-demo"

                                options={this.props.users_select.length === 0 && this.props.rent_responses_editor.user_id ? [this.props.users_select.filter(a => a.value === this.props.rent_responses_editor.user_id)[0]]
                                    : this.props.users_select}
                                getOptionLabel={(option) => option.label ? option.label : ''}

                                style={{ width: "100%" }}
                                name="user_id"
                                loading={this.props.users_loading}

                                value={this.props.rent_responses_editor.user_id ? this.props.users_select.filter(a => a.value === this.props.rent_responses_editor.user_id)[0] : {}}

                                onChange={this.handleUserIdChangeSet}
                                renderInput={(params) => <TextField
                                    {...params}
                                    variant="outlined"
                                    name="user_id"
                                    error={!this.props.rent_responses_editor.user_id}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>

                                                {this.props.users_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                    label="反響担当" />}

                            />
                        </Grid>

                        <Grid justify="space-between" item xs={6}>

                            <Autocomplete
                                id="combo-box-demo"

                                options={this.props.users_select.length === 0 && this.props.rent_responses_editor.primary_user_id ? [this.props.users_select.filter(a => a.value === this.props.rent_responses_editor.primary_user_id)[0]]
                                    : this.props.users_select}
                                getOptionLabel={(option) => option.label ? option.label : ''}

                                style={{ width: "100%" }}
                                name="primary_user_id"
                                loading={this.props.users_loading}

                                value={this.props.rent_responses_editor.primary_user_id ? this.props.users_select.filter(a => a.value === this.props.rent_responses_editor.primary_user_id)[0] : {}}

                                onChange={this.handlePrimaryUserIdChangeSet}
                                renderInput={(params) => <TextField
                                    {...params}
                                    variant="outlined"
                                    name="primary_user_id"
                                    error={!this.props.rent_responses_editor.primary_user_id}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>

                                                {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                    label="追客担当" />}

                            />
                        </Grid> */}
                    </Grid>


                    <Grid container style={{marginTop:10}}>
                        <Grid xs={6} style={{padding: "8px 5px 0px 5px"}}>

                            <FormControl variant="outlined" size="small">
                                <InputLabel id="rank-outlined-label">ランク</InputLabel>
                                <MaterialUiSelect
                                    labelId="rank-label"
                                    id="rank" style={{ width: 150 }}
                                    value={this.props.rent_responses_editor.rank}
                                    name="rank"
                                    // onChange={this.onChange.bind(this, "setRentMMediumId")}
                                    onChange={this.handleChange}
                                    label="rank"
                                >
                                    <MenuItem value={0}>-</MenuItem>
                                    <MenuItem value={1}>S</MenuItem>
                                    <MenuItem value={2}>A</MenuItem>
                                    <MenuItem value={3}>B</MenuItem>
                                    <MenuItem value={4}>C</MenuItem>
                                </MaterialUiSelect>
                            </FormControl>

                        </Grid>
                        <Grid item xs={12} style={{padding: "5px"}}>
                            <TextField fullWidth
                                id="responsed_buildings"
                                label="問合物件"
                                variant="outlined"
                                size="small"
                                onChange={this.handleChange}
                                name="responsed_buildings"
                                value={this.props.rent_responses_editor.responsed_buildings}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid xs={12} style={{padding: "0px 5px"}}>
                            <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 10 }}>類型</FormLabel>
                            <RadioGroup  error={this.props.rent_responses_editor.student_independent == 0} row aria-label="position" name="student_independent" onChange={this.handleChange} value={Number(this.props.rent_responses_editor.student_independent)} >
                                <FormControlLabel control={<Radio style={{backgroundColor:this.props.rent_responses_editor.student_independent == 0 ? "#fdece9": "transparent"}} value={1} />} label="社会人" />
                                <FormControlLabel control={<Radio style={{backgroundColor:this.props.rent_responses_editor.student_independent == 0 ? "#fdece9": "transparent"}} value={2} />} label="学生" />
                            </RadioGroup>
                        </Grid>

                        {String(this.props.rent_responses_editor.student_independent) === "2" ? <Grid xs={12} style={{padding: "0px 5px"}}>
                            <FormControlLabel style={{ marginTop: -10 }} control={<Checkbox checked={this.props.rent_responses_editor.gakusei_yoyaku == 1 ? true : false} onChange={this.handleChange} name="gakusei_yoyaku" />} label="学生予約" />
                        
                            {/* {this.props.rent_responses_editor.gakusei_yoyaku == 1 ? <>

                                <TextField
                                    id="gakusei_yoyaku_date"
                                    label="学生予約来店日"
                                    type="date"
                                    name="gakusei_yoyaku_date"
                                    size="small"
                                    variant='outlined'
                                    onChange={this.handleChange}
                                    error={!this.props.rent_responses_editor.gakusei_yoyaku_date}
                                    value={moment(this.props.rent_responses_editor.gakusei_yoyaku_date).format("YYYY-MM-DD")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />


                            </>:""} */}
                        </Grid> : ""}
                        {String(this.props.rent_responses_editor.student_independent) === "2" ? <Grid xs={5} style={{padding: "5px"}}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="rent_m_school_id-label">学校名</InputLabel>
                                <MaterialUiSelect
                                    labelId="rent_m_school_id-label"
                                    id="rent_m_school_id"
                                    name="rent_m_school_id"
                                    value={this.props.rent_responses_editor.rent_m_school_id}
                                    onChange={this.handleChange}
                                    label="学校名"
                                    error={!this.props.rent_responses_editor.rent_m_school_id}
                                >

                                    
                                    <MenuItem value={0}>-</MenuItem>

                                    {this.props.rent_m_schools.map(function (item, i) {
                                        return (
                                            <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                        );

                                    })}
                                </MaterialUiSelect>
                            </FormControl>
                        </Grid> : ""}
                        {String(this.props.rent_responses_editor.student_independent) === "2" ? <Grid xs={7} style={{padding: "5px"}}>
                            <TextField fullWidth
                                id="highschool"
                                label="出身校"
                                variant="outlined"
                                onChange={this.handleChange}
                                name="highschool"
                                value={this.props.rent_responses_editor.highschool}
                                style={{}}
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={"不明の場合は「不明」と入力"}
                                error={!this.props.rent_responses_editor.highschool}
                            />
                        </Grid> : ""}
                        {String(this.props.rent_responses_editor.student_independent) === "2" ? <Grid xs={5} style={{padding: "5px"}}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="gakunen-label">学年</InputLabel>
                                <MaterialUiSelect
                                    labelId="gakunen-label"
                                    id="gakunen"
                                    name="gakunen"
                                    value={this.props.rent_responses_editor.gakunen}
                                    onChange={this.handleChange}
                                    label="学年"
                                    error={!this.props.rent_responses_editor.gakunen}
                                >
                                    <MenuItem value={0}>-</MenuItem>
                                    <MenuItem value={1}>新入学</MenuItem>
                                    <MenuItem value={2}>1回生</MenuItem>
                                    <MenuItem value={3}>2回生</MenuItem>
                                    <MenuItem value={4}>3回生</MenuItem>
                                    <MenuItem value={5}>4回生</MenuItem>
                                    <MenuItem value={6}>院生</MenuItem>

                                </MaterialUiSelect>
                            </FormControl>
                        </Grid> : ""}
                        {String(this.props.rent_responses_editor.student_independent) === "2" && this.props.rent_responses_editor.gakunen === 1 ? <Grid xs={7} style={{padding: "0px 5px"}}>
                            <Grid justifyContent="space-between" item xs={12}>
                                <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 10 }}>受験予定</FormLabel>
                                
                                {this.props.rent_responses_editor.rent_m_school_id && this.props.rent_m_schools.length > 0 && this.props.rent_m_schools.filter(a=>a.id == this.props.rent_responses_editor.rent_m_school_id)[0].classification == 0 ?
                                <>
                                    <FormControlLabel control={<Checkbox checked={this.props.rent_responses_editor.jyukenyotei ? this.props.rent_responses_editor.jyukenyotei.split(',').includes("1") : false} value={1} onChange={this.handleChange} name="jyukenyotei" />} label="推薦" />
                                    <FormControlLabel control={<Checkbox checked={this.props.rent_responses_editor.jyukenyotei ? this.props.rent_responses_editor.jyukenyotei.split(',').includes("2") : false} value={2} onChange={this.handleChange} name="jyukenyotei" />} label="前期" />
                                    <FormControlLabel control={<Checkbox checked={this.props.rent_responses_editor.jyukenyotei ? this.props.rent_responses_editor.jyukenyotei.split(',').includes("3") : false} value={3} onChange={this.handleChange} name="jyukenyotei" />} label="後期" />
                                </>:
                                <>
                                    <FormControlLabel control={<Checkbox checked={this.props.rent_responses_editor.jyukenyotei ? this.props.rent_responses_editor.jyukenyotei.split(',').includes("1") : false} value={1} onChange={this.handleChange} name="jyukenyotei" />} label="推薦" />
                                    <FormControlLabel control={<Checkbox checked={this.props.rent_responses_editor.jyukenyotei ? this.props.rent_responses_editor.jyukenyotei.split(',').includes("4") : false} value={4} onChange={this.handleChange} name="jyukenyotei" />} label="一般" />
                                </>
                                }
                            </Grid>
                        </Grid> : ""}
                        {String(this.props.rent_responses_editor.student_independent) === "2" && this.props.rent_responses_editor.gakunen === 1 ? <Grid xs={5} style={{padding: "5px"}}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="gakunen-label">合否</InputLabel>
                                
                                {this.props.rent_responses_editor.rent_m_school_id && this.props.rent_m_schools.length > 0 && this.props.rent_m_schools.filter(a=>a.id == this.props.rent_responses_editor.rent_m_school_id)[0].classification == 0 ?
                                <MaterialUiSelect
                                    labelId="gakunen-label"
                                    id="gouhi"
                                    name="gouhi"
                                    value={this.props.rent_responses_editor.gouhi}
                                    onChange={this.handleChange}
                                    label="合否"
                                >
                                        <MenuItem value={0}>-</MenuItem>
                                        <MenuItem value={1}>推薦合格</MenuItem>
                                        <MenuItem value={2}>前期合格</MenuItem>
                                        <MenuItem value={3}>後期合格</MenuItem>
                                        <MenuItem value={4}>不合格</MenuItem>
 
                                </MaterialUiSelect>
                                :
                                <MaterialUiSelect
                                    labelId="gakunen-label"
                                    id="gouhi"
                                    name="gouhi"
                                    value={this.props.rent_responses_editor.gouhi}
                                    onChange={this.handleChange}
                                    label="合否"
                                >
                                        <MenuItem value={0}>-</MenuItem>
                                        <MenuItem value={1}>推薦合格</MenuItem>
                                        <MenuItem value={5}>一般合格</MenuItem>
                                        <MenuItem value={4}>不合格</MenuItem>
                                </MaterialUiSelect>}
                            </FormControl>
                        </Grid> : ""}
                        {String(this.props.rent_responses_editor.student_independent) === "2" && this.props.rent_responses_editor.gakunen === 1 ? <Grid xs={7} style={{padding: "0px 5px"}}>
                        </Grid> : ""}
                        {String(this.props.rent_responses_editor.student_independent) === "2" ? <Grid xs={12} style={{padding: "5px"}}>
                            <TextField fullWidth
                                id="reserveroom"
                                label="予約物件"
                                variant="outlined"
                                onChange={this.handleChange}
                                name="reserveroom"
                                value={this.props.rent_responses_editor.reserveroom}
                                style={{}}
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid> : ""}
                        {String(this.props.rent_responses_editor.student_independent) === "1" ? <Grid xs={12} style={{padding: "5px"}}>
                            <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 10 }}>単身・ファミリー</FormLabel>
                            <RadioGroup row aria-label="position" name="alone_family" onChange={this.handleChange} value={Number(this.props.rent_responses_editor.alone_family)} >
                                <FormControlLabel control={<Radio value={1} style={{backgroundColor:this.props.rent_responses_editor.alone_family == 0 ? "#fdece9": "transparent"}} />} label="単身" />
                                <FormControlLabel control={<Radio value={2} style={{backgroundColor:this.props.rent_responses_editor.alone_family == 0 ? "#fdece9": "transparent"}} />} label="ファミリー" />
                            </RadioGroup>
                        </Grid> : ""}
                        {/* <Grid style={{padding: "0px 5px"}}>
                            <TextField fullWidth
                                // multiline
                                label="現住居"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_residence"
                                value={this.props.rent_responses_editor.free_residence}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                // minRows={1} maxRows={4}
                            />

                            <TextField fullWidth
                                multiline
                                label="引越し理由"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_moving_reason"
                                value={this.props.rent_responses_editor.free_moving_reason}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={1} maxRows={4}
                            />

                            <TextField fullWidth
                                multiline
                                label="人物属性その他"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_person_attributes"
                                value={this.props.rent_responses_editor.free_person_attributes}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={1} maxRows={4}
                            />

                            <TextField fullWidth
                                multiline
                                label="通勤通学先"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_office_school"
                                value={this.props.rent_responses_editor.free_office_school}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={1} maxRows={4}
                            />

                            <TextField fullWidth
                                // multiline
                                label="入居人数"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_number_of_people"
                                value={this.props.rent_responses_editor.free_number_of_people}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                // minRows={1} maxRows={4}
                            />

                            <TextField fullWidth
                                // multiline
                                label="賃料目安"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_rent"
                                value={this.props.rent_responses_editor.free_rent}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                // minRows={1} maxRows={4}
                            />

                            <TextField fullWidth
                                // multiline
                                label="賃料規定あれば"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_rent_remarks"
                                value={this.props.rent_responses_editor.free_rent_remarks}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                // minRows={1} maxRows={4}
                            />

                            <TextField fullWidth
                                // multiline
                                label="間取り目安"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_madori"
                                value={this.props.rent_responses_editor.free_madori}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                // minRows={1} maxRows={4}
                            />

                            <TextField fullWidth
                                multiline
                                label="エリア目安"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_area"
                                value={this.props.rent_responses_editor.free_area}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={1} maxRows={4}
                            />

                            <TextField fullWidth
                                multiline
                                label="入居時期"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_move_in_period"
                                value={this.props.rent_responses_editor.free_move_in_period}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={1} maxRows={4}
                            />

                            <TextField fullWidth
                                multiline
                                label="補足事項"
                                variant="outlined"
                                margin='dense'
                                onChange={this.handleChange}
                                name="free_remarks"
                                value={this.props.rent_responses_editor.free_remarks}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={1} maxRows={4}
                            />
                        </Grid> */}
                    </Grid>


                    <Grid container style={{marginTop:10}}>
                        <Grid xs={6} style={{padding: "5px"}}>
                            {/* <TextField
                    id="request_move_date"
                    label="引越時期"
                    type="date"
                    name="request_move_date"
                    onChange={this.handlerequestMoveDate}
                    defaultValue={moment(this.props.rent_responses_editor.request_move_date).format("YYYY-MM-DD")}
                    // className={classes.textField}
                    InputLabelProps={{shrink: true,}}
                /> */}
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="request_move_later-label">引越時期</InputLabel>
                                <MaterialUiSelect
                                    labelId="request_move_later-label"
                                    id="request_moving_time"
                                    name="request_moving_time"
                                    value={this.props.rent_responses_editor.request_moving_time}
                                    onChange={this.handleChange}
                                    label="引越時期"
                                >

                                    <MenuItem value={0}>-</MenuItem>
                                    <MenuItem value={1}>今月中</MenuItem>
                                    <MenuItem value={2}>来月中</MenuItem>
                                    <MenuItem value={3}>2ヶ月後</MenuItem>
                                    <MenuItem value={4}>3ヶ月後</MenuItem>
                                    <MenuItem value={5}>4ヶ月後</MenuItem>
                                    <MenuItem value={6}>5ヶ月後</MenuItem>
                                    <MenuItem value={7}>半年後</MenuItem>
                                    <MenuItem value={8}>1年以内 </MenuItem>
                                    <MenuItem value={9}>未定いいのがあれば </MenuItem>
                                </MaterialUiSelect>
                            </FormControl>
                        </Grid>

                        <Grid xs={6} style={{padding: "5px"}}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="rent_m_reason_id-label">引越理由</InputLabel>
                                <MaterialUiSelect
                                    labelId="rent_m_reason_id-label"
                                    id="rent_m_reason_id"
                                    name="rent_m_reason_id"
                                    value={this.props.rent_responses_editor.rent_m_reason_id}
                                    onChange={this.handleChange}
                                    label="引越理由"
                                    error={!this.props.rent_responses_editor.rent_m_reason_id}
                                >
                                    {this.props.rentMReasons.filter(a=>a.orderNo != null || a.id == this.props.rent_responses_editor.rent_m_reason_id).sort((a, b) => a['orderNo'] - b['orderNo']).map(function (item, i) {
                                        return (
                                            <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                        );

                                    })}
                                </MaterialUiSelect>
                            </FormControl>
                        </Grid>


                        
                        <Grid xs={12} style={{padding: "5px"}}>
                            <FormControl variant="outlined" size="small">
                                <InputLabel id="request_from_cost-label">希望金額</InputLabel>
                                <MaterialUiSelect
                                    labelId="request_from_cost-label"
                                    id="request_from_cost"
                                    name="request_from_cost"
                                    value={this.props.rent_responses_editor.request_from_cost}
                                    onChange={this.handleChange}
                                    label="希望金額" style={{ width: 250 }}
                                >
                                    <MenuItem value={0}>-</MenuItem>
                                    <MenuItem value={20000}>20,000円</MenuItem>
                                    <MenuItem value={25000}>25,000円</MenuItem>
                                    <MenuItem value={30000}>30,000円</MenuItem>
                                    <MenuItem value={35000}>35,000円</MenuItem>
                                    <MenuItem value={40000}>40,000円</MenuItem>
                                    <MenuItem value={45000}>45,000円</MenuItem>
                                    <MenuItem value={50000}>50,000円</MenuItem>
                                    <MenuItem value={55000}>55,000円</MenuItem>
                                    <MenuItem value={60000}>60,000円</MenuItem>
                                    <MenuItem value={65000}>65,000円</MenuItem>
                                    <MenuItem value={70000}>70,000円</MenuItem>
                                    <MenuItem value={75000}>75,000円</MenuItem>
                                    <MenuItem value={80000}>80,000円</MenuItem>
                                    <MenuItem value={85000}>85,000円</MenuItem>
                                    <MenuItem value={90000}>90,000円</MenuItem>
                                    <MenuItem value={95000}>95,000円</MenuItem>
                                    <MenuItem value={100000}>100,000円</MenuItem>
                                    <MenuItem value={105000}>105,000円</MenuItem>
                                    <MenuItem value={110000}>110,000円</MenuItem>
                                    <MenuItem value={120000}>120,000円</MenuItem>
                                    <MenuItem value={130000}>130,000円</MenuItem>
                                    <MenuItem value={140000}>140,000円</MenuItem>
                                    <MenuItem value={150000}>150,000円以上</MenuItem>

                                </MaterialUiSelect>
                            </FormControl>～
                            <FormControl variant="outlined" size="small">
                                <InputLabel id="request_to_cost-label">希望金額</InputLabel>
                                <MaterialUiSelect
                                    labelId="request_to_cost-label"
                                    id="request_to_cost"
                                    name="request_to_cost"
                                    value={this.props.rent_responses_editor.request_to_cost}
                                    onChange={this.handleChange}
                                    label="希望金額" style={{ width: 250 }}
                                >
                                    <MenuItem value={0}>-</MenuItem>
                                    <MenuItem value={20000}>20,000円</MenuItem>
                                    <MenuItem value={25000}>25,000円</MenuItem>
                                    <MenuItem value={30000}>30,000円</MenuItem>
                                    <MenuItem value={35000}>35,000円</MenuItem>
                                    <MenuItem value={40000}>40,000円</MenuItem>
                                    <MenuItem value={45000}>45,000円</MenuItem>
                                    <MenuItem value={50000}>50,000円</MenuItem>
                                    <MenuItem value={55000}>55,000円</MenuItem>
                                    <MenuItem value={60000}>60,000円</MenuItem>
                                    <MenuItem value={65000}>65,000円</MenuItem>
                                    <MenuItem value={70000}>70,000円</MenuItem>
                                    <MenuItem value={75000}>75,000円</MenuItem>
                                    <MenuItem value={80000}>80,000円</MenuItem>
                                    <MenuItem value={85000}>85,000円</MenuItem>
                                    <MenuItem value={90000}>90,000円</MenuItem>
                                    <MenuItem value={95000}>95,000円</MenuItem>
                                    <MenuItem value={100000}>100,000円</MenuItem>
                                    <MenuItem value={105000}>105,000円</MenuItem>
                                    <MenuItem value={110000}>110,000円</MenuItem>
                                    <MenuItem value={120000}>120,000円</MenuItem>
                                    <MenuItem value={130000}>130,000円</MenuItem>
                                    <MenuItem value={140000}>140,000円</MenuItem>
                                    <MenuItem value={150000}>150,000円以上</MenuItem>

                                </MaterialUiSelect>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} style={{padding: "5px"}}>
                            <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 10 }}>希望種別</FormLabel>
                            <FormControlLabel control={<Checkbox checked={this.props.rent_responses_editor.request_tatemono_types ? this.props.rent_responses_editor.request_tatemono_types.split(',').includes("1") : false} value={1} onChange={this.handleChange} name="request_tatemono_types" />} label="アパート" />
                            <FormControlLabel control={<Checkbox checked={this.props.rent_responses_editor.request_tatemono_types ? this.props.rent_responses_editor.request_tatemono_types.split(',').includes("2") : false} value={2} onChange={this.handleChange} name="request_tatemono_types" />} label="マンション" />
                            <FormControlLabel control={<Checkbox checked={this.props.rent_responses_editor.request_tatemono_types ? this.props.rent_responses_editor.request_tatemono_types.split(',').includes("7") : false} value={7} onChange={this.handleChange} name="request_tatemono_types" />} label="戸建て" />
                        </Grid>
                        <Grid xs={12} style={{padding: "5px"}}>
                            <FormControl variant="outlined" size="small">
                                <InputLabel id="gakunen-label">築年</InputLabel>
                                <MaterialUiSelect
                                    labelId="gakunen-label"
                                    id="request_year"
                                    name="request_year"
                                    value={this.props.rent_responses_editor.request_year}
                                    onChange={this.handleChange}
                                    label="築年" style={{ width: 250 }}
                                >
                                    <MenuItem value={0}>-</MenuItem>
                                    <MenuItem value={1}>1年以内</MenuItem>
                                    <MenuItem value={2}>2年以内</MenuItem>
                                    <MenuItem value={3}>3年以内</MenuItem>
                                    <MenuItem value={4}>4年以内</MenuItem>
                                    <MenuItem value={5}>5年以内</MenuItem>
                                    <MenuItem value={6}>6年以内</MenuItem>
                                    <MenuItem value={7}>7年以内</MenuItem>
                                    <MenuItem value={8}>8年以内</MenuItem>
                                    <MenuItem value={9}>9年以内</MenuItem>
                                    <MenuItem value={10}>10年以内</MenuItem>
                                    <MenuItem value={11}>11年以内</MenuItem>
                                    <MenuItem value={12}>12年以内</MenuItem>
                                    <MenuItem value={13}>13年以内</MenuItem>
                                    <MenuItem value={14}>14年以内</MenuItem>
                                    <MenuItem value={15}>15年以内</MenuItem>
                                    <MenuItem value={15}>15年以内</MenuItem>
                                    <MenuItem value={16}>16年以内</MenuItem>
                                    <MenuItem value={17}>17年以内</MenuItem>
                                    <MenuItem value={18}>18年以内</MenuItem>
                                    <MenuItem value={19}>19年以内</MenuItem>
                                    <MenuItem value={20}>20年以内</MenuItem>
                                </MaterialUiSelect>
                            </FormControl>
                        </Grid>

                        <Grid xs={12} style={{padding: "5px"}}>
                            <FormControl variant="outlined" size="small">
                                <InputLabel id="request_from_plan-label">希望間取</InputLabel>
                                <MaterialUiSelect
                                    labelId="requestFrrequest_from_planomPlan-label"
                                    id="request_from_plan"
                                    name="request_from_plan"
                                    value={this.props.rent_responses_editor.request_from_plan}
                                    onChange={this.handleChange}
                                    label="希望間取" style={{ width: 250 }}
                                >
                                    <MenuItem value={0}>-</MenuItem>
                                    <MenuItem value={2}>1K</MenuItem>
                                    <MenuItem value={3}>1DK</MenuItem>
                                    <MenuItem value={5}>1LDK</MenuItem>
                                    <MenuItem value={8}>2R</MenuItem>
                                    <MenuItem value={9}>2DK</MenuItem>
                                    <MenuItem value={11}>2LDK</MenuItem>
                                    <MenuItem value={15}>3DK</MenuItem>
                                    <MenuItem value={17}>3LDK</MenuItem>
                                </MaterialUiSelect>
                            </FormControl>～
                            <FormControl variant="outlined" size="small">
                                <InputLabel id="request_to_plan-label">希望間取</InputLabel>
                                <MaterialUiSelect
                                    labelId="request_to_plan-label"
                                    id="request_to_plan"
                                    name="request_to_plan"
                                    value={this.props.rent_responses_editor.request_to_plan}
                                    onChange={this.handleChange}
                                    label="希望間取" style={{ width: 250 }}
                                >
                                    <MenuItem value={0}>-</MenuItem>
                                    <MenuItem value={2}>1K</MenuItem>
                                    <MenuItem value={3}>1DK</MenuItem>
                                    <MenuItem value={5}>1LDK</MenuItem>
                                    <MenuItem value={8}>2R</MenuItem>
                                    <MenuItem value={9}>2DK</MenuItem>
                                    <MenuItem value={11}>2LDK</MenuItem>
                                    <MenuItem value={15}>3DK</MenuItem>
                                    <MenuItem value={17}>3LDK</MenuItem>
                                </MaterialUiSelect>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} style={{padding: "5px"}}>
                            <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 10 }}>地域</FormLabel>
                            <div style={{ paddingTop: 8 }}>
                                <FormControlLabel control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }} checked={this.props.rent_responses_editor.areas ? this.props.rent_responses_editor.areas.split(',').includes("1") : false} value={1} onChange={this.handleChange} name="areas" />} label="高松市中心部" />
                                <FormControlLabel control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }} checked={this.props.rent_responses_editor.areas ? this.props.rent_responses_editor.areas.split(',').includes("2") : false} value={2} onChange={this.handleChange} name="areas" />} label="レインボー・サンフラワー周辺" />
                                <FormControlLabel control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }} checked={this.props.rent_responses_editor.areas ? this.props.rent_responses_editor.areas.split(',').includes("3") : false} value={3} onChange={this.handleChange} name="areas" />} label="高松市南部" /><br />
                                <FormControlLabel control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }} checked={this.props.rent_responses_editor.areas ? this.props.rent_responses_editor.areas.split(',').includes("4") : false} value={4} onChange={this.handleChange} name="areas" />} label="高松市東部" />
                                <FormControlLabel control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }} checked={this.props.rent_responses_editor.areas ? this.props.rent_responses_editor.areas.split(',').includes("5") : false} value={5} onChange={this.handleChange} name="areas" />} label="高松市西部" />
                                <FormControlLabel control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }} checked={this.props.rent_responses_editor.areas ? this.props.rent_responses_editor.areas.split(',').includes("6") : false} value={6} onChange={this.handleChange} name="areas" />} label="香川町　三木町" />
                                <FormControlLabel control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }} checked={this.props.rent_responses_editor.areas ? this.props.rent_responses_editor.areas.split(',').includes("7") : false} value={7} onChange={this.handleChange} name="areas" />} label="庵治町・牟礼町" /><br />
                                <FormControlLabel control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }} checked={this.props.rent_responses_editor.areas ? this.props.rent_responses_editor.areas.split(',').includes("8") : false} value={8} onChange={this.handleChange} name="areas" />} label="高松市全域" />
                            </div>
                        </Grid>


                        <Grid xs={12} style={{padding: "5px 5px"}}>

                            {!this.state.chomeiEdit && this.props.rent_responses_editor.request_chomei ? this.props.rent_responses_editor.request_chomei.split(',').map(function (item) {

                                return parseInt(item) > 0 ? this.props.chomeis.filter(a => a.id === parseInt(item))[0].townName : (item === "-1" ? "東かがわ市" : (item === "-2" ? "さぬき市" : ""))

                            }, this).join(',') : ""
                            }


                            {this.state.chomeiEdit === true ? <span>
                                <Tooltip title="閉じる">
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => this.setState({ "chomeiEdit": false })}
                                        style={{ float: "right" }}
                                        size="large">
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                                <ChomeiEdit />
                            </span> :
                                <Button color="primary" size="small" style={{ color: "white", backgroundColor: "#28a745", float: "left" }} onClick={() => this.setState({ "chomeiEdit": true })}>町名選択</Button>}


                        </Grid>


                        <Grid xs={12} style={{padding: "4px 5px"}}>
                            {!this.state.shougakkouEdit && this.props.rent_responses_editor.request_shougakkou ? this.props.rent_responses_editor.request_shougakkou.split(',').map(function (item) {

                                return this.props.shougakkous.filter(a => a.shougakkouNo === parseInt(item))[0] ? this.props.shougakkous.filter(a => a.shougakkouNo === parseInt(item))[0].shougakkouName : ""

                            }, this).join(',') : ""
                            }


                            {this.state.shougakkouEdit ? <span>
                                <Tooltip title="閉じる">
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => this.setState({ "shougakkouEdit": false })}
                                        style={{ float: "right" }}
                                        size="large">
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                                <ShougakkouEdit />
                            </span> :
                                <Button color="primary" size="small" style={{ color: "white", backgroundColor: "#28a745", float: "left" }} onClick={() => this.setState({ "shougakkouEdit": true })}>小学校区選択</Button>}

                        </Grid>


                        <Grid xs={12} style={{padding: "4px 5px"}}>
                            {!this.state.chuugakkouEdit && this.props.rent_responses_editor.request_chuugakkou ? this.props.rent_responses_editor.request_chuugakkou.split(',').map(function (item) {

                                return this.props.chuugakkous.filter(a => a.chuugakkouNo === parseInt(item))[0].chuugakkouName

                            }, this).join(',') : ""
                            }

                            {this.state.chuugakkouEdit ? <span>
                                <Tooltip title="閉じる">
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => this.setState({ "chuugakkouEdit": false })}
                                        style={{ float: "right" }}
                                        size="large">
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                                <ChuugakkouEdit />
                            </span> :
                                <Button color="primary" size="small" style={{ color: "white", backgroundColor: "#28a745", float: "left" }} onClick={() => this.setState({ "chuugakkouEdit": true })}>中学校区選択</Button>}

                        </Grid>

                        <Grid xs={12} style={{padding: "5px 5px"}}>
                            <Card style={{ marginTop: 20, padding: 10 }}>
                                <FormLabel component="legend" style={{ marginBottom: 0, fontSize: "15px" }}>こだわり条件</FormLabel>
                                <Grid container spacing={1} style={{ marginTop: 18 }}>

                                    <Grid justifyContent="space-between" item xs={12}>
                                        <FormLabel component="legend" style={{ marginBottom: 0 }}>キッチン</FormLabel>
                                    </Grid>



                                    <Grid justifyContent="space-between" item xs={6}>
                                        {/* <Field name="gasu" id="gasu" component="input" type="checkbox" value="gasu" onClick={()=>this.setState({"gasu" : !this.state.gasu})} /> */}
                                        <FormControlLabel control={<Checkbox checked={this.state.gasu} style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }} onChange={(e) => { this.handleRequestsChange(e); this.setState({ "gasu": !this.state.gasu }) }} name="response_requests_gasu" />} label="ガスコンロ付" />
                                        {this.state.gasu ? <MaterialUiSelect
                                            labelId="response_requests-label"
                                            id="response_requests"
                                            name="response_requests"
                                            value={this.props.rent_responses_editor.response_requests ? (this.props.rent_responses_editor.response_requests.split(',').includes("24") ? 24 : (this.props.rent_responses_editor.response_requests.split(',').includes("23") ? 23 : (this.props.rent_responses_editor.response_requests.split(',').includes("22") ? 22 : (this.props.rent_responses_editor.response_requests.split(',').includes("95") ? 95 : 0)))) : 0}
                                            onChange={this.handleChange}
                                        // label="requestToPlan"
                                        >
                                            <MenuItem value={24}>1口</MenuItem>
                                            <MenuItem value={23}>2口</MenuItem>
                                            <MenuItem value={22}>3口</MenuItem>
                                            <MenuItem value={95}>口数不明</MenuItem>
                                        </MaterialUiSelect> : ""}
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>

                                        <FormControlLabel control={<Checkbox checked={this.state.ih} style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }} onChange={(e) => { this.handleRequestsChange(e); this.setState({ "ih": !this.state.ih }) }} name="response_requests_ih" />} label="IHヒーター付" />
                                        {this.state.ih ? <MaterialUiSelect
                                            labelId="response_requests-label"
                                            id="response_requests"
                                            name="response_requests"
                                            onChange={this.handleChange}
                                            value={this.props.rent_responses_editor.response_requests ? (this.props.rent_responses_editor.response_requests.split(',').includes("29") ? 29 : (this.props.rent_responses_editor.response_requests.split(',').includes("30") ? 30 : (this.props.rent_responses_editor.response_requests.split(',').includes("59") ? 59 : (this.props.rent_responses_editor.response_requests.split(',').includes("92") ? 92 : 0)))) : 0}
                                        >
                                            <MenuItem value={29}>1口</MenuItem>
                                            <MenuItem value={30}>2口</MenuItem>
                                            <MenuItem value={59}>3口</MenuItem>
                                            <MenuItem value={92}>口数不明</MenuItem>
                                        </MaterialUiSelect> : ""}
                                    </Grid>
                                    {/* {this.props.rent_responses_editor.response_requests} */}
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("91") : false}
                                                onChange={this.handleChange}
                                                value={91}
                                                name="response_requests" />}
                                            label="対面キッチン" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("21") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={21}
                                            label="システムキッチン" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={12}>
                                        <FormLabel component="legend" style={{ marginBottom: 0 }}>冷暖房</FormLabel>
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel control={<Checkbox checked={this.state.eakon} style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }} onChange={(e) => { this.handleRequestsChange(e); this.setState({ "eakon": !this.state.eakon }) }} name="response_requests_eakon" />} label="エアコン" />
                                        {this.state.eakon ? <MaterialUiSelect
                                            labelId="response_requests-label"
                                            id="response_requests"
                                            name="response_requests"
                                            onChange={this.handleChange}
                                            value={this.props.rent_responses_editor.response_requests ? (this.props.rent_responses_editor.response_requests.split(',').includes("58") ? 58 : (this.props.rent_responses_editor.response_requests.split(',').includes("7") ? 7 : (this.props.rent_responses_editor.response_requests.split(',').includes("8") ? 8 : (this.props.rent_responses_editor.response_requests.split(',').includes("119") ? 119 : (this.props.rent_responses_editor.response_requests.split(',').includes("151") ? 151 : (this.props.rent_responses_editor.response_requests.split(',').includes("121") ? 121 : (this.props.rent_responses_editor.response_requests.split(',').includes("115") ? 115 : 0))))))) : 0}
                                        // value={this.state.eakon_id}
                                        >
                                            <MenuItem value={58}>1台付</MenuItem>
                                            <MenuItem value={7}>2台付</MenuItem>
                                            <MenuItem value={8}>3台付</MenuItem>
                                            <MenuItem value={119}>4台付</MenuItem>
                                            <MenuItem value={151}>5台付</MenuItem>
                                            <MenuItem value={121}>6台付</MenuItem>
                                            <MenuItem value={115}>台数不明</MenuItem>

                                        </MaterialUiSelect> : ""}
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={12}>
                                        <FormLabel component="legend" style={{ marginBottom: 0 }}>給湯・浴室</FormLabel>
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("3") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={3}
                                            label="追い炊き" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("4") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={4}
                                            label="シャンプードレッサー" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("196") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={196}
                                            label="脱衣所" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={12}>
                                        <FormLabel component="legend" style={{ marginBottom: 0 }}>トイレ</FormLabel>
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("20") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={20}
                                            label="バストイレ別" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("1") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={1}
                                            label="温水洗浄便座" />
                                    </Grid>

                                    <Grid justifyContent="space-between" item xs={12}>
                                        <FormLabel component="legend" style={{ marginBottom: 0 }}>アンテナ・TV</FormLabel>
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("16") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={16}
                                            label="ネット使い放題" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("9") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={9}
                                            label="CATV" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("12") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={12}
                                            label="BS" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={12}>
                                        <FormLabel component="legend" style={{ marginBottom: 0 }}>洗濯場</FormLabel>
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("69") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={69}
                                            label="室内洗濯機置き場" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={12}>
                                        <FormLabel component="legend" style={{ marginBottom: 0 }}>セキュリティ</FormLabel>
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("41") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={41}
                                            label="TVモニターホン" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("37") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={37}
                                            label="オートロック" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("153") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={153}
                                            label="2F以上" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={12}>
                                        <FormLabel component="legend" style={{ marginBottom: 0 }}>部屋設備</FormLabel>
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("99") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={99}
                                            label="ウォークインクローゼット" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("448") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={448}
                                            label="家具・家電付き" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={12}>
                                        <FormLabel component="legend" style={{ marginBottom: 0 }}>その他</FormLabel>
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("17") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={17}
                                            label="P2台" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("104") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={104}
                                            label="バイク置き場" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("45") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={45}
                                            label="ペット相談可" />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox style={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
                                                checked={this.props.rent_responses_editor.response_requests ? this.props.rent_responses_editor.response_requests.split(',').includes("147") : false}
                                                onChange={this.handleChange}
                                                name="response_requests" />}
                                            value={147}
                                            label="猫相談可" />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>




                        <Grid xs={12}>
                            {
                                (Number(this.props.rent_responses_editor.rent_m_way_id) === 3 && !this.props.rent_responses_editor.rent_m_agent_id > 0) ? <Alert severity="error">代行会社を選択してください。</Alert> :
                                    !this.props.rent_responses_editor.rent_m_medium_id ? <Alert severity="error">メディアを選択してください。</Alert> :
                                        this.props.rent_responses_editor.section_id == -1 ? <Alert severity="error">店舗を選択してください。</Alert> :
                                            this.props.rent_responses_editor.first_contact_section_id == -1 ? <Alert severity="error">初回対応店舗を選択してください。</Alert> :
                                                !this.props.rent_responses_editor.user_id > 0 ? <Alert severity="error">反響担当を選択してください。</Alert> :
                                                    // !this.props.rent_responses_editor.primary_user_id > 0 ? <Alert severity="error">追客担当を選択してください。</Alert> :
                                                    this.props.response_attention_disp ? <Alert severity="error" action={<><LoadingButton
                                                        loading = {this.props.rent_responses_update_status}
                                                        loadingPosition="start"
                                                        startIcon={<SaveAltIcon />}
                                                        variant="contained"
                                                        onClick={this.props.responseUpdate}
                                                        color="primary"
                                                        style={{ float: "right" }}
                                                      >
                                                        登録
                                                      </LoadingButton><Button style={{marginLeft:5}}  variant="contained" onClick={this.handleCancel}>キャンセル</Button></>}>信用情報にマッチングがありますが登録しますか？</Alert>:
                                                        // <Button style={{ float: "right" }} variant="contained" color="primary" onClick={this.handleUpdate}>登録</Button>
                                                        <LoadingButton
                                                        loading = {this.props.rent_responses_update_status}
                                                        loadingPosition="start"
                                                        startIcon={<SaveAltIcon />}
                                                        variant="contained"
                                                        onClick={this.props.responseUpdate}
                                                        color="primary"
                                                        style={{ float: "right" }}
                                                      >
                                                        登録
                                                      </LoadingButton>
                            }



                            {/* {(Number(this.props.rent_responses_editor.rent_m_way_id) === 3 ? this.props.rent_responses_editor.rent_m_agent_id > 0 : true) &&
                             this.props.rent_responses_editor.rent_m_medium_id && 
                             this.props.rent_responses_editor.section_id > 0 && 
                             this.props.rent_responses_editor.first_contact_section_id > 0 &&
                             this.props.rent_responses_editor.user_id > 0 && 
                             this.props.rent_responses_editor.primary_user_id > 0 ? 
                                 <Button style={{ float: "right" }} variant="contained" color="primary" onClick={this.props.responseUpdate}>登録</Button> : 
                                 (!(Number(this.props.rent_responses_editor.rent_m_way_id) === 3 ? this.props.rent_responses_editor.rent_m_agent_id > 0 : true)  ? <Alert severity="error">代行会社を選択してください。</Alert> : 
                                 (!this.props.rent_responses_editor.rent_m_medium_id >= 1 ? <Alert severity="error">メディアを選択してください。</Alert> : 
                                 (this.props.rent_responses_editor.section_id == -1 ? <Alert severity="error">店舗を選択してください。</Alert> : 
                                 (this.props.rent_responses_editor.first_contact_section_id == -1 ? <Alert severity="error">初回担当店舗を選択してください。</Alert> :
                                 (!this.props.rent_responses_editor.user_id > 0 ? <Alert severity="error">反響担当を選択してください。</Alert> : 
                                 <Alert severity="error">追客担当を選択してください。</Alert>)))))} */}
                            {/* <button
                                type="submit"
                                className="btn btn-primary float-right"
                            >登録</button> */}
                        </Grid>

                    </Grid>


                </form>






            </div>
        );
    }
}








const mapStateToProps = state => {
    return {
        //responseedit: state.customersReducer.responseedit,
        rentMMedeas: state.masterReducer.rentMMedeas,
        users_select: state.masterReducer.users_select,
        shops: state.masterReducer.shops,
        // rentAgents: state.masterReducer.rentAgents,
        rent_m_agents: state.responsesReducer.rent_m_agents,
        rentMReasons: state.masterReducer.rentMReasons,
        newResponseCustomer: state.responsesReducer.newResponseCustomer,
        setsubi_master: state.masterReducer.setsubi_master,
        section_select: state.masterReducer.section_select,
        user_details: state.masterReducer.user_details,

        users: state.masterReducer.users,

        chomeis: state.masterReducer.chomeis,
        shougakkous: state.masterReducer.shougakkous,
        chuugakkous: state.masterReducer.chuugakkous,
        // responsedetail: state.responsesReducer.responsedetail,
        rent_responses_editor: state.responsesReducer.rent_responses_editor,

        rent_m_medea: state.responsesReducer.rent_m_medea,
        //rentMAgent:state.customersReducer.rentMAgent,
        customerNewDisp: state.customersReducer.customerNewDisp,
        response_detail_dialog_disp: state.responsesReducer.response_detail_dialog_disp,
        response_attention_disp: state.responsesReducer.response_attention_disp,
        rent_responses_update_status: state.responsesReducer.rent_responses_update_status,
        rent_customer_editor_forms: state.customersReducer.rent_customer_editor_forms,

        rent_m_schools: state.responsesReducer.rent_m_schools,
    }
}
function mapDispatchToProps(dispatch) {
    return {

        change_responsedetail(state) {
            dispatch(Responses.change_responsedetail(state))
        },
        change_rent_responses_editor(state) {
            dispatch(Responses.change_rent_responses_editor(state))
        },

        responseUpdate(state) {
            dispatch(Responses.responseUpdate(state))
        },
        change_response_attention_disp(state) {
            dispatch(Responses.change_response_attention_disp(state))
        },
        change_rent_customer_editor_forms(state) {
            dispatch(Customers.change_rent_customer_editor_forms(state))
        },
    };
}



ResponseInfoEdit = connect(
    mapStateToProps, mapDispatchToProps,
)(ResponseInfoEdit);

ResponseInfoEdit = reduxForm({
    validate,//バリデーション
    form: 'responseform', // a unique identifier for this form

    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(ResponseInfoEdit)

export default ResponseInfoEdit



