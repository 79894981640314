import React from 'react';
import { connect } from 'react-redux';
import styled from '@mui/system/styled';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { withRouter } from 'react-router';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import * as StockRooms from '../StockRooms/Saga/StockRooms';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

const Root = styled('div')(({ theme }) => ({
    color: "#FFF",
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
    // paddingLeft: 5,
    width:"100%",
    '&.selected': {
        backgroundColor: "#b0c4de", // 選択状態の背景色を指定
    },
}));

const Stock = styled('div')(({ theme }) => ({
    color: "#FFF",
    textAlign: "center",
    paddingTop: 20,
    paddingLeft: 10,
}));





class NavMenu extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.link = this.link.bind(this);
        this.windowopen = this.windowopen.bind(this);

        this.state = {
            dropdownOpenBuilding: false,
            dropdownOpenPatrol: false,
            height: window.innerHeight,
            showFlag: 0
        };
    }

    toggle() {}

    handleResize = () => {
        this.setState({ height: window.innerHeight });
    };

    link = (link) => {
        this.props.history.push(link);
    }

    windowopen = (link) => {
        window.open(link, '_blank', 'noopener');
    }

    render() {
        return (
            <div id="aside" style={{ position: "relative" }}>
                <MenuList>
                    <MenuItem onClick={() => this.link('/dashboard')} style={{padding:0}}>
                        <Root className={this.props.location.pathname === "/dashboard" ? "selected" : ""}>
                            <div><DashboardIcon style={{ fontSize: 28 }} /></div>
                            <div style={{ fontSize: "9px", marginTop: "5px", color: "#FFFFFF" }}>ボ ー ド</div>
                        </Root>
                    </MenuItem>

                    <MenuItem onClick={() => this.link('/')} style={{padding:0}}>
                        <Root className={this.props.location.pathname === "/" ? "selected" : ""}>
                            <div><PeopleAltIcon style={{ fontSize: 28 }} /></div>
                            <div style={{ fontSize: "9px", marginTop: "5px", color: "#FFFFFF" }}>反響管理</div>
                        </Root>
                    </MenuItem>

                    <MenuItem onClick={() => this.link('/contractfollow')} style={{padding:0}}>
                        <Root className={this.props.location.pathname === "/contractfollow" ? "selected" : ""}>
                            <div><MenuBookIcon style={{ fontSize: 28 }} /></div>
                            <div style={{ fontSize: "9px", marginTop: "5px", color: "#FFFFFF" }}>契約管理</div>
                        </Root>
                    </MenuItem>

                    <MenuItem onClick={() => this.link('/calendar')} style={{padding:0}}>
                        <Root className={this.props.location.pathname === "/calendar" ? "selected" : ""}>
                            <div><EventNoteIcon style={{ fontSize: 28 }} /></div>
                            <div style={{ fontSize: "9px", marginTop: "5px", color: "#FFFFFF" }}>予 定</div>
                        </Root>
                    </MenuItem>

                    <MenuItem onClick={() => this.link('/MailTemplate')} style={{padding:0}}>
                        <Root className={this.props.location.pathname === "/MailTemplate" ? "selected" : ""}>
                            <div><DynamicFeedIcon style={{ fontSize: 28 }} /></div>
                            <div style={{ fontSize: "9px", marginTop: "5px", color: "#FFFFFF" }}>テンプレ</div>
                        </Root>
                    </MenuItem>

                    <MenuItem onClick={() => this.props.change_stock_rooms_open(!this.props.stock_rooms_open)} style={{padding:0}}>
                        <Root>
                            <Badge badgeContent={this.props.stock_rooms.length} color="secondary">
                                <ShoppingCartIcon style={{ fontSize: 28 }} />
                            </Badge>
                            <div style={{ fontSize: "9px", marginTop: "5px", color: "#FFFFFF" }}>ストック</div>
                        </Root>
                    </MenuItem>

                    <MenuItem onClick={() => this.link('/corporation')} style={{padding:0}}>
                        <Root className={this.props.location.pathname === "/corporation" ? "selected" : ""}>
                            <div><LocationCityIcon style={{ fontSize: 28 }} /></div>
                            <div style={{ fontSize: "9px", marginTop: "5px", color: "#FFFFFF" }}>法 人</div>
                        </Root>
                    </MenuItem>

                    <MenuItem onClick={() => this.windowopen('https://homestationweb.azurewebsites.net/WebSummary')} style={{padding:0}}>
                        <Root className={this.props.location.pathname === "/analytics" ? "selected" : ""}>
                            <div><AssessmentIcon style={{ fontSize: 28 }} /></div>
                            <div style={{ fontSize: "9px", marginTop: "5px", color: "#FFFFFF" }}>分 析</div>
                        </Root>
                    </MenuItem>

                    <MenuItem onClick={() => this.link('/YoyakuParking')} style={{padding:0}}>
                        <Root className={this.props.location.pathname === "/YoyakuParking" ? "selected" : ""}>
                            <div><BrowseGalleryIcon style={{ fontSize: 28 }} /></div>
                            <div style={{ fontSize: "9px", marginTop: "5px", color: "#FFFFFF" }}>空き待ち</div>
                        </Root>
                    </MenuItem>
                    <MenuItem onClick={() => this.windowopen('https://board.homestation.jp/dashboard')} style={{padding:0}}>
                        <Root className={this.props.location.pathname === "/analytics" ? "selected" : ""}>
                            <div><PlaylistAddCheckIcon style={{ fontSize: 28 }} /></div>
                            <div style={{ fontSize: "9px", marginTop: "5px", color: "#FFFFFF" }}>ToDo</div>
                        </Root>
                    </MenuItem>
                </MenuList>
            </div>
        );
    }
}

// コンテナ
const mapStateToProps = (state) => {
    return {
        stock_rooms: state.StockRooms.stock_rooms,
        stock_rooms_open: state.StockRooms.stock_rooms_open,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        change_stock_rooms_open(state) {
            dispatch(StockRooms.change_stock_rooms_open(state));
        },
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavMenu));
