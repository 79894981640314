import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Calendarsaga from './Saga/Calendar';
import * as ContractFollow from '../ContractFollow/Saga/ContractFollow';
import * as Responses from '../ResponseDetail/Saga/Responses';
import * as Contracts from '../Contracts/Saga/Contracts';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Scrollbars } from 'react-custom-scrollbars';
var moment = require('moment-timezone');


class Editor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
    };

    this.onClick = this.onClick.bind(this);
 
  }


  componentDidMount() {

  }

  onClick(value){
    let detail = this.props.detail
    // alert(value)

    // alert(moment(detail[this.props.name]).utc().format("YYYY/MM/DD HH:mm"))
    let t = detail[this.props.name] && detail[this.props.name] != "Invalid date" ? detail[this.props.name] : detail[this.props.name2]
    detail[this.props.name] = moment(moment(t).format("YYYY/MM/DD ") + value).format('YYYY/MM/DD HH:mm')
    this.props.change_detail(detail)
// alert(detail[this.props.name])
    this.setState({ reflesh: !this.props.reflesh })
    this.props.change_datetime_select(this.props.name, detail[this.props.name])
  }

  render() {

    return (

      <Scrollbars style={{ width: this.props.start ? 140:160, height: 160, 
        position: "absolute", top:50, left:0, zIndex: 2, backgroundColor:"white", border:"1px solid #c4c4c4", borderRadius: "2px" }}>
          {(() => {
            if(this.props.start){
            return time.map((item) => (
              <ListItem style={{padding:1}}>
              <ListItemButton style={{padding:"0px 5px"}} onClick={()=>this.onClick(item.value)}>
                <ListItemText style={{padding:0}} primary={item.label} />
              </ListItemButton>
            </ListItem>
            ))
            }else{
              let time_list = []
              // alert(moment(this.props.start_time).endOf('day').format("YYYY/MM/DD HH:mm"))
              // alert(moment(this.props.start_time).add(15, 'minutes').format("YYYY/MM/DD HH:mm"))
              if(moment(this.props.start_time).endOf('day') > moment(this.props.start_time).add(15, 'minutes') ){
                let time = moment(this.props.start_time).add(15, 'minutes')
                time_list.push({value: moment(time).format("HH:mm"), label: moment(time).format("HH:mm") + "（15分）"})
              }
              if(moment(this.props.start_time).endOf('day') > moment(this.props.start_time).add(30, 'minutes') ){
                let time = moment(this.props.start_time).add(30, 'minutes')
                time_list.push({value: moment(time).format("HH:mm"), label: moment(time).format("HH:mm") + "（30分）"})
              }
              if(moment(this.props.start_time).endOf('day') > moment(this.props.start_time).add(45, 'minutes') ){
                let time = moment(this.props.start_time).add(45, 'minutes')
                time_list.push({value: moment(time).format("HH:mm"), label: moment(time).format("HH:mm") + "（45分）"})
              }
              if(moment(this.props.start_time).endOf('day') > moment(this.props.start_time).add(60, 'minutes') ){
                let time = moment(this.props.start_time).add(60, 'minutes')
                time_list.push({value: moment(time).format("HH:mm"), label: moment(time).format("HH:mm") + "（60分）"})
              }
              let n = moment(this.props.start_time).add(60, 'minutes').add(15, 'minutes');
              let x = 1.25;
              while (n < moment(this.props.start_time).endOf('day')) {
                
                time_list.push({value: moment(n).format("HH:mm"), label: moment(n).format("HH:mm") + "（"+(x !== Math.floor(x) ? Math.floor(x)+"時間"+ Math.round((x - Math.floor(x)) * 60) +"分）" : x+"時間）")})

                n = moment(n).add(15, 'minutes')
                x = x + 0.25
              }

              return time_list.map((item) => (
                <ListItem style={{padding:1}}>
                <ListItemButton style={{padding:"0px 5px"}} onClick={()=>this.onClick(item.value)}>
                  <ListItemText style={{padding:0}} primary={item.label} />
                </ListItemButton>
              </ListItem>
              ))

            }
          })()}
      </Scrollbars>

    );
  }
};

const time = [{ value: "9:00", label: '09:00' }
  , { value: "9:15", label: '09:15' }
  , { value: "9:30", label: '09:30' }
  , { value: "9:45", label: '09:45' }
  , { value: "10:00", label: '10:00' }
  , { value: "10:15", label: '10:15' }
  , { value: "10:30", label: '10:30' }
  , { value: "10:45", label: '10:45' }
  , { value: "11:00", label: '11:00' }
  , { value: "11:15", label: '11:15' }
  , { value: "11:30", label: '11:30' }
  , { value: "11:45", label: '11:45' }
  , { value: "12:00", label: '12:00' }
  , { value: "12:15", label: '12:15' }
  , { value: "12:30", label: '12:30' }
  , { value: "12:45", label: '12:45' }
  , { value: "13:00", label: '13:00' }
  , { value: "13:15", label: '13:15' }
  , { value: "13:30", label: '13:30' }
  , { value: "13:45", label: '13:45' }
  , { value: "14:00", label: '14:00' }
  , { value: "14:15", label: '14:15' }
  , { value: "14:30", label: '14:30' }
  , { value: "14:45", label: '14:45' }
  , { value: "15:00", label: '15:00' }
  , { value: "15:15", label: '15:15' }
  , { value: "15:30", label: '15:30' }
  , { value: "15:45", label: '15:45' }
  , { value: "16:00", label: '16:00' }
  , { value: "16:15", label: '16:15' }
  , { value: "16:30", label: '16:30' }
  , { value: "16:45", label: '16:45' }
  , { value: "17:00", label: '17:00' }
  , { value: "17:15", label: '17:15' }
  , { value: "17:30", label: '17:30' }
  , { value: "17:45", label: '17:45' }
  , { value: "18:00", label: '18:00' }
  , { value: "18:15", label: '18:15' }
  , { value: "18:30", label: '18:30' }
  , { value: "18:45", label: '18:45' }
  , { value: "19:00", label: '19:00' }]

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

//export  default CustomerList;
const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    users: state.masterReducer.users,
    shops: state.masterReducer.shops,
    contract_follow_search_list: state.contractfollow.contract_follow_search_list,
    rent_m_contract_follows_disp: state.contractfollow.rent_m_contract_follows_disp,
    // contract_follow_editer: state.contractfollow.contract_follow_editer,
    modalResponseDetail: state.responsesReducer.modalResponseDetail,
    responseid: state.responsesReducer.responseid,
    contract_follow_editer_loading: state.contractfollow.contract_follow_editer_loading,

    rent_task_list: state.calendar.rent_task_list,
    rent_task_detail: state.calendar.rent_task_detail,
    calendar_search_store_list: state.calendar.calendar_search_store_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    change_contract_follow_editer(state) {
      dispatch(ContractFollow.change_contract_follow_editer(state))
    },
    change_contract_follow_list(state) {
      dispatch(ContractFollow.change_contract_follow_list(state))
    },

    get_contract_follow_list(state) {
      dispatch(ContractFollow.get_contract_follow_list(state))
    },
    change_responseid(state) {
      dispatch(Responses.change_responseid(state))
    },
    change_modalResponseDetail(state) {
      dispatch(Responses.change_modalResponseDetail(state))
    },

    change_rent_contract_id(state) {
      dispatch(Contracts.change_rent_contract_id(state))
    },

    update_rent_contract_follow_details(state) {
      dispatch(ContractFollow.update_rent_contract_follow_details(state))
    },
    search_contract_follow_list(state) {
      dispatch(ContractFollow.search_contract_follow_list(state))
    },



    
    insert_rent_task_detail(state) {
      dispatch(Calendarsaga.insert_rent_task_detail(state))
    },
    update_rent_task_detail(state) {
      dispatch(Calendarsaga.update_rent_task_detail(state))
    },
    change_rent_task_detail(state) {
      dispatch(Calendarsaga.change_rent_task_detail(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor);